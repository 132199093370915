import AuthService from '@dhi/react-components';
import {
  AppBar,
  Box,
  Button,
  Link as MuiLink,
  ListItemIcon,
  Menu,
  MenuItem,
  Switch,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { orange, red, yellow } from '@material-ui/core/colors';
// import { Typography } from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { AccountCircle, PowerSettingsNew } from '@material-ui/icons';
import AlarmIcon from '@material-ui/icons/Alarm';
import WarningIcon from '@material-ui/icons/Warning';
import { formatDistance, formatDistanceToNow } from 'date-fns';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { returnToList } from 'src/modules/PortSustainability/__utility';
import { pageMenuCaption } from '../../helpers';
import { useStores } from '../../hooks/useStores';
import { Config } from '../../interfaces/Config';
import { ExistingOperatingInstructionsText } from '../../modules/YardSafe/ExistingOperatingInstructionsText';
import { UserConsumer } from '../../providers/UserProvider';
import { NCOSLogo, SARLogo } from '../logos';

const styles = (theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      boxShadow: 'none',
    },
    toolbar: {
      alignItems: 'stretch',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    userNameIcon: {
      marginLeft: theme.spacing(3),
    },
    rightIcon: {},
    userNameCaption: {
      marginRight: theme.spacing(2),
    },
    tabs: {
      alignItems: 'stretch',
      display: 'flex',
      flex: 1,
    },
    tab: {
      paddingBottom: '5px',
      marginBottom: 0,
    },
    userbutton: {
      'display': 'flex',
      'alignItems': 'center',
      '& a': {
        marginRight: theme.spacing(2),
      },
    },
    banner: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 700,
      color: '#FFF',
      textTransform: 'uppercase',
      textAlign: 'center',
    },
  });

const authService = new AuthService(process.env.REACT_APP_API_ENDPOINT_URL);

interface TopBarProps {
  appConfig: Config;
}
interface Props extends TopBarProps, WithStyles<typeof styles> {}

const AppTopBar: React.FC<Props> = observer(({ classes, appConfig }: Props) => {
  const [anchorEl, setAchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [activePage, setActivePage] = useState('');
  const [eLearningLink, setELearningLink] = useState('');
  const intl = useIntl();
  const session = authService.getSession();
  const { appStateStore, configStore, portStore, yardSafeStore, psAppStateStore } = useStores();

  const updateAnchorEl = (el) => {
    setAchorEl(el);
    setOpen(!!el);
  };

  const handleMenu = (event) => {
    updateAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    updateAnchorEl(null);
  };

  const handlePageChange = (event, newValue) => {
    if (activePage !== newValue) {
      setActivePage(newValue);
      if (newValue.startsWith('/portsustainability')) {
        psAppStateStore.setCurrentPage(newValue.replace('/portsustainability/', ''));
        returnToList(psAppStateStore);
      }
    }
  };

  useEffect(() => {
    if (!activePage) {
      appStateStore.authorisedModules.forEach((module) => {
        module.pages.forEach((page) => {
          if (window.location.pathname.startsWith(module.route + page.route) && page.visibility.indexOf('menu') > -1) {
            setActivePage(module.route + page.route);
          }
        });
      });
    }
  }, []);

  const constructELearningLink = () => {
    const token = appStateStore.session.token.accessToken;
    const clientId = appStateStore.session.customerCode;

    if (token && clientId) {
      setELearningLink(
        `${process.env.REACT_APP_SUPPORT_SEAPORTOPX_LINK}/${process.env.REACT_APP_SYS_ENVIRONMENT}/${clientId}/${token}`,
      );
    } else {
      setELearningLink('');
    }
  };

  const updateDocumentTitle = () => {
    const hostname = document.location.hostname.toUpperCase();
    const wordsRegex = /\w+\s*/g;
    const hostnameWords = hostname.match(wordsRegex) ?? [];

    if (hostnameWords.length >= 2) {
      const module = hostnameWords[0];
      const newTitle = `${module} Seaport OPX`;
      document.title = newTitle;
    }
  };

  useEffect(() => {
    constructELearningLink();
    updateDocumentTitle();
  }, []);

  const criticalEventDate = () =>
    yardSafeStore.demoMode && portStore.portData.demoTime
      ? formatDistance(yardSafeStore.criticalEventDate, new Date(portStore.portData.demoTime)).replace('about', '')
      : formatDistanceToNow(yardSafeStore.criticalEventDate).replace('about', '');

  return (
    <>
      <AppBar position="relative" className={classes.appBar}>
        <Toolbar className={classes.toolbar} style={{ justifyContent: 'space-between' }}>
          {appStateStore.currentModule === 'SAR' ? <SARLogo /> : <NCOSLogo />}
          {activePage && (
            <Tabs
              value={activePage}
              onChange={handlePageChange}
              indicatorColor="secondary"
              textColor="inherit"
              className={classes.tabs}
            >
              {appStateStore.authorisedModules.map((module) =>
                module.pages.map((page) =>
                  page.visibility?.indexOf('menu') > -1 ? (
                    <Tab
                      label={pageMenuCaption(intl, page)}
                      key={page.id}
                      value={module.route + page.route}
                      component={Link}
                      to={module.route + page.route}
                      className={classes.tab}
                    />
                  ) : null,
                ),
              )}
            </Tabs>
          )}
          {session && (
            <div className={classes.userbutton}>
              {configStore.appConfig.applicationSettings.supportPhoneNumber && (
                <Typography style={{ fontWeight: 'bold' }}>
                  <MuiLink href={`tel:${configStore.appConfig.applicationSettings.supportPhoneNumber}`}>
                    {configStore.appConfig.applicationSettings.supportPhoneNumber}
                  </MuiLink>
                </Typography>
              )}
              {configStore.appConfig.applicationSettings.eLearning && eLearningLink && (
                <Button color="primary" variant="contained" target="_blank" href={eLearningLink}>
                  <FormattedMessage id="global.button.elearning" defaultMessage="global.button.elearning" />
                </Button>
              )}
              {appStateStore.currentModule === 'YardSafe' && appStateStore.session.customerCode === 'APMTPier400' && (
                <>
                  <Typography style={{ fontWeight: 'bold' }}>
                    {yardSafeStore.toggleWindUnit ? 'Wind speed M/S' : 'Wind speed MPH'}
                  </Typography>

                  <Switch
                    color="primary"
                    checked={yardSafeStore.toggleWindUnit}
                    onChange={(e, newValue) => {
                      yardSafeStore.setToggleWindUnit(newValue);
                    }}
                  />
                </>
              )}
              {appStateStore.currentModule === 'YardSafe' && appStateStore.session.user.metadata.debug && (
                <>
                  <Typography style={{ fontWeight: 'bold' }}>
                    {yardSafeStore.demoMode ? 'Demo Mode' : 'Forecast Mode'}
                  </Typography>

                  <Switch
                    color="primary"
                    checked={yardSafeStore.demoMode}
                    onChange={(_, newValue) => {
                      yardSafeStore.setDemoMode(newValue);
                    }}
                  />
                </>
              )}
              {appStateStore.currentModule === 'YardSafe' &&
                configStore.appConfig.applicationSettings.showOperatorInstructions && (
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      appStateStore.showAlertDialog(
                        intl.formatMessage({
                          id: 'components.YardSafe.global.existingOperatingInstructions',
                          defaultMessage: '',
                        }),
                        <ExistingOperatingInstructionsText />,
                        null,
                        'lg',
                      );
                    }}
                    style={{ marginRight: 12 }}
                  >
                    <FormattedMessage id="components.YardSafe.global.existingOperatingInstructions" />
                  </Button>
                )}
              <Button
                aria-owns={open ? 'menu-appbar' : undefined}
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <UserConsumer>
                  {() => {
                    return (
                      <span className={classes.userNameCaption}>
                        {appStateStore.session.user && appStateStore.session.user.name}
                      </span>
                    );
                  }}
                </UserConsumer>
                <AccountCircle className={(classes.userNameIcon, classes.rightIcon)} />
              </Button>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                <UserConsumer>
                  {({ onLogout }) => {
                    return (
                      <MenuItem onClick={onLogout}>
                        <ListItemIcon>
                          <PowerSettingsNew />
                        </ListItemIcon>
                        <FormattedMessage id="auth.menu.logout" defaultMessage="auth.menu.logout" />
                      </MenuItem>
                    );
                  }}
                </UserConsumer>
              </Menu>
            </div>
          )}
        </Toolbar>
        {appStateStore.currentModule === 'MooringAnalysis' &&
          configStore.appConfig.applicationSettings.evaluationMessage && (
            <Box p={1} className={classes.banner} style={{ backgroundColor: red.A700 }}>
              <span>
                <FormattedMessage id="global.evaluationPurposes" defaultMessage="global.evaluationPurposes" />
              </span>
            </Box>
          )}
        {appStateStore.currentModule === 'YardSafe' && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {configStore.appConfig.applicationSettings.evaluationMessage && (
              <Box p={1} className={classes.banner} style={{ backgroundColor: yellow[500], color: 'black' }}>
                <span>
                  <FormattedMessage id="global.evaluationPurposes" defaultMessage="global.evaluationPurposes" />
                </span>
              </Box>
            )}
            {yardSafeStore.dataOutOfDate && (
              <Box p={1} className={classes.banner} style={{ backgroundColor: orange[700] }}>
                <WarningIcon />
                <span style={{ marginLeft: 8 }}>
                  {intl.formatMessage({
                    id: 'components.YardSafe.global.conservativeData',
                    defaultMessage: '',
                  })}
                </span>
              </Box>
            )}
            {yardSafeStore.criticalEventDate && (
              <Box p={1} className={classes.banner} style={{ backgroundColor: red.A700 }}>
                <AlarmIcon />
                <span style={{ marginLeft: 8 }}>
                  {intl.formatMessage(
                    {
                      id: 'components.YardSafe.global.critialEventImminent',
                      defaultMessage: '',
                    },
                    { time: criticalEventDate() },
                  )}
                </span>
              </Box>
            )}
          </div>
        )}
      </AppBar>
    </>
  );
});

export default withStyles(styles, { withTheme: true })(AppTopBar);
