import { makeStyles, Theme } from "@material-ui/core";

const navBarStyles = makeStyles((theme: Theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: "none",
  },
  toolbar: {
    alignItems: "stretch",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  userNameIcon: {
    marginLeft: theme.spacing(3),
  },
  rightIcon: {},
  userNameCaption: {
    marginRight: theme.spacing(2),
  },
  tabs: {
    alignItems: "stretch",
    display: "flex",
    flex: 1,
  },
  tab: {
    paddingBottom: "5px",
    marginBottom: 0,
  },
  userbutton: {
    display: "flex",
    alignItems: "center",
    "& a": {
      marginRight: theme.spacing(2),
    },
  },
}));

export default navBarStyles;
