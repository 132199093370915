const dataFilter = (userDebug = false) => {
  const filter = [];

  if (!userDebug) {
    filter.push({
      field: 'data.debug',
      value: 'false',
    });
  }

  filter.push({
    field: 'fullName',
  });

  filter.push({
    field: 'data.backup',
    value: 'false',
  });

  filter.push({
    field: 'data.deleted',
    value: 'false',
  });

  return filter;
};

export default dataFilter;
