import PropTypes from 'prop-types';
import React, { lazy } from 'react';

export const RenderComponent = (props) => {
  const { componentConfig, inheritedStyles } = props;
  const { id, className, type, config, styles, components } = componentConfig;

  return React.createElement(
    lazy(() => import(`../components/${type}`)),
    {
      key: id,
      className,
      componentConfig: config,
      inheritedStyles: inheritedStyles || {},
      componentStyles: styles,
      components,
    },
  );
};

RenderComponent.propTypes = {
  componentConfig: PropTypes.object,
  inheritedStyles: PropTypes.object,
};

export const RenderComponents = (config) => {
  const { components, styles } = config;

  return components.map((component) => (
    <RenderComponent key={component.id} componentConfig={component} inheritedStyles={styles && styles[component.id]} />
  ));
};
