import { observer } from 'mobx-react';
import React, { useState } from 'react';
import ReactJson from 'react-json-view';

interface Props {
  data: any;
  onEdit: (data: any) => void;
}

const DebugViewer = observer(({ data, onEdit }: Props) => {
  const [showDebugLog, setShowDebugLog] = useState(false);
  const [showDebugLogMAs, setShowDebugLogMAs] = useState(false);

  return (
    <>
      {showDebugLog && (
        <>
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              height: 'calc(100vh - 7px)',
              width: 'calc(100vw - 600px)',
              opacity: '0.5',
              zIndex: 99998,
            }}
          ></div>
          <ReactJson
            src={data}
            theme="ocean"
            iconStyle="circle"
            displayDataTypes={false}
            displayObjectSize={true}
            groupArraysAfterLength={50}
            shouldCollapse={(props) => props.type === 'array'}
            onEdit={(props) => onEdit(props.updated_src as any)}
            style={{
              fontSize: 13,
              overflow: 'scroll',
              resize: 'none',
              position: 'absolute',
              padding: 10,
              top: 0,
              left: 0,
              bottom: 0,
              height: 'calc(100vh)',
              width: 'calc(100vw - 600px)',
              zIndex: 99999,
            }}
          />
        </>
      )}
      {showDebugLogMAs && (
        <>
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              height: 'calc(100vh - 7px)',
              width: 'calc(100vw - 600px)',
              opacity: '0.5',
              zIndex: 99998,
            }}
          ></div>
          <ReactJson
            src={{
              [data.data.mooring.mooringArrangementName]:
                data.data.mooring.mooringArrangements[data.data.mooring.mooringArrangementName],
            }}
            theme="ocean"
            iconStyle="circle"
            displayDataTypes={false}
            displayObjectSize={true}
            onEdit={(props) => onEdit(props.updated_src as any)}
            style={{
              fontSize: 13,
              overflow: 'scroll',
              resize: 'both',
              position: 'absolute',
              padding: 10,
              top: 0,
              left: 0,
              bottom: 0,
              height: 'calc(100vh)',
              width: 'calc(80vw - 600px)',
              zIndex: 99999,
            }}
          />
        </>
      )}
      <button
        type="button"
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          height: 20,
          width: 25,
          fontSize: 13,
          zIndex: 99999,
        }}
        onClick={() => setShowDebugLog(!showDebugLog)}
      >
        {showDebugLog ? '▼' : '▲'}
      </button>
      <button
        type="button"
        style={{
          position: 'absolute',
          bottom: 0,
          left: 30,
          height: 20,
          width: 25,
          fontSize: 13,
          zIndex: 99999,
        }}
        onClick={() => setShowDebugLogMAs(!showDebugLogMAs)}
      >
        <span style={{ position: 'absolute', marginLeft: -9, marginTop: -9 }}>{showDebugLogMAs ? '➰' : '➰'}</span>
      </button>
    </>
  );
});

export default DebugViewer;
