import { CircularProgress } from '@material-ui/core';
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const styles = (theme: Theme) => ({
  container: {
    display: 'flex',
    // flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
});

interface LoadingProps {
  messageId?: string;
}
interface Props extends LoadingProps, WithStyles<typeof styles> {}
const Loading: React.FC<Props> = ({ classes, messageId }: Props) => (
  <div className={classes.container}>
    <CircularProgress />
    {messageId && (
      <div style={{ padding: '20px' }}>
        <FormattedMessage id={messageId} defaultMessage={messageId} />
      </div>
    )}
  </div>
);

export default withStyles(styles, { withTheme: true })(Loading);
