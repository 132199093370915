/* eslint-disable prefer-destructuring */
import convert from 'convert-units';
import { round } from 'lodash';
import { InputFieldValue } from '../components/common/InputField/types';
import { isNumeric } from '../helpers/common';
import fieldRound from '../helpers/fieldRound';
import { DataConfig } from '../interfaces/Config';

// not impl till needed
const nauticalMilesToMeters = 1852;

interface Factor {
  isSi: boolean;
  opposite: string;
  unit: string; // compatible with convert-units package
  display: string;
}

// conversion factors constants
export const FACTORS = {
  // Imperial
  'ft': { isSi: false, opposite: 'm', unit: 'ft', display: 'ft' } as Factor,
  'ft2': { isSi: false, opposite: 'm2', unit: 'ft2', display: 'ft²' } as Factor,
  'in': { isSi: false, opposite: 'm', unit: 'in', display: 'in' } as Factor,
  'mi': { isSi: false, opposite: 'm', unit: 'mi', display: 'mi' } as Factor,
  'mi2': { isSi: false, opposite: 'm2', unit: 'mi2', display: 'mi²' } as Factor,
  'kn': { isSi: false, opposite: 'm/s', unit: 'knot', display: 'kn' } as Factor,
  'F': { isSi: false, opposite: 'C', unit: 'F', display: '°' } as Factor,
  // si
  'm': { isSi: true, opposite: 'ft', unit: 'm', display: 'm' } as Factor,
  'm2': { isSi: true, opposite: 'ft2', unit: 'm2', display: 'ft²' } as Factor,
  'm/s': { isSi: true, opposite: 'knot', unit: 'm/s', display: 'm/s' } as Factor,
  'C': { isSi: true, opposite: 'F', unit: 'C', display: '°' } as Factor,
};

const convertViaFieldConfig = (
  forceSi: boolean,
  value: number,
  fieldConfig: DataConfig,
  useConfigRounding: boolean,
) => {
  if (Object.prototype.hasOwnProperty.call(fieldConfig, 'conversion')) {
    if (!fieldConfig.conversion) {
      // conversion property is falsy
      return value;
    }

    if (!Object.prototype.hasOwnProperty.call(FACTORS, fieldConfig.conversion)) {
      // conversion function not found
      throw new TypeError();
    }
  }

  const checkRounding = (val) => fieldRound(val, useConfigRounding ? fieldConfig : null); // all other cases, 4 decimals inside fieldRound fallback, confirmed with ALHA 21/5/2020

  if (Object.prototype.hasOwnProperty.call(fieldConfig, 'conversion')) {
    let fromUnit: Factor = null;
    let toUnit: Factor = null;

    if (!FACTORS[fieldConfig.conversion]) {
      throw new Error(`conversion string \`${fieldConfig.conversion}\` not found in conversions.FACTORS.`);
    } else if (forceSi) {
      fromUnit = FACTORS[fieldConfig.conversion];
      toUnit = FACTORS[fromUnit.opposite];
      // console.log(`Attempting to look up fromUnit: FACTORS[${fieldConfig.conversion}]`);
      // console.log(`Attempting to look up toUnit: FACTORS[${fromUnit.opposite}]`);
    } else {
      toUnit = FACTORS[fieldConfig.conversion];
      fromUnit = FACTORS[toUnit.opposite];
      // console.log(`Attempting to look up toUnit: FACTORS[${fieldConfig.conversion}]`);
      // console.log(`Attempting to look up fromUnit: FACTORS[${toUnit.opposite}]`);
    }

    const conversionOutput = convert(value).from(fromUnit.unit).to(toUnit.unit);
    // console.log(`[${fieldConfig.field}] from ${value} ${fromUnit.display} = ${checkRounding(conversionOutput)} ${toUnit.display}`);

    return checkRounding(conversionOutput);
  }

  const output = isNumeric(value) ? checkRounding(value) : value; // no conversion property

  return output;
};

const convertViaUnitString = (
  value: number,
  unit: string,
  toSiUnits: boolean,
  withSuffix: boolean,
  decimals?: number,
) => {
  let conversionOutput = 0;
  let display = '';

  // If toSystem is the same system as the incoming unit, we do no conversion, just display
  // console.log(`[convertViaUnitString] ${value} ${unit}`);

  if (!FACTORS[unit]) {
    conversionOutput = value;
  } else if ((FACTORS[unit].isSi && toSiUnits) || (!FACTORS[unit].isSi && !toSiUnits)) {
    conversionOutput = value;
    display = unit;
  } else {
    let fromUnit: Factor = null;
    let toUnit: Factor = null;

    if (toSiUnits) {
      toUnit = FACTORS[unit];
      fromUnit = FACTORS[toUnit.opposite];
      // console.log(`Attempting to look up toUnit: FACTORS[${unit}]`);
      // console.log(`Attempting to look up fromUnit: FACTORS[${toUnit.opposite}]`);
    } else {
      fromUnit = FACTORS[unit];
      toUnit = FACTORS[fromUnit.opposite];
      // console.log(`Attempting to look up fromUnit: FACTORS[${unit}]`);
      // console.log(`Attempting to look up toUnit: FACTORS[${fromUnit.opposite}]`);
    }

    conversionOutput = convert(value).from(fromUnit.unit).to(toUnit.unit);
    display = toUnit.display;
  }

  return `${round(conversionOutput, decimals)} ${withSuffix ? display : ''}`;
};

/**
 * Convert display units (either input as __imperial__ or __si__) always to __si__ units, suitable for field storage.
 * Usage:
 * ```
 * // We always return Si units. If a non-si conversion string has been specified, conversion will occur.
 * const fieldConfig = { conversion: 'F' };
 * const fieldUnits = displayToField(128, fieldConfig); // 128F = 55C
 *
 * const fieldConfig = { conversion: 'C' };
 * const fieldUnits = displayToField(55, fieldConfig); // 55C = 55C
 *
 * const fieldConfig = { }; // Works with empty fieldConfig, assumes Si input
 * const fieldUnits = displayToField(55, fieldConfig); // 55C = 55C
 *
 * const fieldConfig = { conversion: 'kn' };
 * const fieldUnits = displayToField(1, fieldConfig); // 1kn = 0.514444 m/s
 * ```
 * @param {number} value
 * @param {{conversion: string, *}} fieldConfig
 * @returns {number}
 */
const displayToField = (value: number, fieldConfig: DataConfig, useRounding = false) =>
  convertViaFieldConfig(true, value, fieldConfig, useRounding);
/**
 * Convert data from backend field to unit of measure specified in `fieldConfig.conversion` suitable for display units.
 *
 * Typically this is used to convert from __si__ to __imperial__.
 * Usage:
 * ```
 * // We return si/imperial according to conversion string. If non specified, si will be returned.
 * const fieldConfig = { conversion: 'F' };
 * const displayUnits = fieldToDisplay(55, fieldConfig); // 55C = 128F
 *
 * const fieldConfig = { conversion: 'ft' };
 * const displayUnits = fieldToDisplay(5, fieldConfig); // 5m = 16.4042 ft
 * ```
 * @param {number} value
 * @param {{conversion: string, *}} fieldConfig
 * @returns {number}
 */
const fieldToDisplay = (value: number, fieldConfig: DataConfig, useRounding = true) =>
  convertViaFieldConfig(false, value, fieldConfig, useRounding);

/**
 * Convert a unit of measure to __si__ or __imperial__ specified by the `toSiUnits` flag.
 * @param value The value to be converted.
 * @param fromUnit The unit of measure to be converted from.
 * @param isSiUnits Is si/si system.
 * @param withSuffix Include suffix? Eg "m" in "120 m"
 * @param decimals Number of decimals to return (or default)
 */
const valueToDisplay = (value: number, fromUnit: string, toSiUnits: boolean, withSuffix: boolean, decimals = 2) => {
  return convertViaUnitString(value, fromUnit, toSiUnits, withSuffix, decimals);
};

const translateUnitsForFieldValue = (value: InputFieldValue, fieldConfig: DataConfig): InputFieldValue => {
  // number
  if (isNumeric(value)) {
    return displayToField(value as number, fieldConfig);
  }

  // range of numbers
  if (Array.isArray(value) && isNumeric(value[0]) && isNumeric(value[1])) {
    return [displayToField(value[0] as number, fieldConfig), displayToField(value[1] as number, fieldConfig)];
  }

  return value;
};

const translateUnitsForStateClientValue = (value: InputFieldValue, fieldConfig: DataConfig): InputFieldValue => {
  if (!value) {
    if (value === 0) {
      return 0;
    }

    // Apparently Reactjs by-design that React doesn't accept null for controlled components.
    // No idea why this is, I suppose it must have a default initialiser specifing the type, in the instance of strings that is '',
    // In arrays, []. Either way I'm not 100% comfortable with it, but we'll live with it. - GRON
    return '';
  }

  // isNumeric does not cut it, we want to check TRUE data type. As "imo" for eg. is numeric, but it should not be translated.
  const shouldAttemptTranslation = typeof value === 'number';

  // number

  if (shouldAttemptTranslation) {
    return fieldToDisplay(value as number, fieldConfig);
  }

  // range of numbers
  if (Array.isArray(value) && typeof value[0] === 'number' && typeof value[1] === 'number') {
    return [fieldToDisplay(value[0], fieldConfig), fieldToDisplay(value[1], fieldConfig)];
  }

  return value;
};

/**
 * Converts degrees to Radians
 * @param degrees 0 to 360
 * @param offset in case you need to rotate the object to start at 0
 * @returns Degrees in Radian
 */
const degreesToRadians = (degrees: number, offset = 0) => {
  return (Math.PI * (offset - degrees)) / 180;
};

export {
  displayToField,
  fieldToDisplay,
  valueToDisplay,
  FACTORS as CONVERSION_FACTORS,
  translateUnitsForFieldValue,
  translateUnitsForStateClientValue,
  degreesToRadians,
};
