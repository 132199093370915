/**
 *
 * @param color : HEX, rgb or 'black';
 * @returns return an object with `r`, `g` and `b` color values
 */
const colorToRGB = (
  color: String
): {
  r: number;
  g: number;
  b: number;
} => {
  if (!color) {
    return {
      r: 255,
      g: 255,
      b: 255,
    };
  }

  if (color === "black") {
    return {
      r: 0,
      g: 0,
      b: 0,
    };
  }
  if (color.includes("rgb")) {
    const parts = color.split("(")[1].split(")")[0].split(",");

    return {
      r: parseInt(parts[0]),
      g: parseInt(parts[1]),
      b: parseInt(parts[2]),
    };
  } else {
    return {
      r: parseInt(color.substring(1, 3), 16),
      g: parseInt(color.substring(3, 5), 16),
      b: parseInt(color.substring(5, 7), 16),
    };
  }
};

/**
 * return RGB array
 * @param color : HEX, rgb or 'black';
 * @returns RGB array
 */
const paint = (color: string, alpha = 255) => {
  const rgb = colorToRGB(color);

  if (color != null) {
    return [rgb.r, rgb.g, rgb.b, alpha];
  }

  return [rgb.r, rgb.g, rgb.b, alpha];
};

export default colorToRGB;
export { paint };
