import { transaction } from 'mobx';
import { VesselType } from '../../helpers/enums';
import { Berth, ShipLoader } from '../../interfaces/PortData';
import { EnvironmentalConditions, Vessel, VesselScenarioContainer } from '../../interfaces/VesselScenario';
import { PortStore } from '../../stores';
import { largeRound } from '../common';
import {
  bulkCarrierWindageAreas,
  containerWindageAreas,
  cruiseLinerWindageAreas,
  gasCarrierWindageAreas,
  roRoWindageAreas,
  tankerWindageAreas,
} from './windageCalculation';

// These constants about Block Coefficient (CB) could be written on the configuration file instead,
// for now they will be here
const TANKER_CB = 0.85; // above 0.8, around 0.85 usually
const CONTAINER_CB = 0.7; // 0.65 to 0. 75 usually
const CARGO_CB = 0.75; // 0.7 to 0.8 usually
const BULK_CB = 0.85; // 0.7 to 0.8 usually

const CB_DEFAULTS = {
  Tanker: TANKER_CB,
  ContainerVessel: CONTAINER_CB,
  GeneralCargo: CARGO_CB,
  BulkCarrier: BULK_CB,
};

const getDraftToUse = (scenarioData) => {
  const draftToUse =
    scenarioData.vessel.draftType === 1
      ? parseFloat(scenarioData.vessel.draftMidValue) * 1.0
      : (1.0 * scenarioData.vessel.draftMin + 1.0 * scenarioData.vessel.draftMax) / 2;

  return largeRound(draftToUse);
};

/**
 * Get max value of drafts if draftType=1, otherwise min draft from range.
 * @param vessel
 */
const getVesselMinOfDrafts = (vessel: Vessel) => {
  let value = 0;

  if (vessel.draftType === 1) {
    value = Math.max(vessel.draftForeValue, vessel.draftMidValue, vessel.draftAftValue); // Max of the three draft always.
  } else {
    value = vessel.draftMin;
  }

  return value;
};

/**
 * Get max value of drafts if draftType=1, otherwise max draft from range.
 * @param vessel
 */
const getVesselMaxOfDrafts = (vessel: Vessel) => {
  let value = 0;

  if (vessel.draftType === 1) {
    value = Math.max(vessel.draftForeValue, vessel.draftMidValue, vessel.draftAftValue);
  } else {
    value = vessel.draftMax;
  }

  return value;
};

/**
 * Update all the derived values around the Vessel and Mooring.
 * @param portStore
 * @param scenarioDataContainer
 * @param berth
 * @param finishCallback usually called to run validation with the update scenario data.
 */
const setDerivedValues = (
  portStore: PortStore,
  scenarioDataContainer: VesselScenarioContainer,
  berth: Berth,
  finishCallback?: (scenarioDataContainer: VesselScenarioContainer) => void,
) => {
  transaction(() => {
    scenarioDataContainer.data.vessel.containerHeightAboveWl = setContainerHeightAboveWl(
      scenarioDataContainer.data.vessel,
    );

    scenarioDataContainer.data.vessel.totalHeightAboveWaterLevel = setTotalHeightAboveWl(
      scenarioDataContainer.data.vessel,
    );

    const windages = setWindageAreas(scenarioDataContainer.data.vessel, berth);

    scenarioDataContainer.data.vessel.transversalWindageArea = windages.transversal;
    scenarioDataContainer.data.vessel.transversalWindageAreaMin = windages.transversalMin;
    scenarioDataContainer.data.vessel.transversalWindageAreaMax = windages.transversalMax;
    scenarioDataContainer.data.vessel.longitudinalWindageArea = windages.longitudinal;

    scenarioDataContainer.data.vessel.longitudinalWindageAreaMin = windages.longitudinalMin;

    scenarioDataContainer.data.vessel.longitudinalWindageAreaMax = windages.longitudinalMax;

    // Only perform screening if berth is selected
    if (berth != null) {
      // Only screen for spreader clearance/outreach if loader exists.
      if (berth.shipLoaders.length > 0) {
        const shipLoaderMinOutreachOfBoom = berth.shipLoaders.reduce((prev, curr) =>
          prev.outReachOfBoom > curr.outReachOfBoom ? prev : curr,
        );
        const shipLoaderMinHeightAboveWharf = berth.shipLoaders.reduce((prev, curr) =>
          prev.spreaderHeightAboveWharf > curr.spreaderHeightAboveWharf ? prev : curr,
        );

        scenarioDataContainer.data.mooring.outreachBeyondBeam = setOutreachBeyondBeam(
          scenarioDataContainer.data.vessel,
          shipLoaderMinOutreachOfBoom,
        );

        const spreaderClearance = setSpreaderClearance(
          portStore,
          scenarioDataContainer.data.vessel,
          berth,
          shipLoaderMinHeightAboveWharf,
          scenarioDataContainer.data.environmentalConditions,
        );

        scenarioDataContainer.data.mooring.spreaderClearance = spreaderClearance.calc;
        scenarioDataContainer.data.mooring.spreaderClearanceMin = spreaderClearance.calcMin;
        scenarioDataContainer.data.mooring.spreaderClearanceMax = spreaderClearance.calcMax;
      }
      const underKeelClearance = setUnderKeelClearance(
        portStore,
        scenarioDataContainer.data.vessel,
        berth,
        scenarioDataContainer.data.environmentalConditions,
      );

      scenarioDataContainer.data.mooring.underKeelClearance = underKeelClearance.calc;
      scenarioDataContainer.data.mooring.underKeelClearanceMin = underKeelClearance.calcMin;
      scenarioDataContainer.data.mooring.underKeelClearanceMax = underKeelClearance.calcMax;

      scenarioDataContainer.data.mooring.maxBeamExceeded = setMaxBeamExceeded(scenarioDataContainer.data.vessel, berth);

      scenarioDataContainer.data.mooring.maxLoaExceeded = setMaxLoaExceeded(scenarioDataContainer.data.vessel, berth);

      const deckAboveWharf = setDeckAboveWharf(
        portStore,
        scenarioDataContainer.data.vessel,
        berth,
        scenarioDataContainer.data.environmentalConditions,
      );

      scenarioDataContainer.data.mooring.deckAboveWharf = deckAboveWharf.calc;
      scenarioDataContainer.data.mooring.deckAboveWharfMin = deckAboveWharf.calcMin;
      scenarioDataContainer.data.mooring.deckAboveWharfMax = deckAboveWharf.calcMax;
      scenarioDataContainer.data.mooring.dredgeDepth = setDredgeDepth(berth);

      scenarioDataContainer.data.mooring.dredgeDepthMsl = setDredgeDepthMsl(portStore, berth);
    }

    if (finishCallback) {
      finishCallback(scenarioDataContainer);
    }
  });
};

const setContainerHeightAboveWl = (vessel: Vessel) => {
  const vesselMinOfDrafts = Math.max(vessel.draftForeValue, vessel.draftMidValue, vessel.draftAftValue);
  // Only show/set data for container vessels:
  const containerHeightAboveWl =
    vessel.vesselType == 1
      ? largeRound(
          vessel.mouldedDepth -
            vesselMinOfDrafts +
            vessel.hatchHeightAboveMainDeck +
            vessel.numberOfContainerTiers * vessel.containerHeight,
        )
      : null;

  return containerHeightAboveWl;
};

const setTotalHeightAboveWl = (vessel: Vessel) => {
  const vesselMinOfDrafts = Math.min(vessel.draftForeValue, vessel.draftMidValue, vessel.draftAftValue);
  // Only show/set data for container vessels:
  const totalHeightAboveWl = largeRound(
    vessel.mouldedDepth - vesselMinOfDrafts + vessel.heightOfPilotHouse + vessel.heightAbovePilotHouse,
  );
  return totalHeightAboveWl;
};

const setOutreachBeyondBeam = (vessel: Vessel, shipLoader: ShipLoader) => {
  const calc = largeRound(shipLoader.outReachOfBoom - shipLoader.railToFender - vessel.beam);

  return calc;
};

const setSpreaderClearance = (
  portStore: PortStore,
  vessel: Vessel,
  berth: Berth,
  shipLoader: ShipLoader,
  environmentalConditions: EnvironmentalConditions,
) => {
  // IF DraftType=1 AND tideType=1 .. return single value
  // Else.. Return 2 values
  let calc = 0;
  let calcMin = 0;
  let calcMax = 0;

  if (vessel.draftType === 1 && environmentalConditions.tide.type === 1) {
    const spreaderCalc1 = shipLoader.spreaderHeightAboveWharf + berth.wharfHeightAbovePortDatum;
    const spreaderCalc2 =
      vessel.numberOfContainerTiers * vessel.containerHeight +
      vessel.hatchHeightAboveMainDeck +
      vessel.mouldedDepth -
      getVesselMinOfDrafts(vessel) +
      environmentalConditions.tide.value; // Tide is already in port datum, so portStore.portData.mslPortDatum is already taken into account
    calc = spreaderCalc1 - spreaderCalc2;

    return { calc, calcMin: null, calcMax: null };
  }

  let tideMin = environmentalConditions.tide.value;
  let tideMax = environmentalConditions.tide.value;

  if (environmentalConditions.tide.type === 2 || environmentalConditions.tide.type === 3) {
    tideMin = environmentalConditions.tide.min;
    tideMax = environmentalConditions.tide.max;
  }

  const spreaderCalc1 = shipLoader.spreaderHeightAboveWharf + berth.wharfHeightAbovePortDatum;
  const spreaderCalc2Min =
    vessel.numberOfContainerTiers * vessel.containerHeight +
    vessel.hatchHeightAboveMainDeck +
    vessel.mouldedDepth -
    getVesselMinOfDrafts(vessel) +
    tideMax; // Tide is already in port datum, so portStore.portData.mslPortDatum is already taken into account
  calcMin = largeRound(spreaderCalc1 - spreaderCalc2Min);

  const spreaderCalc2Max =
    vessel.numberOfContainerTiers * vessel.containerHeight +
    vessel.hatchHeightAboveMainDeck +
    vessel.mouldedDepth -
    getVesselMaxOfDrafts(vessel) +
    tideMin; // Tide is already in port datum, so portStore.portData.mslPortDatum is already taken into account
  calcMax = largeRound(spreaderCalc1 - spreaderCalc2Max);

  return { calc: null, calcMin, calcMax };
};

const setUnderKeelClearance = (
  portStore: PortStore,
  vessel: Vessel,
  berth: Berth,
  environmentalConditions: EnvironmentalConditions,
) => {
  // IF DraftType=1 AND tideType=1 .. return single value
  // Else.. Return 2 values
  if (vessel.draftType === 1 && environmentalConditions.tide.type === 1) {
    // Tide is already in port datum, so portStore.portData.mslPortDatum is already taken into account
    const calc = largeRound(
      berth.berthPocketDepthBelowPortDatum + environmentalConditions.tide.value - getVesselMaxOfDrafts(vessel),
    );

    return { calc, calcMin: null, calcMax: null };
  }

  let tideMin = environmentalConditions.tide.value;
  let tideMax = environmentalConditions.tide.value;

  if (environmentalConditions.tide.type === 2 || environmentalConditions.tide.type === 3) {
    tideMin = environmentalConditions.tide.min;
    tideMax = environmentalConditions.tide.max;
  }

  // Calc UKC at max draft (min UKC)
  const calcMin = largeRound(
    // Tide is already in port datum, so portStore.portData.mslPortDatum is already taken into account
    berth.berthPocketDepthBelowPortDatum + tideMin - getVesselMaxOfDrafts(vessel),
  );
  // Calc UKC at min draft (max UKC)
  const calcMax = largeRound(
    // Tide is already in port datum, so portStore.portData.mslPortDatum is already taken into account
    berth.berthPocketDepthBelowPortDatum + tideMax - getVesselMinOfDrafts(vessel),
  );

  return { calc: null, calcMin, calcMax };
};

const setDredgeDepthMsl = (portStore: PortStore, berth: Berth) => {
  const calc = largeRound(berth.berthPocketDepthBelowPortDatum + portStore.portData.mslPortDatum);

  return calc;
};

const setMaxBeamExceeded = (vessel: Vessel, berth: Berth) => {
  const calc = berth.maximumVesselBeam == null || vessel.beam < berth.maximumVesselBeam!;

  return calc;
};

const setMaxLoaExceeded = (vessel: Vessel, berth: Berth) => {
  const calc = berth.maximumVesselLOA == null || vessel!.loa < berth.maximumVesselLOA!;

  return calc;
};

const setDeckAboveWharf = (
  portStore: PortStore,
  vessel: Vessel,
  berth: Berth,
  environmentalConditions: EnvironmentalConditions,
) => {
  // IF DraftType=1 AND tideType=1 .. return single value
  // Else.. Return 2 values
  let calc = 0;
  let calcMin = 0;
  let calcMax = 0;

  if (vessel.draftType === 1 && environmentalConditions.tide.type === 1) {
    calc = largeRound(
      vessel.mouldedDepth -
        getVesselMinOfDrafts(vessel) -
        (berth.wharfHeightAbovePortDatum - environmentalConditions.tide.value),
    );

    return { calc, calcMin: null, calcMax: null };
  }

  let tideMin = environmentalConditions.tide.value;
  let tideMax = environmentalConditions.tide.value;

  if (environmentalConditions.tide.type === 2) {
    tideMin = environmentalConditions.tide.min;
    tideMax = environmentalConditions.tide.max;
  }

  calcMin = largeRound(
    vessel.mouldedDepth - getVesselMaxOfDrafts(vessel) - (berth.wharfHeightAbovePortDatum - tideMin),
  );

  calcMax = largeRound(
    vessel.mouldedDepth - getVesselMinOfDrafts(vessel) - (berth.wharfHeightAbovePortDatum - tideMax),
  );

  return { calc: null, calcMin, calcMax };
};

const setDredgeDepth = (berth: Berth) => {
  const calc = berth.berthPocketDepthBelowPortDatum;

  return calc;
};

const setWindageAreas = (vessel: Vessel, berth: Berth) => {
  if (vessel.draftType === 1) {
    let calc: {
      longitudinal: number;
      transversal: number;
      warnings?: string;
    } = undefined;
    if (vessel.vesselType === VesselType.Tanker) {
      calc = tankerWindageAreas(vessel, getVesselMinOfDrafts(vessel));
    } else if (vessel.vesselType === VesselType.BulkCarrier) {
      calc = bulkCarrierWindageAreas(vessel, getVesselMinOfDrafts(vessel));
    } else if (vessel.vesselType === VesselType.ContainerVessel) {
      calc = containerWindageAreas(vessel, getVesselMinOfDrafts(vessel));
    } else if (vessel.vesselType === VesselType.RoRo) {
      calc = roRoWindageAreas(vessel, getVesselMinOfDrafts(vessel));
    } else if (vessel.vesselType === VesselType.CruiseLiner) {
      calc = cruiseLinerWindageAreas(vessel, getVesselMinOfDrafts(vessel));
    } else if (vessel.vesselType === VesselType.GasCarrier) {
      calc = gasCarrierWindageAreas(vessel, getVesselMinOfDrafts(vessel));
    }

    return {
      longitudinal: calc ? largeRound(calc.longitudinal) : null,
      longitudinalMin: null,
      longitudinalMax: null,
      transversal: calc ? largeRound(calc.transversal) : null,
      transversalMin: null,
      transversalMax: null,
    };
  }

  let calcMin: {
    longitudinal: number;
    transversal: number;
    warnings?: string;
  } = undefined;
  let calcMax: {
    longitudinal: number;
    transversal: number;
    warnings?: string;
  } = undefined;
  if (vessel.vesselType === VesselType.Tanker) {
    calcMin = tankerWindageAreas(vessel, getVesselMaxOfDrafts(vessel));
    calcMax = tankerWindageAreas(vessel, getVesselMinOfDrafts(vessel));
  } else if (vessel.vesselType === VesselType.BulkCarrier) {
    calcMin = bulkCarrierWindageAreas(vessel, getVesselMaxOfDrafts(vessel));
    calcMax = bulkCarrierWindageAreas(vessel, getVesselMinOfDrafts(vessel));
  } else if (vessel.vesselType === VesselType.ContainerVessel) {
    calcMin = containerWindageAreas(vessel, getVesselMaxOfDrafts(vessel));
    calcMax = containerWindageAreas(vessel, getVesselMinOfDrafts(vessel));
  } else if (vessel.vesselType === VesselType.RoRo) {
    calcMin = roRoWindageAreas(vessel, getVesselMaxOfDrafts(vessel));
    calcMax = roRoWindageAreas(vessel, getVesselMinOfDrafts(vessel));
  } else if (vessel.vesselType === VesselType.CruiseLiner) {
    calcMin = cruiseLinerWindageAreas(vessel, getVesselMaxOfDrafts(vessel));
    calcMax = cruiseLinerWindageAreas(vessel, getVesselMinOfDrafts(vessel));
  } else if (vessel.vesselType === VesselType.GasCarrier) {
    calcMin = gasCarrierWindageAreas(vessel, getVesselMaxOfDrafts(vessel));
    calcMax = gasCarrierWindageAreas(vessel, getVesselMinOfDrafts(vessel));
  }

  return {
    longitudinal: null,
    longitudinalMin: calcMin ? largeRound(calcMin.longitudinal) : null,
    longitudinalMax: calcMax ? largeRound(calcMax.longitudinal) : null,
    transversal: null,
    transversalMin: calcMin ? largeRound(calcMin.transversal) : null,
    transversalMax: calcMax ? largeRound(calcMax.transversal) : null,
  };
};

/**
 * Calculate the displacement by using certain details and data from the scenario configured
 * @param {scenarioDTO} scenarioData is the object with all the data (data property has all the details)
 */
const calculateDisplacement = (scenarioData) => {
  /**
    VESSEL DISPLACEMENT
    ===================
      vessel.displacement = CB (block Coefficient) * lpp * draft * beam * 1.025
      The 1.025 is the conversion from m3 to tonnes
      > CB depends on the type of vessel, for now it will come from the vessel itself
      > when draftType === '1', value, use the draftMidValue
      > when draftType === '2', range, use the mean between draftMin and draftMax
   */
  const draftToUse = getDraftToUse(scenarioData);
  const vesselTypeName = VesselType[scenarioData.vessel.vesselType];
  const value = largeRound(
    (scenarioData.vessel.blockCoefficient || CB_DEFAULTS[vesselTypeName] || 0) *
      parseFloat(scenarioData.vessel.lpp) *
      draftToUse *
      parseFloat(scenarioData.vessel.beam) *
      1.025,
  );

  return value;
};

/**
 * Calculate the freeboard by using certain vessel details. Freeboard: is a derived parameter that is used for berth prescreening and for calculation of wind area and fairlead z-position
 * @param {scenarioData} scenarioData is the object with all the data (data property has all the details)
 */
const calculateFreeboard = (scenarioData) => {
  const draftToUse = getDraftToUse(scenarioData);
  const freeboard = largeRound((scenarioData.vessel.mouldedDepth || 0) - draftToUse); // there may be no mouldedDepth as per yet...

  return freeboard;
};

// constants
export { CB_DEFAULTS };
// functions
  export {
    calculateDisplacement,
    calculateFreeboard,
    getVesselMaxOfDrafts,
    getVesselMinOfDrafts,
    setContainerHeightAboveWl,
    setDeckAboveWharf,
    setDerivedValues,
    setDredgeDepthMsl,
    setMaxBeamExceeded,
    setMaxLoaExceeded,
    setOutreachBeyondBeam,
    setSpreaderClearance,
    setUnderKeelClearance
  };

