import { isNumber, round } from 'lodash';

/**
 * Rounds given value to number of decimals set in field's config
 * if any decimals-config given
 * and if the value is a number
 * @param {*} stateValue
 * @param {{conversion: {decimals?: *}, *}} fieldConfig
 */
const fieldRound = (stateValue, fieldConfig) =>
  isNumber(stateValue) &&
  fieldConfig &&
  Object.prototype.hasOwnProperty.call(fieldConfig, 'decimals') &&
  isNumber(fieldConfig.decimals)
    ? round(stateValue, fieldConfig.decimals)
    : round(stateValue, 4); // Any amount of decimals not specified, fallback to 4. This ensures any large floating point numbers, eg. 4.8800000009 are converted simply to 4 as well.

export default fieldRound;
