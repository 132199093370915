const getDataField = (data, field) => {
  const fieldNodes = field.split('.');
  let finalObject = data;

  for (let i = 0; i < fieldNodes.length - 1; i++) {
    finalObject = finalObject[fieldNodes[i]];
  }

  return finalObject;
};

// check out this beauty:
// const getField = (p, o) => p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o);

export default getDataField;
