import fieldIntl, { getSectionProps } from './fieldIntl';

const SECTION_LABEL = 'label';
const SECTION_LABEL_ON = 'onLabel';
const SECTION_LABEL_OFF = 'offLabel';
const propsLabel = getSectionProps(SECTION_LABEL);
const propsLabelOn = getSectionProps(SECTION_LABEL_ON);
const propsLabelOff = getSectionProps(SECTION_LABEL_OFF);

/**
 * Helper function to extract label out of field-config
 * by either having specified one of these
 * two properties in the `fieldConfig` object:
 * - label
 * - labelId
 * @param {Object} intl
 * @param {{label?: string, labelId?: string}} fieldConfig
 * @returns {string}
 */
const fieldLabel = (intl, fieldConfig, fieldName: string) => {
  if (!fieldConfig) {
    throw Error(`No fieldConfig found for ${fieldName}!`);
  }

  return Object.prototype.hasOwnProperty.call(fieldConfig, propsLabel[0]) ||
    Object.prototype.hasOwnProperty.call(fieldConfig, propsLabel[1])
    ? fieldIntl(intl, fieldConfig, SECTION_LABEL)
    : undefined;
};

/**
 * Helper function to extract labelOn out of field-config
 * by either having specified one of these
 * two properties in the `fieldConfig` object:
 * - onLabel
 * - offLabelId
 * @param {Object} intl
 * @param {{label?: string, labelId?: string}} fieldConfig
 * @returns {string}
 */
const fieldOnLabel = (intl, fieldConfig) =>
  Object.prototype.hasOwnProperty.call(fieldConfig, propsLabelOn[0]) ||
  Object.prototype.hasOwnProperty.call(fieldConfig, propsLabelOn[1])
    ? fieldIntl(intl, fieldConfig, SECTION_LABEL_ON)
    : undefined;

/**
 * Helper function to extract labelOff out of field-config
 * by either having specified one of these
 * two properties in the `fieldConfig` object:
 * - offLabel
 * - offLabelId
 * @param {Object} intl
 * @param {{label?: string, labelId?: string}} fieldConfig
 * @returns {string}
 */
const fieldOffLabel = (intl, fieldConfig) =>
  Object.prototype.hasOwnProperty.call(fieldConfig, propsLabelOff[0]) ||
  Object.prototype.hasOwnProperty.call(fieldConfig, propsLabelOff[1])
    ? fieldIntl(intl, fieldConfig, SECTION_LABEL_OFF)
    : undefined;

export { fieldLabel, fieldOnLabel, fieldOffLabel };
