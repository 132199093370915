/**
 * Utility function that combines (MUI) styles,
 * both theme-aware (functions)
 * and theme-unaware (objects)
 *
 * Usage:
 * // We can use style functions that make use of the theme (example):
 * const s1 = theme => ({
 *  toolbar: {
 *    backgroundColor: theme.palette.primary.main,
 *    color: '#fff',
 *    ...theme.mixins.toolbar,
 *  },
 *  link: {
 *    color: theme.palette.primary.main,
 *    width: '100%',
 *    textDecoration: 'none',
 *    padding: '12px 16px',
 *  },
 * });
 *
 *  // And we can use style objects (example):
 *  const s2 = {
 *    menuItem: {
 *      height: 'auto',
 *      padding: 0,
 *    },
 *  };
 *
 *  // Use our util to create a compatible function for `withStyles`:
 *  const combinedStyles = combineStyles(s1, s2, buttonStyles, componentStyles);
 *
 *  // And use `withStyles` as you would normally:
 *  export default withStyles(combinedStyles)(MyComponent);
 *
 * @param  {...function|object} styles
 * @returns {object}
 */
const combineStyles = (...styles) => {
  return function CombineStyles(theme) {
    const outStyles = styles.map((arg) => {
      // Apply the "theme" object for style functions.
      if (typeof arg === "function") {
        return arg(theme);
      }

      // Objects need no change.
      return arg;
    });

    return outStyles.reduce((acc, val) => Object.assign(acc, val));
  };
};

export default combineStyles;
