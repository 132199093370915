import { ALL_CHANNELS } from '../constants';
import linearInterpolation from './linearInterpolation';

const filterByChannelCategory = (value, portSustainabilityStore) => {
  let filteredCategory;

  const { dredgingZones, results } = portSustainabilityStore.portSustainabilityData;

  if (value === ALL_CHANNELS) {
    filteredCategory = dredgingZones.map((item) => item.name);
  } else {
    const filteredZones = dredgingZones.filter((item) => item.category === value);
    filteredCategory = filteredZones.map((item) => item.name);
  }

  const filteredResultsData = results.filter((el) => filteredCategory.includes(el.name));

  const filteredZones = dredgingZones.filter((el) => filteredCategory.includes(el.name));

  portSustainabilityStore.setChannelCategory(value);

  return {
    ...portSustainabilityStore.portSustainabilityData,
    dredgingZones: filteredZones,
    results: linearInterpolation(portSustainabilityStore.dateTime, filteredResultsData),
  };
};

export default filterByChannelCategory;
