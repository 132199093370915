import { displayToField, fieldDefault, getDataField, SCENARIO_DATA_VERSION } from 'src/components/SharedLibrary';

const setDefaultValues = (data: any, dataConfig: any[], intl = null) => {
  // %% SET LATEST VERSION
  data.version = SCENARIO_DATA_VERSION;

  dataConfig.forEach((fieldConfig) => {
    if (Object.prototype.hasOwnProperty.call(fieldConfig, 'default')) {
      const path = fieldConfig.field;
      const field = fieldConfig.field.substr(fieldConfig.field.lastIndexOf('.') + 1);
      const datafield = getDataField(data, path);

      datafield[field] = displayToField(fieldConfig.default, fieldConfig);
    } else if (Object.prototype.hasOwnProperty.call(fieldConfig, 'defaultId') && intl) {
      const path = fieldConfig.field;
      const field = fieldConfig.field.substr(fieldConfig.field.lastIndexOf('.') + 1);
      const datafield = getDataField(data, path);

      datafield[field] = fieldDefault(intl, fieldConfig);
    } else if (Object.prototype.hasOwnProperty.call(fieldConfig, 'defaultMin')) {
      // this is for ranges
      // min
      const pathMin = fieldConfig.fieldMin;
      const fieldMin = fieldConfig.fieldMin?.substr(fieldConfig.fieldMin.lastIndexOf('.') + 1);

      if (fieldMin) {
        const datafieldMin = getDataField(data, pathMin);

        datafieldMin[fieldMin] = displayToField(fieldConfig.defaultMin, fieldConfig);
      }

      // max
      const pathMax = fieldConfig.fieldMax;
      const fieldMax = fieldConfig.fieldMax?.substr(fieldConfig.fieldMax.lastIndexOf('.') + 1);

      if (fieldMax) {
        const datafieldMax = getDataField(data, pathMax);

        datafieldMax[fieldMax] = displayToField(fieldConfig.defaultMax, fieldConfig);
      }
    }
  });

  return data;
};

export default setDefaultValues;
