import { FONT_SIZE_XS } from '../../modules/YardSafe/yardSafeConstants';

export const buildMarkLine = (
  date: string,
  formatText: string,
  color: string,
  lineType: 'solid' | 'dashed' | 'dotted',
  fontWeight: 'normal' | 'bold',
) => {
  return {
    xAxis: date,
    lineStyle: {
      normal: {
        type: lineType,
        color: color,
        width: 2,
      },
    },
    label: {
      fontSize: FONT_SIZE_XS,
      fontWeight: fontWeight,
      formatter: (f) => {
        return formatText;
      },
    },
  };
};
