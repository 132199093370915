import { isNumber } from 'lodash';
import { Diagnostics } from '../../interfaces/Config';
import { Berth } from '../../interfaces/PortData';
import { PortStore, VesselScenarioStore } from '../../stores';
import { getEnumIndex, PrescreenColour, PrescreenIndicator } from '../enums';
import {
  getVesselMaxOfDrafts,
  getVesselMinOfDrafts,
  setMaxBeamExceeded,
  setMaxLoaExceeded,
  setOutreachBeyondBeam,
  setSpreaderClearance,
  setUnderKeelClearance,
} from './fieldCalculations';

export interface PrescreeningResult {
  [key: string]: {
    name: string;
    result: PrescreenIndicator;
    colour: string;
  };
}

const calculateBerthPrescreening = (
  portStore: PortStore,
  vesselScenarioStore: VesselScenarioStore,
  diagnosticsConfig: Diagnostics,
) => {
  const results: PrescreeningResult = {};

  portStore.getAllBerths().forEach((berth) => {
    const result = calculatePrescreeningForBerth(portStore, vesselScenarioStore, berth, diagnosticsConfig);

    if (result) {
      results[result.name] = result;
    }
  });

  return results;
};

const calculatePrescreeningForBerth = (
  portStore: PortStore,
  vesselScenarioStore: VesselScenarioStore,
  berth: Berth,
  diagnosticsConfig: Diagnostics,
): {
  name: string;
  result: PrescreenIndicator;
  colour: PrescreenColour;
} => {
  let { berthPrescreening } = portStore.portData;
  // Use per-berth prescreening, if we have it
  berthPrescreening = berth.berthPrescreening ?? berthPrescreening;
  // Vessel Attributes
  const { vessel, mooring, environmentalConditions } = vesselScenarioStore.scenarioData.data;

  // Escape out if no vessel selected

  if (!vessel || !vessel.vesselName) {
    console.log(
      `%c Cannot calculate Berth Prescreening Report until Vessel selected. `,
      'background-color:#FFCDD2;color:#333;font-weight:bold',
    );

    return null;
  }

  if (!berth || !berth.berthName) {
    console.log(
      `%c Cannot calculate Berth Prescreening Report until Berth selected. `,
      'background-color:#FFCDD2;color:#333;font-weight:bold',
    );

    return null;
  }

  const enabled = true; //vessel.vesselType === VesselType.ContainerVessel;

  let result = PrescreenIndicator.Safe;

  // If non-container vessels, always approve
  if (!enabled) {
    return {
      name: berth.berthName,
      result,
      colour: PrescreenColour.Safe,
    };
  }

  let clgPrintResult: (
    calcValue: number,
    resultValue: PrescreenIndicator,
    safeValue: string | number,
    omitEndLine?: boolean,
  ) => void | null;

  if (diagnosticsConfig.berthPrescreening !== 'none') {
    console.log(
      `%c ${'='.repeat(50)}\n| Berth Prescreening Report - ${berth.berthName}\n${'='.repeat(50)} `,
      'background-color:#E0F7FA;color: #333;font-weight:bold',
    );

    clgPrintResult = (
      calcValue: number,
      resultValue: PrescreenIndicator,
      safeValue: string | number,
      omitEndLine: boolean | undefined = false,
    ) => {
      let colour = PrescreenColour.NotSafe;

      if (resultValue === PrescreenIndicator.Safe) {
        colour = PrescreenColour.Safe;
      } else if (resultValue === PrescreenIndicator.Warning) {
        colour = PrescreenColour.Warning;
      } else colour = PrescreenColour.NotSafe;

      console.log(
        `|\t%c RESULT: ${calcValue}m >= ${safeValue}m %c[${resultValue}] `,
        `font-weight:bold`,
        `background:${colour};font-weight:bold`,
      );

      if (diagnosticsConfig.berthPrescreening === 'verbose' && !omitEndLine) {
        console.log(`${'-'.repeat(50)}`);
      }
    };
  }

  // Only run ship loader pre screening if berth has a ship loader.
  if (berth.shipLoaders.length > 0) {
    const shipLoaderMinOutreachOfBoom = berth.shipLoaders.reduce((prev, curr) =>
      prev.outReachOfBoom > curr.outReachOfBoom ? prev : curr,
    );
    const shipLoaderMinHeightAboveWharf = berth.shipLoaders.reduce((prev, curr) =>
      prev.spreaderHeightAboveWharf > curr.spreaderHeightAboveWharf ? prev : curr,
    );

    // 1. BOOM TRAVEL (aka. OUTREACH BEYOND BOOM)
    if (enabled && shipLoaderMinOutreachOfBoom && isNumber(berthPrescreening.thresholds.outreachBeyondBeam.safe)) {
      const boomCalc = setOutreachBeyondBeam(vessel, shipLoaderMinOutreachOfBoom);

      let boomResult = PrescreenIndicator.NotSafe;

      if (boomCalc >= berthPrescreening.thresholds.outreachBeyondBeam.safe) {
        boomResult = PrescreenIndicator.Safe;
      } else if (boomCalc >= berthPrescreening.thresholds.outreachBeyondBeam.warning) {
        boomResult = PrescreenIndicator.Warning;
      }

      if (PrescreenIndicator[boomResult] < PrescreenIndicator[result]) {
        result = boomResult;
      }

      if (diagnosticsConfig.berthPrescreening !== 'none') {
        console.log(`| %cBoom Travel Beyond Beam:`, 'font-weight:bold');

        if (diagnosticsConfig.berthPrescreening === 'verbose') {
          console.log(
            `| \tshipLoader.outReachOfBoom(${shipLoaderMinOutreachOfBoom.outReachOfBoom}) - shipLoader.railToFender(${shipLoaderMinOutreachOfBoom.railToFender}) - vessel.beam(${vessel.beam})`,
          );
        }

        clgPrintResult!(boomCalc, boomResult, berthPrescreening.thresholds.outreachBeyondBeam.safe);
      }
    } else if (diagnosticsConfig.berthPrescreening !== 'none') {
      console.log(
        `%c Test Skipped - Boom Travel Beyond Beam; berthPrescreening.thresholds not specified for this type. `,
        'background-color:#ffe0b2;color:#333;font-weight:bold',
      );
    }

    // 2. SPREADER CLEARANCE
    if (enabled && shipLoaderMinHeightAboveWharf && isNumber(berthPrescreening.thresholds.spreaderClearance.safe)) {
      const spreaderCalc = setSpreaderClearance(
        portStore,
        vessel,
        berth,
        shipLoaderMinHeightAboveWharf,
        environmentalConditions,
      );

      let spreaderResult = PrescreenIndicator.NotSafe;

      const spreaderClearance = spreaderCalc.calc ?? spreaderCalc.calcMin;

      if (spreaderClearance >= berthPrescreening.thresholds.spreaderClearance.safe) {
        spreaderResult = PrescreenIndicator.Safe;
      } else if (spreaderClearance >= berthPrescreening.thresholds.spreaderClearance.warning) {
        spreaderResult = PrescreenIndicator.Warning;
      }

      if (getEnumIndex(PrescreenIndicator, spreaderResult) < getEnumIndex(PrescreenIndicator, result)) {
        result = spreaderResult;
      }

      if (diagnosticsConfig.berthPrescreening !== 'none') {
        console.log(`| %c Min Spreader Clearance: `, 'font-weight:bold');

        if (diagnosticsConfig.berthPrescreening === 'verbose') {
          let tideValueText = null;
          let tideValue = 0;

          if (mooring.dynamic || environmentalConditions.tide.type === 2) {
            tideValueText = 'tideMax';
            tideValue = environmentalConditions.tide.max;
          } else {
            tideValueText = 'tideValue';
            tideValue = environmentalConditions.tide.value;
          }

          console.log(
            `| \tc1 = shipLoader.spreaderHeightAboveWharf(${shipLoaderMinHeightAboveWharf.spreaderHeightAboveWharf}) + berth.wharfHeightAbovePortDatum(${berth.wharfHeightAbovePortDatum})`,
          );

          console.log(
            `| \tc2 = vessel.loading(${vessel.numberOfContainerTiers}) * CONTAINER_HEIGHT(${vessel.containerHeight}) + vessel.hatchHeightAboveMainDeck(${vessel.hatchHeightAboveMainDeck}) + ` +
              `vessel.mouldedDepth(${vessel.mouldedDepth}) - draftMin(${getVesselMinOfDrafts(
                vessel,
              )}) + ${tideValueText}(${tideValue})`,
          );

          console.log(`| \tc1 - c2 = ${spreaderClearance}`);
        }

        clgPrintResult!(spreaderClearance, spreaderResult, berthPrescreening.thresholds.spreaderClearance.safe);
      }
    } else if (diagnosticsConfig.berthPrescreening !== 'none') {
      console.log(
        `%c Test Skipped - Spreader Clearance; berthPrescreening.thresholds not specified for this type. `,
        'background-color:#ffe0b2;color:#333;font-weight:bold',
      );
    }
  }
  // 3. UNDER KEEL CLEARANCE
  if (enabled && isNumber(berthPrescreening.thresholds.underKeelClearance.safe)) {
      const underKeelCalc = setUnderKeelClearance(portStore,
          vessel,
          berth,
          environmentalConditions
    );
    let underKeelResult = PrescreenIndicator.NotSafe;

    const underKeelClearance = underKeelCalc.calc ?? underKeelCalc.calcMin;

    if (underKeelClearance >= berthPrescreening.thresholds.underKeelClearance.safe) {
      underKeelResult = PrescreenIndicator.Safe;
    } else if (underKeelClearance >= berthPrescreening.thresholds.underKeelClearance.warning) {
      underKeelResult = PrescreenIndicator.Warning;
    }

    if (getEnumIndex(PrescreenIndicator, underKeelResult) < getEnumIndex(PrescreenIndicator, result)) {
      result = underKeelResult;
    }

    if (diagnosticsConfig.berthPrescreening !== 'none') {
      console.log(`| %c Min Under Keel Clearance: `, 'font-weight:bold');

      if (diagnosticsConfig.berthPrescreening === 'verbose') {
        let tideValueText = null;
        let tideValue = 0;

        if (environmentalConditions.tide.type === 1) {
          tideValueText = 'tideValue';
          tideValue = environmentalConditions.tide.value;
        } else {
          tideValueText = 'tideMin';
          tideValue = environmentalConditions.tide.min;
        }

        console.log(
          `| \tberth.berthPocketDepthBelowPortDatum(${
            berth.berthPocketDepthBelowPortDatum
          }) + ${tideValueText}(${tideValue}) - draftMax(${getVesselMaxOfDrafts(vessel)})`,
        );

        console.log(`| \tUnder Keel Clearance = ${underKeelClearance}`);
      }

      clgPrintResult!(underKeelClearance, underKeelResult, berthPrescreening.thresholds.underKeelClearance.safe);
    }
  } else if (diagnosticsConfig.berthPrescreening !== 'none') {
    console.log(
      `%c Test Skipped - Under Keel Clearance; berthPrescreening.thresholds not specified for this type. `,
      'background-color:#ffe0b2;color:#333;font-weight:bold',
    );
  }

  // 4. MAX BEAM EXCEEDED
  const maxBeamCalc = setMaxBeamExceeded(vessel, berth);
  let maxBeamResult = PrescreenIndicator.NotSafe;

  if (maxBeamCalc) {
    maxBeamResult = PrescreenIndicator.Safe;
  } else {
    maxBeamResult = PrescreenIndicator.Warning;
  }

  if (getEnumIndex(PrescreenIndicator, maxBeamResult) < getEnumIndex(PrescreenIndicator, result)) {
    result = maxBeamResult;
  }

  if (diagnosticsConfig.berthPrescreening !== 'none') {
    console.log(`| %c Max Beam Clearance: `, 'font-weight:bold');

    if (berth.maximumVesselBeam == null) {
      console.log(
        `%c Test Skipped - Max Beam Clearance; maximumVesselBeam not specified in config. `,
        'background-color:#ffe0b2;color:#333;font-weight:bold',
      );
    } else if (diagnosticsConfig.berthPrescreening === 'verbose') {
      console.log(`| \tvessel.beam(${vessel.beam}) < berth.maximumVesselBeam(${berth.maximumVesselBeam})`);

      clgPrintResult!(vessel.beam, maxBeamResult, berth.maximumVesselBeam!);
    }
  }

  // 5. MAX LOA
  const maxLoaCalc = setMaxLoaExceeded(vessel, berth);
  let maxLoaResult = PrescreenIndicator.NotSafe;

  if (maxLoaCalc) {
    maxLoaResult = PrescreenIndicator.Safe;
  } else {
    maxLoaResult = PrescreenIndicator.Warning;
  }

  if (getEnumIndex(PrescreenIndicator, maxLoaResult) < getEnumIndex(PrescreenIndicator, result)) {
    result = maxLoaResult;
  }

  if (diagnosticsConfig.berthPrescreening !== 'none') {
    console.log(`| %c Max LOA Clearance: `, 'font-weight:bold');

    if (berth.maximumVesselLOA == null) {
      console.log(
        `%c Test Skipped - Max LOA Clearance; maximumVesselLOA not specified in config. `,
        'background-color:#ffe0b2;color:#333;font-weight:bold',
      );
    } else if (diagnosticsConfig.berthPrescreening === 'verbose') {
      console.log(`| \tvessel.loa(${vessel.loa}) < berth.maximumVesselLOA(${berth.maximumVesselLOA})`);

      clgPrintResult!(vessel.loa, maxLoaResult, berth.maximumVesselLOA!, true);
    }
  }

  let colour = PrescreenColour.NotSafe;

  if (result === PrescreenIndicator.Warning) {
    colour = PrescreenColour.Warning;
  } else if (result === PrescreenIndicator.Safe) {
    colour = PrescreenColour.Safe;
  }

  if (diagnosticsConfig.berthPrescreening !== 'none') {
    console.log(`${'='.repeat(50)}`);

    console.log(`| %c Overall Result: ${result} `, `background-color:${colour};color:#333;font-weight:bold`);

    console.log(`%c ${'='.repeat(50)} `, 'color:#333;font-weight:bold');
  }

  return {
    name: berth.berthName,
    result,
    colour,
  };
};

export { calculateBerthPrescreening, calculatePrescreeningForBerth };
