import { Dictionary } from "./dictionary";

const en: Dictionary = {
  "auth.menu.login": "Login",
  "auth.menu.logout": "Logout",
  "auth.menu.profile": "Profile",
  "components.views.startDate": "Start Date",
  "components.views.endDate": "End Date",
  "auth.status.loggingin": "Logging in",
  "components.scenarioEditor.fields.name": "Scenario Name",
  "components.scenarioEditor.fields.name.default": "New MA Scenario",
  "components.scenarioEditor.fields.name.placeholder": "Scenario Name",
  "components.scenarioEditor.fields.dredgeDate": "Dredge Date",
  "components.scenarioEditor.fields.endDate": "End Date",
  "components.scenarioList.menuItems.edit": "Edit",
  "components.scenarioList.menuItems.editPS": "Edit",
  "components.scenarioList.menuItems.execute": "Execute",
  "components.scenarioList.menuItems.tryExecute": "Execute",
  "components.scenarioList.menuItems.clone": "Clone",
  "components.scenarioList.menuItems.remove": "Delete",
  "components.scenarioList.menuItems.terminate": "Cancel Execution",
  "components.scenarioList.menuItems.openPdf": "Open PDF",
  "components.ma.scenarioList.menuItems.openPdf_LineHandlers":
    "Open Line Handlers PDF",
  "components.scenarioList.menuItems.openXlsx": "Open Excel",
  "components.ma.scenarioList.menuItems.openXlsx_LineHandlers":
    "Open Line Handlers Excel",
  "components.scenarioList.menuItems.viewResults": "View Results",
  "components.scenarioList.menuItems.viewReport": "View Report",
  "components.scenarioList.menuItems.viewLog": "View Log",
  "components.cc.views.compareScenarioId": "Compare Scenario",
  "components.cc.views.resultLocation": "Select Location",
  "components.cc.scenarioEditor.tabs.wind": "Select Wind Input",
  "components.cc.scenarioEditor.tabs.tributaries": "Select Rainfall Input",
  "components.cc.scenarioEditor.tabs.tide": "Select SLR Input",
  "components.cc.scenarioEditor.tabs.portAssets": "Select Port Asset Layout",
  "components.cc.scenarioEditor.fields.name": "Climate Scenario Name",
  "components.cc.scenarioEditor.fields.name.default": "New Scenario",
  "components.cc.scenarioEditor.fields.name.placeholder":
    "Climate Scenario Name",
  "components.cc.scenarioEditor.fields.projectionYear": "Projection Year",
  "components.cc.scenarioEditor.fields.environmentalConditions.wind.name":
    "Name Input",
  "components.cc.scenarioEditor.fields.environmentalConditions.wind.directionList":
    "Select Wind Direction Array",
  "components.cc.scenarioEditor.fields.environmentalConditions.wind.averageRecurrenceInterval":
    "Average Recurrence Interval",
  "components.cc.scenarioEditor.fields.environmentalConditions.wind.windSpeedIncrease":
    "Wind Speed Increase",
  "components.cc.scenarioEditor.fields.environmentalConditions.wind.windSpeedIncreaseUnit":
    "Unit",
  "components.cc.scenarioEditor.fields.environmentalConditions.tributaries.name":
    "Name Input",
  "components.cc.scenarioEditor.fields.environmentalConditions.tributaries.averageRecurrenceInterval":
    "Average Recurrence Interval",
  "components.cc.scenarioEditor.fields.environmentalConditions.tributaries.dischargeStations":
    "River Discharge Sources",
  "components.cc.scenarioEditor.fields.environmentalConditions.tributaries.rainfallAmplifier":
    "Rainfall Amplifier",
  "components.cc.scenarioEditor.fields.environmentalConditions.tributaries.rainfallAmplifierLowerMark":
    "Lower",
  "components.cc.scenarioEditor.fields.environmentalConditions.tributaries.rainfallAmplifierMidMark":
    "Mid-point",
  "components.cc.scenarioEditor.fields.environmentalConditions.tributaries.rainfallAmplifierUpperMark":
    "Upper",
  "components.cc.scenarioEditor.fields.environmentalConditions.tide.name":
    "Name Input",
  "components.cc.scenarioEditor.fields.environmentalConditions.tide.slrYears":
    "Sea Level Rise Estimates Relative to Early 21st Century",
  "components.cc.scenarioEditor.fields.environmentalConditions.tide.slrWeighting":
    "Sea Level Weighting",
  "components.cc.scenarioEditor.fields.environmentalConditions.tide.slrWeightingLowerMark":
    "Lower",
  "components.cc.scenarioEditor.fields.environmentalConditions.tide.slrWeightingMidMark":
    "Mid-point",
  "components.cc.scenarioEditor.fields.environmentalConditions.tide.slrWeightingUpperMark":
    "Upper",
  "components.ma.dialogs.customiseLineProperties": "Customise Line Properties",
  "components.ma.dialogs.customiseTailProperties": "Customise Tail Properties",
  "components.ma.dialogs.customiseWinchProperties":
    "Customise Winch Properties",
  "components.cc.forms.dischargeCannotBeNegative":
    "Resetting discharge values, as they cannot be negative.",
  "components.cc.forms.scenarioFormInvalid":
    "Please correct following values before saving scenario:",
  "components.ma.forms.saveOffline": "Saving temporarily offline",
  "components.ma.forms.berth.linePretension": "Line Pretension",
  "components.ma.forms.berth.lineRange": "Line Pretension Range",
  "components.ma.forms.berth.lineValue": "Line Pretension Value",
  "components.ma.berth.prescrenning.boomTravelBeyondBeam":
    "Maximum Reach exceeded.",
  "components.ma.berth.prescrenning.minSpreaderClearance":
    "Maximum Airdraft exceeded.",
  "components.ma.berth.prescrenning.minUKC":
    "Minimum UKC requirement exceeded.",
  "components.ma.berth.prescrenning.maxBeamClearance":
    "Vessel beam exceeds allowable ship width at berth.",
  "components.ma.berth.prescrenning.maxLOAClearance":
    "Vessel length exceeds allowable ship length at berth.",
  "components.ma.forms.environment.current": "Current",
  "components.ma.forms.environment.directionFrom": "(DIRECTION IS COMING FROM)",
  "components.ma.forms.environment.directionTo": "(DIRECTION IS GOING TO)",
  "components.ma.forms.environment.forecast": "Forecast",
  "components.ma.forms.environment.range": "Range",
  "components.ma.forms.environment.tide": "Tide",
  "components.ma.forms.environment.value": "Value",
  "components.ma.forms.environment.wave": "Wave",
  "components.ma.forms.environment.waveMatrixConditions":
    "Wave Matrix Conditions",
  "components.ma.forms.environment.wind": "Wind",
  "components.ma.forms.lineLengthInput": "Input Line Length",
  "components.ma.forms.lineLengthConverged": "Converged Line Length",
  "components.ma.forms.mooringLinesInvalid":
    "One or more problems with the mooring arrangement.",
  "components.ma.forms.mooringLinesBollardCapacityExceeded":
    "Bollard capacity of {1} exceeded.",
  "components.ma.forms.mooringLinesFairleadCapacityExceeded":
    "Fairlead capacity of {1} exceeded.",
  "components.ma.forms.mooringLinesNoBollardsAvailable":
    "No bollards suitable for line {1}.",
  "components.ma.forms.mooringLinesHorizontalAngleViolated":
    "The recommended horizontal angle of {1}° is outside of the range {2}-{3}°.",
  "components.ma.forms.mooringLinesVerticalAngleViolated":
    "The vertical angle of {1}° is greater than {2}°.",
  "components.ma.forms.mooringLinesTooShort":
    "The line length of {1} is less than the minimum length of {2}.",
  "components.ma.forms.mooringLinesTooLong":
    "The line length of {1} is greater than the maximum length of {2}.",
  "components.ma.forms.mooringLinesCrossingDeck": "The line crosses the deck",
  "components.ma.forms.mooringLinesVerticallyTooClose":
    "The line is too close to another line {1}",
  "components.ma.forms.mooringProximityError":
    "Vessel's proximity too close to other vessels across similar duration.",
  "components.ma.forms.scenarioFormBerthReset":
    "Berth selection has been reset due to input parameters exceeding berth limits.",
  "components.ma.forms.scenarioFormEndTimeReset":
    "End time duration selection has been reset due to berth change.",
  "components.ma.forms.scenarioFormInvalid":
    "Please correct following values before saving scenario:",
  "components.ma.forms.scenarioMissingVessel": "First choose a vessel",
  "components.ma.forms.stats.title": "Statistics",
  "components.ma.forms.vesselSpecs.draft": "Vessel Draft",
  "components.ma.forms.vesselSpecs.draftRange": "Draft Range",
  "components.ma.forms.vesselSpecs.draftValue": "Draft Value",
  "components.ma.forms.vesselspecs.inputClipScenarioId":
    "Scenario Id (Click the clip to copy)",
  "components.ma.mooringmap.goto": "Go to",
  "components.page.menu.climateChange": "Climate Change",
  "components.page.menu.mooringAnalysis": "Mooring Analysis",
  "components.page.menu.mooringScheduling": "Terminal Scheduling",
  "components.ma.scenarioEditor.fields.environmentalConditions.current.directionBinSize":
    "Direction Bin Size",
  "components.ma.scenarioEditor.fields.environmentalConditions.current.directionRange":
    "Direction",
  "components.ma.scenarioEditor.fields.environmentalConditions.current.directionValue":
    "Direction",
  "components.ma.scenarioEditor.fields.environmentalConditions.current.speedBinSize":
    "Speed Bin Size",
  "components.ma.scenarioEditor.fields.environmentalConditions.current.speedRange":
    "Speed",
  "components.ma.scenarioEditor.fields.environmentalConditions.current.speedValue":
    "Speed",
  "components.ma.scenarioEditor.fields.environmentalConditions.current.type":
    "Wave Type",
  "components.ma.scenarioEditor.fields.environmentalConditions.tide.binSize":
    "Tide Bin Size",
  "components.ma.scenarioEditor.fields.environmentalConditions.tide.range":
    "Tide",
  "components.ma.scenarioEditor.fields.environmentalConditions.tide.type":
    "Tide Type",
  "components.ma.scenarioEditor.fields.environmentalConditions.tide.value":
    "Tide",
  "components.ma.scenarioEditor.fields.environmentalConditions.wave.directionBinSize":
    "Direction Bin Size",
  "components.ma.scenarioEditor.fields.environmentalConditions.wave.directionRange":
    "Direction",
  "components.ma.scenarioEditor.fields.environmentalConditions.wave.directionValue":
    "Direction",
  "components.ma.scenarioEditor.fields.environmentalConditions.wave.directionalStandardDeviationBinSize":
    "Direction Standard Deviation Bin Size",
  "components.ma.scenarioEditor.fields.environmentalConditions.wave.heightBinSize":
    "Height Bin Size",
  "components.ma.scenarioEditor.fields.environmentalConditions.wave.heightRange":
    "Height",
  "components.ma.scenarioEditor.fields.environmentalConditions.wave.heightValue":
    "Height",
  "components.ma.scenarioEditor.fields.environmentalConditions.wave.periodBinSize":
    "Period Bin Size",
  "components.ma.scenarioEditor.fields.environmentalConditions.wave.periodRange":
    "Period",
  "components.ma.scenarioEditor.fields.environmentalConditions.wave.periodValue":
    "Period",
  "components.ma.scenarioEditor.fields.environmentalConditions.wave.type":
    "Wave Type",
  "components.ma.scenarioEditor.fields.environmentalConditions.wind.directionBinSize":
    "Direction Bin Size",
  "components.ma.scenarioEditor.fields.environmentalConditions.wind.directionRange":
    "Direction",
  "components.ma.scenarioEditor.fields.environmentalConditions.wind.directionValue":
    "Direction",
  "components.ma.scenarioEditor.fields.environmentalConditions.wind.speedBinSize":
    "Speed Bin Size",
  "components.ma.scenarioEditor.fields.environmentalConditions.wind.speedRange":
    "Speed",
  "components.ma.scenarioEditor.fields.environmentalConditions.wind.speedValue":
    "Speed",
  "components.ma.scenarioEditor.fields.environmentalConditions.wind.type":
    "Wind Type",
  "components.ma.scenarioEditor.fields.usesCrossScenarioAwareness":
    "Include Adjacent Vessels",
  "components.ma.scenarioEditor.fields.mooring.bowMarker": "Bow",
  "components.ma.scenarioEditor.fields.mooring.berthMarkerLabel":
    "Berth Markers",
  "components.ma.scenarioEditor.fields.mooring.berthMarker": "Bridge",
  "components.ma.scenarioEditor.fields.mooring.sternMarker": "Stern",
  "components.ma.scenarioEditor.fields.mooring.berthName": "Berth",
  "components.ma.scenarioEditor.fields.mooring.deckAboveWharf":
    "Deck Above Wharf",
  "components.ma.scenarioEditor.fields.mooring.dredgeDepth": "Dredged Depth",
  "components.ma.scenarioEditor.fields.mooring.dynamic": "Use Live Forecast",
  "components.ma.scenarioEditor.fields.mooring.startTime": "ETA",
  "components.ma.scenarioEditor.fields.mooring.endTime": "ETD",
  "components.ma.scenarioEditor.fields.mooring.lockMooringArrangement":
    "Lock Mooring Arrangement",
  "components.ma.scenarioEditor.fields.mooring.unlockMooringArrangement":
    "Unlock Mooring Arrangement",
  "components.ma.scenarioEditor.fields.mooring.linePretensionBinSize":
    "Line Pretension Bin Size",
  "components.ma.scenarioEditor.fields.mooring.linePretensionRange":
    "Line Pretension Range",
  "components.ma.scenarioEditor.fields.mooring.linePretensionType":
    "Line Pretension Type",
  "components.ma.scenarioEditor.fields.mooring.linePretensionValue":
    "Line Pretension Value",
  "components.ma.scenarioEditor.fields.mooring.lines.breast": "Breast",
  "components.ma.scenarioEditor.fields.mooring.lines.spring": "Spring",
  "components.ma.scenarioEditor.fields.mooring.lines.short.bow": "Bow",
  "components.ma.scenarioEditor.fields.mooring.lines.short.foreBreast":
    "Fore Breast",
  "components.ma.scenarioEditor.fields.mooring.lines.short.foreSpring":
    "Fore Spring",
  "components.ma.scenarioEditor.fields.mooring.lines.short.aftSpring":
    "Aft Spring",
  "components.ma.scenarioEditor.fields.mooring.lines.short.aftBreast":
    "Aft Breast",
  "components.ma.scenarioEditor.fields.mooring.lines.short.stern": "Stern",
  "components.ma.scenarioEditor.fields.mooring.lines.long.bowLine": "Bow Line",
  "components.ma.scenarioEditor.fields.mooring.lines.long.foreBreastLine":
    "Fore Breast Line",
  "components.ma.scenarioEditor.fields.mooring.lines.long.foreSpringLine":
    "Fore Spring Line",
  "components.ma.scenarioEditor.fields.mooring.lines.long.aftSpringLine":
    "Aft Spring Line",
  "components.ma.scenarioEditor.fields.mooring.lines.long.aftBreastLine":
    "Aft Breast Line",
  "components.ma.scenarioEditor.fields.mooring.lines.long.sternLine":
    "Stern Line",
  "components.ma.scenarioEditor.fields.mooring.lines.headerBollard": "Bollard",
  "components.ma.scenarioEditor.fields.mooring.lines.headerFairlead":
    "Fairlead",
  "components.ma.scenarioEditor.fields.mooring.lines.headerLine": "Line",
  "components.ma.scenarioEditor.fields.mooring.lines.headerLines": "Lines",
  "components.ma.scenarioEditor.fields.mooring.lines.headerLineType":
    "Line Type",
  "components.ma.scenarioEditor.fields.mooring.lines.headerLineMaterial":
    "Line Material",
  "components.ma.scenarioEditor.fields.mooring.lines.headerTail": "Tail",
  "components.ma.scenarioEditor.fields.mooring.lines.headerTailType":
    "Tail Type",
  "components.ma.scenarioEditor.fields.mooring.lines.headerTailMaterial":
    "Tail Material",
  "components.ma.scenarioEditor.fields.mooring.lines.headerWinch": "Winch Type",
  "components.ma.scenarioEditor.fields.mooring.lines.none": "None",
  "components.ma.scenarioEditor.fields.mooring.lines.noTail": "No Tail",
  "components.ma.scenarioEditor.fields.mooring.lines.setAllLines":
    "Set All Lines",
  "components.ma.scenarioEditor.fields.mooring.lines.setAllTails":
    "Set All Tails",
  "components.ma.scenarioEditor.fields.mooring.lines.setAllWinches":
    "Set All Winches",
  "components.ma.scenarioEditor.fields.mooring.lines.addShoreTension":
    "Add Shore Tension",
  "components.ma.scenarioEditor.fields.mooring.lineMinimumBreakingLoad":
    "Minimum Breaking Load [tonnes]",
  "components.ma.scenarioEditor.fields.mooring.tailMinimumBreakingLoad":
    "Minimum Breaking Load [tonnes]",
  "components.ma.scenarioEditor.fields.mooring.tailLength": "Tail Length [m]",
  "components.ma.scenarioEditor.fields.mooring.winchBrakeHoldingCapacity":
    "Brake Holding Capacity [tonnes]",
  "components.ma.scenarioEditor.fields.mooring.maxBeamExceeded":
    "Exceeding Max Beam",
  "components.ma.scenarioEditor.fields.mooring.maxLoaExceeded":
    "Exceeding Max LOA",
  "components.ma.scenarioEditor.fields.mooring.mooringArrangement":
    "Mooring Arrangement Configuration",
  "components.ma.scenarioEditor.fields.mooring.mooringArrangementName":
    "Mooring Arrangement",
  "components.ma.scenarioEditor.fields.mooring.customisedMooringArrangement":
    "Customised",
  "components.ma.scenarioEditor.fields.mooring.outreachBeyondBeam":
    "Outreach Beyond Beam",
  "components.ma.scenarioEditor.fields.mooring.portDock": "Quayside",
  "components.ma.scenarioEditor.fields.mooring.portDockPort": "Port",
  "components.ma.scenarioEditor.fields.mooring.portDockStarboard": "Starboard",
  "components.ma.scenarioEditor.fields.mooring.spreaderClearance":
    "Spreader Clearance",
  "components.ma.scenarioEditor.fields.mooring.underKeelClearance":
    "Under Keel Clearance",
  "components.ma.scenarioEditor.fields.name": "Scenario Name",
  "components.ma.scenarioEditor.fields.name.default": "New MA Scenario",
  "components.ma.scenarioEditor.fields.name.placeholder": "Scenario Name",
  "components.ma.scenarioEditor.fields.port.airTemperature": "Air Temperature",
  "components.ma.scenarioEditor.fields.port.salinity": "Salinity",
  "components.ma.scenarioEditor.fields.port.waterTemperature":
    "Water Temperature",
  "components.scenarioEditor.fields.vessel.beam": "Beam",
  "components.ma.scenarioEditor.fields.vessel.bridgeToBow": "Bridge To Bow",
  "components.ma.scenarioEditor.fields.vessel.displacement": "Displacement",
  "components.scenarioEditor.fields.vessel.draftAftValue": "Aft Draft",
  "components.ma.scenarioEditor.fields.vessel.draftBinSize": "Draft Bin Size",
  "components.scenarioEditor.fields.vessel.draftForeValue": "Fore Draft",
  "components.scenarioEditor.fields.vessel.draftMidValue": "Mid Draft",
  "components.ma.scenarioEditor.fields.vessel.draftRange": "Draft Range",
  "components.ma.scenarioEditor.fields.vessel.draftType": "Draft Type",
  "components.ma.scenarioEditor.fields.vessel.geometricCentreHeight": "GMf",
  "components.ma.scenarioEditor.fields.vessel.hatchHeightAboveMainDeck":
    "Hatch Height Above Main Deck",
  "components.scenarioEditor.fields.vessel.imo": "IMO No",
  "components.scenarioEditor.fields.vessel.loa": "LOA",
  "components.scenarioEditor.fields.vessel.lbp": "LBP",
  "components.scenarioEditor.fields.vessel.speedProfile": "Speed Profile",
  "components.scenarioEditor.fields.vessel.dwt": " DWT",
  "components.scenarioEditor.fields.vessel.eventId": "Event ID",
  "components.scenarioEditor.fields.vessel.vesselType": "Vessel Type",
  "components.scenarioEditor.fields.transit.startLocation": "Start Location",
  "components.scenarioEditor.fields.transit.endLocation": "End Location",
  "components.scenarioEditor.fields.transit.swing": "Swing",
  "components.scenarioEditor.fields.transit.swingOn": "Swing",
  "components.scenarioEditor.fields.transit.swingOff": "No Swing",
  "components.scenarioEditor.fields.transit.eventNotes": "Event Notes",
  "components.scenarioEditor.fields.vessel.loading": "Loading",
  "components.scenarioEditor.fields.vessel.loadingContainers":
    "Containers High On Deck",
  "components.ma.scenarioEditor.fields.vessel.tiers": "tiers",
  "components.ma.scenarioEditor.fields.vessel.tonnes": "tonnes",
  "components.ma.scenarioEditor.fields.vessel.longitudinalWindageArea":
    "Longitudinal Windage Area",
  "components.ma.scenarioEditor.fields.vessel.lpp": "LPP",
  "components.ma.scenarioEditor.fields.vessel.mouldedDepth": "Moulded Depth",
  "components.ma.scenarioEditor.fields.vessel.rxx": "X Radii Gyration",
  "components.ma.scenarioEditor.fields.vessel.ryy": "Y Radii Gyration",
  "components.ma.scenarioEditor.fields.vessel.rzz": "Z Radii Gyration",
  "components.ma.scenarioEditor.fields.vessel.totalHeightAboveWl":
    "Total Height Above WL",
  "components.ma.scenarioEditor.fields.vessel.transversalWindageArea":
    "Transversal Windage Area",
  "components.ma.scenarioEditor.fields.vessel.lineTypeConfirmed":
    "Line Type confirmed?",
  "components.ma.scenarioEditor.fields.vessel.winchTypeConfirmed":
    "Winch Type confirmed?",
  "components.ma.scenarioEditor.fields.vessel.vesselName": "Vessel Name / IMO",
  "components.ma.scenarioEditor.fields.vessel.vesselName.placeholder":
    "Search Vessel By Name / IMO",
  "components.ma.scenarioEditor.tabs.berthConfig": "Berth Configuration",
  "components.ma.scenarioEditor.tabs.enviroForcings": "Environmental Forcings",
  "components.ma.scenarioEditor.tabs.vesselSpec": "Vessel Specification",
  "components.ma.scenarioList.menuItems.clone": "Clone",
  "components.ma.scenarioList.menuItems.customClone": "Clone",
  "components.ma.scenarioList.menuItems.remove": "Delete",
  "components.ma.scenarioList.menuItems.delete": "Delete",
  "components.ma.scenarioList.menuItems.edit": "Edit",
  "components.ma.scenarioList.menuItems.execute": "Execute",
  "components.ma.scenarioList.menuItems.tryExecute": "Execute",
  "components.ma.scenarioList.menuItems.terminate": "Cancel Execution",
  "components.ma.scenarioList.menuItems.openPdf": "Open PDF",
  "components.ma.scenarioList.menuItems.openXlsx": "Open Excel",
  "components.ma.scenarioList.menuItems.viewResults": "View Results",
  "components.ma.scenarioList.menuItems.viewReport": "View Report",
  "components.ma.scenarioList.menuItems.viewLog": "View Log",
  "components.scenarioList.translations.executeConfirmation":
    "This will start a new job in the background. The status will change after job completion. Are you sure you want to execute %job%?",
  "components.scenarioList.translations.terminateConfirmation":
    "This will cancel the job currently executing. The status will change after job cancellation. Are you sure you want to terminate %job%?",
  "components.scenarioList.translations.cloneConfirmation":
    "Are you sure you want to clone %job%?",
  "components.scenarioList.translations.deleteConfirmation":
    "This will delete the selected scenario from the list. After it is deleted you cannot retrieve the data. Are you sure you want to delete %job%?",
  "components.ma.scenarioList.translations.cancelLabel": "Cancel",
  "components.scenarioList.translations.confirmLabel": "Confirm",
  "components.scenarioList.translations.viewResults.compare":
    "Optionally, select other scenarios to compare against",
  "components.ma.scenarioList.translations.cloneLabel": "Yes, Clone",
  "components.ma.scenarioList.translations.deleteLabel": "Yes, Delete Scenario",
  "components.ma.scenarioList.translations.cloneTitle": "Clone",
  "components.ma.scenarioList.translations.deleteTitle": "Delete",
  "components.ma.scenarioList.title": "Scenarios",
  "components.page.menu.yardSafe": "Yard Safe",
  "components.YardSafe.global.existingOperatingInstructions":
    "Existing Operating Instructions",
  "components.YardSafe.global.critialEventImminent":
    "Warning: {time} until critical event",
  "components.YardSafe.global.conservativeData":
    "Critical data feeds from terminal are not available. Using last available data.",
  "components.page.menu.transits": "Transits",
  "components.transits.scheduledVesselsTransits.title":
    "Vessel Transit Schedule",
  "components.transits.page.dropdown.tideStation": "Tide Station",
  "components.transits.page.dropdown.waveStation": "Wave Station",
  "components.transits.scenarioEditor.tabs.vesselSpec": "Vessel Specification",
  "components.transits.scenarioEditor.tabs.transitWindow": "Transit Window",
  "components.transits.scenarioEditor.tabs.overrides": "Overrides",
  "components.page.menu.liveWeather": "Live Weather",
  "components.transits.scenarioList.menuItems.editTransit": "Edit",
  "components.transits.scenarioList.menuItems.execute": "Execute",
  "components.ps.scenarioEditor.fields.siltationForecastDate":
    "Evaluation Date",
  "components.ps.scenarioEditor.fields.dredgingCost": "Dredging Cost",
  "components.ps.scenarioEditor.fields.dredgingZones": "Dredging Zone",
  "components.ps.scenarioEditor.fields.designDepth": "Design Depth",
  "components.ps.scenarioEditor.fields.dredgeDepth": "Dredge Depth",
  "components.ps.scenarioEditor.fields.dredgeVolume": "Dredge Volume",
  "components.ps.scenarioEditor.fields.channelCategory": "Channel Category",
  "components.ps.scenarioEditor.fields.timeToRedredgeMonths":
    "Months to Re-Dredge",
  "components.ps.scenarioEditor.fields.date": "Date",
  "components.ps.scenarioEditor.fields.name": "Name",
  "components.ps.scenarioEditor.fields.description": "Description",
  "components.ps.scenarioEditor.fields.inputDataResolution":
    "Data Resolution [m]",
  "components.ps.scenarioEditor.fields.surveyDate": "Survey Date",
  "components.ps.scenarioEditor.fields.surveyDepth": "Survey Depth",
  "components.ps.scenarioEditor.fields.colorPalette": "Color Palette",
  "components.ps.scenarioEditor.fields.mode": "Mode",
  "components.ps.siltation.button.mapView": "Map View",
  "components.ps.siltation.button.bathymetricModel": "Bathymetric Model",
  "components.ps.siltation.button.dredgeRecords": "Dredge Records",
  "components.ps.siltation.button.analyticsView": "Analytics View",
  "components.viewResults.viewButton.downloadReport": "Download Report",
  "components.sar.driftEditing.tabs.vesselSpec": "Vessel Specification",
  "components.sar.fields.validate.invalidDate": "Invalid Date",
  "components.sar.fields.validate.tooEarly": "Must be after drift start",
  "components.sar.fields.validate.tooLate": "Must be before (now + 24 hours)",
  "components.sar.driftEditing.tabs.eventSpec": "Event Specification",
  "components.sar.driftEditing.tabs.overrides": "Overrides",
  "components.sar.lists.tabs.alerts": "Alerts",
  "components.sar.lists.tabs.drifts": "Drift Scenarios",
  "components.sar.driftEditing.fields.requiredLabel": "(Required)",
  "components.sar.driftEditing.fields.scenarioName": "SAR candidate name",
  "components.sar.driftEditing.fields.driftingObject.objectTypeName":
    "Object type",
  "components.sar.driftEditing.fields.driftingObject.loa": "LOA [m]",
  "components.sar.driftEditing.fields.driftingObject.length": "Length [m]",
  "components.sar.driftEditing.fields.driftingObject.width": "Width [m]",
  "components.sar.driftEditing.fields.driftingObject.lpp": "LPP [m]",
  "components.sar.driftEditing.fields.driftingObject.beam": "Beam [m]",
  "components.sar.driftEditing.fields.driftingObject.draftMin":
    "Vessel draft [m]",
  "components.sar.driftEditing.fields.driftingObject.transverseWindAreaMin":
    "Transverse wind area [m²]",
  "components.sar.driftEditing.fields.driftingObject.longitudinalWindAreaMin":
    "Longitudinal wind area [m²]",
  "components.sar.driftEditing.fields.driftingObject.loadingConditionMin":
    "Loading condition [%]",
  "components.sar.driftEditing.fields.driftingObject.submersionLevelMin":
    "Level of submersion [%]",
  "components.sar.driftEditing.fields.driftingObject.height": "Height [m]",
  "components.sar.driftEditing.fields.driftingObject.mmsi": "MMSI",
  "components.sar.driftEditing.fields.environmentalConditions.windEnabled":
    "Wind (direction coming from)",
  "components.sar.driftEditing.fields.environmentalConditions.windSpeed":
    "Wind speed [kn]",
  "components.sar.driftEditing.fields.environmentalConditions.windDirection":
    "Wind direction [deg]",
  "components.sar.driftEditing.fields.assimilation.runAssimilation": "AIS Drift Assimilation",
  "components.sar.driftEditing.fields.environmentalConditions.currentEnabled":
    "Current (direction going to)",
  "components.sar.driftEditing.fields.environmentalConditions.currentSpeed":
    "Current speed [kn]",
  "components.sar.driftEditing.fields.environmentalConditions.currentDirection":
    "Current direction [deg]",
  "components.sar.driftEditing.fields.environmentalConditions.wavesEnabled":
    "Waves (direction coming from)",
  "components.sar.driftEditing.fields.environmentalConditions.wavesHs":
    "Hs [m]",
  "components.sar.driftEditing.fields.environmentalConditions.wavesTp":
    "Tp [sec]",
  "components.sar.driftEditing.fields.environmentalConditions.wavesDirection":
    "Dir [deg]",
  "components.sar.driftEditing.fields.initialConditions.uncertaintyNS":
    "N-S uncertainty [deg]",
  "components.sar.driftEditing.fields.initialConditions.uncertaintyWE":
    "W-E uncertainty [deg]",
  "components.sar.driftEditing.fields.initialConditions.longitude": "Longitude",
  "components.sar.driftEditing.fields.initialConditions.latitude": "Latitude",
  "components.sar.driftEditing.fields.initialConditions.initialHeadingMin":
    "Heading [deg]",
  "components.sar.driftEditing.fields.initialConditions.initialSpeedMin":
    "Initial speed min [kn]",
  "components.sar.driftEditing.fields.initialConditions.initialSpeedMax":
    "Initial speed max [kn]",
  "components.sar.driftEditing.fields.initialConditions.initialDirectionMin":
    "Direction [deg]",
  "components.sar.driftEditing.fields.initialConditions.driftStartTime":
    "Time of last known position [UTC]",
  "components.sar.driftEditing.fields.initialConditions.driftStartTime.min": "Must be after (UTC now - 1 hours)",
  "components.sar.driftEditing.fields.initialConditions.driftStartTime.max": "Must be before (UTC now + 6 days)",
  "components.sar.driftEditing.fields.initialConditions.numberOfParticles":
    "Number of particles",
  "components.sar.candidateEditing.fields.mmsi": "MMSI",
  "components.sar.candidateEditing.fields.vesselName": "Vessel name",
  "components.sar.candidateEditing.fields.actualLatitude": "Current latitude",
  "components.sar.candidateEditing.fields.actualLongitude": "Current longitude",
  "components.sar.candidateEditing.fields.initialLatitude":
    "Starting latitude",
  "components.sar.candidateEditing.fields.initialLongitude":
    "Starting longitude",
  "components.sar.candidateEditing.fields.initialMinTimeGrounding":
    "Starting minimum time to grounding",
  "components.sar.candidateEditing.fields.initialInterceptTimeETV":
    "Starting intercept time",
  "components.sar.candidateEditing.fields.actualMinTimeGrounding":
    "Actual minimum time to grounding",
  "components.sar.candidateEditing.fields.actualInterceptTimeETV":
    "Actual intercept time",
  "components.sar.candidateEditing.fields.mobilizationTime":
    "Mobilization time",
  "components.sar.candidateEditing.fields.timeBuffer": "Time buffer",
  "components.sar.candidateEditing.fields.alertDelayTime": "Alert delay",
  "components.sar.candidateEditing.fields.watchZoneLatitude":
    "Watch zone latitude",
  "components.sar.candidateEditing.fields.watchZoneLongitude":
    "Watch zone longitude",
  "components.sar.candidateEditing.fields.watchZoneDateTime":
    "Watch zone created",
  "components.sar.candidateEditing.fields.watchZoneRadius":
    "Watch zone radius [nm]",
  "components.sar.candidateEditing.fields.watchZoneHours":
    "Watch zone hours valid [h]",
  "components.sar.candidateEditing.fields.status":
    "Drift candidate alert status",
  "components.sar.candidateEditing.fields.actualTime":
    "Time of current location [UTC]",
  "components.sar.candidateEditing.fields.initialTime":
    "Drift start time [UTC]",
  "components.sar.candidateEditing.fields.watchZoneExpiry":
    "Watch zone time of expiry [UTC]",
  "components.sar.lodgedEvents.title": "Lodged SAR Events",
  "components.sar.scenarioList.menuItems.openPdf": "View Occurence Report",
  "components.sar.scenarioList.menuItems.downloadZip": "Download Occurrences",
  "components.sar.scenarioList.menuItems.executeSar": "Execute",
  "components.sar.scenarioList.menuItems.searchPlan": "Search Plan",
  "components.sar.scenarioList.menuItems.interceptPlan": "Intercept Plan",
  "components.sar.scenarioList.menuItems.editSar": "Edit",
  "components.sar.scenarioList.menuItems.toggleStatus": "Toggle Status",
  "components.sar.scenarioList.menuItems.clone": "Clone",
  "components.sar.scenarioList.menuItems.delete": "Delete",
  "components.sar.scenarioList.menuItems.diagnostics": "Diagnostics",
  "components.sar.scenarioList.translations.deleteTitle": "Delete",
  "components.sar.scenarioList.translations.deleteConfirmation":
    "This will delete the selected scenario from the list. After it is deleted you cannot retrieve the data. Are you sure you want to delete %job%?",
  "components.sar.scenarioList.translations.deleteLabel":
    "Yes, Delete Scenario",
  "components.sar.scenarioList.translations.new": "+ New Scenario",
  "components.sar.scenarioList.translations.shown": "Scenarios Shown",
  "components.sar.driftList.translations.apply": "Apply Watch Zone",
  "components.sar.driftList.translations.convertdrift": "Drift Scenario",
  "components.sar.interceptEditor.translations.title": "Intercept Plan",
  "components.sar.interceptEditor.translations.vesselTypes": "Vessel Type",
  "components.sar.interceptEditor.fields.tug.location.y": "Latitude",
  "components.sar.interceptEditor.fields.tug.location.x": "Longitude",
  "components.sar.interceptEditor.fields.tug.departureTime": "Departure Time",
  "components.sar.interceptEditor.fields.tug.towageCapacity": "Maximum Bollard Pull [tf]",
  "components.sar.interceptEditor.fields.tug.targetTowageSpeed": "Towage Speed [kn]",
  "components.sar.interceptEditor.fields.tug.targetTowageDirection": "Towage Direction",
  "components.sar.interceptEditor.fields.tug.speedFunction": "Speed Function (Required)",
  "components.sar.interceptEditor.fields.tug.planName": "Intercept Plan Name",
  "components.sar.searchPatternEditor.translations.title": "Search Plan",
  "components.sar.searchPatternEditor.translations.vesselTypes": "Vessel Type",
  "components.sar.searchPatternEditor.translations.aircraftBase": "Aircraft Base",
  "components.sar.searchPatternEditor.fields.aircraft.location.y": "Latitude",
  "components.sar.searchPatternEditor.fields.aircraft.location.x": "Longitude",
  "components.sar.searchPatternEditor.fields.aircraft.maxSpeed": "Max Speed [kn]",
  "components.sar.searchPatternEditor.fields.aircraft.enduranceTime": "Range [h]",
  "components.sar.searchPatternEditor.fields.aircraft.overrideSearch": "Overrides",
  "components.sar.searchPatternEditor.fields.aircraft.searchSpeed": "Search Speed [kn]",
  "components.sar.searchPatternEditor.fields.aircraft.trackSpacing": "Track Spacing [nm]",
  "components.sar.searchPatternEditor.fields.aircraft.searchAtNight": "Search At Night",
  "components.sar.searchPatternEditor.fields.aircraft.refuelTime": "Refuel Time [h]",
  "components.sar.searchPatternEditor.fields.aircraft.departureTime": "Departure Time",
  "components.sar.searchPatternEditor.fields.aircraft.departureTime.min": "Must be after drift start time",
  "components.sar.searchPatternEditor.fields.aircraft.departureTime.max": "Must be before (drift start time + 1 days)",
  "components.sar.legendBar.translations.wind": "Wind",
  "components.sar.legendBar.translations.current": "Current",
  "components.sar.legendBar.translations.waves": "Waves",
  "components.sar.legendBar.translations.vessels": "Vessels",
  "components.sar.legendBar.translations.alerts": "Alerts",
  "components.sar.legendBar.translations.createdScenarios": "Created Scenarios",
  "components.sar.legendBar.translations.failedSCenarios": "Failed Scenarios",
  "components.sar.legendBar.translations.occurrenceLikelihood":
    "Occurrence Likelihood",
  "components.sar.legendBar.translations.windScale": "Wind [kn]",
  "components.sar.legendBar.translations.currentScale": "Current [kn]",
  "components.sar.legendBar.translations.waveScale": "Waves [kn]",
  "engine.errorMessages.beamScaling":
    "The target beam is {1}% larger/smaller then the source ship beam.",
  "engine.errorMessages.convergenceError":
    "The mooring system could not converge. Please generate a more symetrical mooring system.",
  "engine.errorMessages.depthScaling":
    "The target depth is {1} larger/smaller then the source ship draft.",
  "engine.errorMessages.draftMouldedDepthError":
    "The maximum draft ({1}) is greater then the moudled depth ({2}).",
  "engine.errorMessages.draftScaling":
    "The target draft is {1} larger/smaller then the source ship draft.",
  "engine.errorMessages.draftWaterDepthError":
    "The maximum draft ({1}) is greater then the minimum water depth ({2}).",
  "engine.errorMessages.forcingProcessingTime":
    "Environmental forcing processing run time was {1}",
  "engine.errorMessages.frequencyResponseProcessingTime":
    "Frequency response processing run time was {1}",
  "engine.errorMessages.loaScaling":
    "The target LOA is {1}% larger/smaller then the source ship LOA.",
  "engine.errorMessages.mooringAnalysisProcessingTime":
    "Mooring analysis run time was {1}",
  "engine.errorMessages.mooringSystemConvergenceProcessingTime":
    "Mooring system convergence run time was {1}",
  "engine.errorMessages.resultsProcessingTime":
    "Results postprocessing run time was {1}",
  "engine.errorMessages.shipSurgeViolation":
    "The ship surged {1} during convergence. Consider generating a more symetrical mooring system.",
  "engine.errorMessages.shipSwayViolation":
    "The ship swayed {1} during convergence. Consider generating a more symetrical mooring system.",
  "engine.errorMessages.shipYawViolation":
    "The ship yawed {1} during convergence. Consider generating a more symetrical mooring system.",
  "engine.errorMessages.technicalError":
    "There has been a technical error running this scenario. Please call NCOS Support.",
  "engine.errorMessages.totalProcessingTime":
    "Total processing run time was {1}",
  "engine.errorMessages.vcgScaling":
    "The target vertical centre of gravity is {1} larger/smaller then the source ship draft",
  "engine.automoor.errorMessages.generalError":
    "Automoor could not moor the vessel at this location. Please adjust berth configuration.",
  "engine.automoor.errorMessages.succeedWithWarnings":
    "Warnings were encountered during Automoor:",
  "engine.automoor.errorMessages.possibleLinesError":
    "No suitable mooring arrangement could be found.",
  "engine.automoor.errorMessages.possibleLinesWarning":
    "Mooring arrangement found, but with less lines than desired.",
  "engine.automoor.errorMessages.bollardOverloadWarning":
    "No suitable mooring arrangement found, because bollards would be overloaded.",
  "engine.automoor.errorMessages.subOptimalMooringSystem":
    "Mooring rules eased in this scenario. Move the ship up or down the berth to see if a better mooring arrangement can be achieved.",
  "global.button.cancel": "Cancel",
  "global.button.save": "Save",
  "global.button.saveDraft": "Save as Draft",
  "global.button.saveAndExecute": "Save & Execute",
  "global.button.saveAndRegister": "Save & Register",
  "global.button.elearning": "E-LEARNING",
  "global.button.next": "Next",
  "global.button.back": "Back",
  "global.help": "Help",
  "global.evaluationPurposes": "For Evaluation Purposes Only.",
  "global.page.notFound": "Page not found",
  "global.scenario.status.editing": "Editing",
  "global.scenario.status.failed": "Failed",
  "global.scenario.status.running": "Running",
  "global.scenario.status.success": "Completed",
  "global.scenarioLogViewer.columns.description": "Description",
  "global.scenarioLogViewer.noResults": "No log entries to display",
  "global.scenarioLogViewer.title": "Scenario Log",
  "global.settings": "Settings",
  "global.unit.direction": "Direction",
  "global.unit.length": "Length",
  "global.yes": "Yes",
  "global.no": "No",
  "validate.decimalsDisallowed": "Must not contain decimal places",
  "validate.decimalsExceeded": "Maximum of {decimals, number} decimal places",
  "validate.valueMaximum": "{maximum, number} maximum",
  "validate.valueMinimum": "{minimum, number} minimum",
  "validate.valueNumeric": "Must be a number",
  "validate.valuePositive": "Must be a positive number",
  "validate.valueRequired": "Value required",
  "validate.valuesOutsideBounds":
    "Only values from {minimum, number} to {maximum, number}",
  "vesselType.bulkCarrier": "Bulk Carrier",
  "vesselType.containerVessel": "Container Vessel",
  "vesselType.cruiseLiner": "Cruise Liner",
  "vesselType.generalCargo": "General Cargo",
  "vesselType.tanker": "Tanker",
  "vesselType.unknown": "Unknown Vessel Type",
};

export default en;
