export type ModuleName = "MooringAnalysis" | "ClimateChange" | "YardSafe";

export enum VesselType {
  Unknown = 0,
  ContainerVessel = 1,
  Tanker = 2,
  BulkCarrier = 3,
  Roro = 4,
  CruiseLiner = 5,
  GeneralCargo = 6,
}

export enum SpeedProfile {
  Slow = 0,
  Fast = 1,
}

/**
 * Get ordinal index position of an Enum member in an Enum.
 * @param enumType Enum
 * @param value Enum member phrase to find index on
 */
export const getEnumIndex = (enumType: any, value: any) =>
  Object.keys(enumType).indexOf(value);
