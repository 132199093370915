import { largeRound } from ".";

const getDraftToUse = (scenarioData) => {
  const draftToUse =
    scenarioData.vessel.draftType === 1
      ? parseFloat(scenarioData.vessel.draftMidValue) * 1.0
      : (1.0 * scenarioData.vessel.draftMin +
          1.0 * scenarioData.vessel.draftMax) /
        2;

  return largeRound(draftToUse);
};

export default getDraftToUse;
