import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { NotFoundPage } from './components/common';
import { useStores } from './hooks/useStores';

interface Props {
  appConfig: any;
}

export const Routes: React.FC<Props> = ({ appConfig }: Props) => {
  const { appStateStore } = useStores();

  const loadPage = (page) => {
    // Set current module
    appStateStore.setCurrentModule(page.id);
    console.log(`%c Current module is ${appStateStore.currentModule} `, `background-color:#cae4ff;color:#333;`);

    return React.createElement(
      lazy(() => import(`./modules/${page.path}`)),
      {
        componentConfig: page.config,
      },
    );
  };

  return (
    <Switch>
      {appStateStore.authorisedModules.map((module) => {
        console.log(`%c User has access to ${module.id} `, `background-color:#caffdd;color:#333;`);

        return module.pages.map((page) => (
          <Route
            exact
            // path={page.id === 'MooringAnalysis' ? ['/', module.route + page.route] : [module.route + page.route]}
            path={[module.route + page.route]}
            key={page.id}
            render={() => loadPage(page)}
          />
        ));
      })}
      <Route path="*" component={NotFoundPage} />
    </Switch>
  );
};
