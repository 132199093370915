import { getDraftToUse, largeRound, VesselType } from "..";

// These constants about Block Coefficient (CB) could be written on the configuration file instead,
// for now they will be here
const TANKER_CB = 0.85; // above 0.8, around 0.85 usually
const CONTAINER_CB = 0.7; // 0.65 to 0. 75 usually
const CARGO_CB = 0.75; // 0.7 to 0.8 usually
const BULK_CB = 0.85; // 0.7 to 0.8 usually

const CB_DEFAULTS = {
  Tanker: TANKER_CB,
  ContainerVessel: CONTAINER_CB,
  GeneralCargo: CARGO_CB,
  BulkCarrier: BULK_CB,
};

/**
 * Calculate the displacement by using certain details and data from the scenario configured
 * @param {scenarioDTO} scenarioData is the object with all the data (data property has all the details)
 */
const calculateDisplacement = (scenarioData) => {
  /**
      VESSEL DISPLACEMENT
      ===================
        vessel.displacement = CB (block Coefficient) * lpp * draft * beam * 1.025
        The 1.025 is the conversion from m3 to tonnes
        > CB depends on the type of vessel, for now it will come from the vessel itself
        > when draftType === '1', value, use the draftMidValue
        > when draftType === '2', range, use the mean between draftMin and draftMax
     */
  const draftToUse = getDraftToUse(scenarioData);
  const vesselTypeName = VesselType[scenarioData.vessel.vesselType];
  const value = largeRound(
    (scenarioData.vessel.blockCoefficient || CB_DEFAULTS[vesselTypeName] || 0) *
      parseFloat(scenarioData.vessel.lpp) *
      draftToUse *
      parseFloat(scenarioData.vessel.beam) *
      1.025
  );

  return value;
};

export default calculateDisplacement;
