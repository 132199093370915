import { IntlShape } from 'react-intl';
import { Line } from 'src/interfaces/VesselScenario';

/**
 * To be used in conjunction with `getLineNames`, eg.
 * getLineNames(mooringArrangement, null, allStores.configStore.diagnosticsLineNames)
 */
const diagnosticsLineNames = {
  bowLine: 'Bow Line',
  foreBreastLine: 'Fore Breast Line',
  foreSpringLine: 'Fore Spring Line',
  aftSpringLine: 'Aft Spring Line',
  aftBreastLine: 'Aft Breast Line',
  sternLine: 'Stern Line',
};

const lineTypeIdList = ['bowLine', 'foreBreastLine', 'foreSpringLine', 'aftSpringLine', 'aftBreastLine', 'sternLine'];
const lineTypeIdShortList = ['bow', 'foreBreast', 'foreSpring', 'aftSpring', 'aftBreast', 'stern'];
const lineGroupKeys = ['bowStern', 'breast', 'spring', 'spring', 'breast', 'bowStern'];

const groupBy = (list: Line[], keyGetter: (row: Line) => number) => {
  const map: Map<number, Line[]> = new Map();

  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);

    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });

  return map;
};

export interface LineName {
  name: string;
  number: number;
}

const getLineNames = (
  lines: Line[],
  intl: IntlShape,
  short?: boolean,
  altLineNames?: { [key: string]: string },
): LineName[] => {
  const lineTypes = groupBy(lines, (row) => row.lineType);
  const lineNames: LineName[] = [];

  lineTypes.forEach((lineTypeSet) => {
    lineTypeSet.forEach((line: any, index: number) => {
      const lineName = altLineNames
        ? altLineNames[lineTypeIdList[line.lineType - 1]]
        : intl.formatMessage({
            id: short
              ? `components.ma.scenarioEditor.fields.mooring.lines.short.${lineTypeIdShortList[line.lineType - 1]}`
              : `components.ma.scenarioEditor.fields.mooring.lines.long.${lineTypeIdList[line.lineType - 1]}`,
          });

      lineNames[line.id] = { name: lineName, number: index + 1 };
    });
  });

  return lineNames;
};

export { diagnosticsLineNames, getLineNames, lineGroupKeys, lineTypeIdList };
