import { withStyles, IconButton, SnackbarContent } from "@material-ui/core";
import {
  CheckCircle as CheckCircleIcon,
  Close as CloseIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  Warning as WarningIcon,
} from "@material-ui/icons";
import clsx from "clsx";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styles from "./styles";
import { CustomSnackbarContentProps, SnackbarItem } from "./types";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
  toast: InfoIcon,
};

const CustomSnackbarContentWrapper = ({
  classes,
  errorSet,
  onClose,
  variant,
}: CustomSnackbarContentProps) => {
  const Icon = variantIcon[variant];
  const errors = [];
  const intl = useIntl();

  errorSet.forEach((es, index) => {
    const lastIndex = index === errorSet.length - 1;

    const description = (item: SnackbarItem) => {
      if (!item.parts) {
        if (item.messageId) {
          return intl.formatMessage({
            id: item.messageId,
            defaultMessage: item.messageId,
          });
        } else {
          return item.description;
        }
      } else {
        return intl.formatMessage(
          {
            id: item.messageId,
            defaultMessage: item.messageId,
          },
          ["", ...item.parts] as any
        );
      }
    };

    const itemList = [];

    if (es.items) {
      itemList.push(
        es.items.map((item: SnackbarItem, index: number) => (
          <li key={item.id} style={{ marginTop: 2 }}>
            <span>{item.title}</span>
            {item.description
              ? (item.title ? " – " : "") + item.description
              : item.messageId && (
                  <>
                    &nbsp;-{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {description(item)}
                    </span>
                  </>
                )}
          </li>
        ))
      );
    }

    const renderItems = itemList && (
      <ul
        style={{
          margin: `0 0 ${!lastIndex ? 12 : 0}px 0`,
          fontWeight: "bold",
          fontSize: "1em",
        }}
      >
        {itemList}
      </ul>
    );

    errors.push(
      <div
        key={index}
        style={{
          borderBottom: !lastIndex ? "solid 1px rgba(255,255,255,200)" : "none",
          marginBottom: !lastIndex ? 12 : 0,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          <p
            style={{
              marginTop: 0,
              marginLeft: "10px",
              fontWeight: errorSet.length > 1 ? "bold" : "normal",
            }}
          >
            <FormattedMessage id={es.messageId} defaultMessage={es.messageId} />
          </p>
        </div>
        {renderItems}
      </div>
    );
  });

  return (
    errorSet && (
      <SnackbarContent
        className={clsx(classes[variant], "customSnackbar")}
        aria-describedby="client-snackbar"
        style={{ padding: 16, alignItems: "flex-start" }}
        message={
          <div
            id="client-snackbar"
            className={classes.message}
            style={{ flexDirection: "column" }}
          >
            {errors}
          </div>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={onClose}
            style={{ pointerEvents: "all" }}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    )
  );
};

export default withStyles(styles, { withTheme: true })(
  CustomSnackbarContentWrapper
);
