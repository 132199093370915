import AuthService from '@dhi/react-components';
import { addDays, format } from 'date-fns';
import { action, makeObservable, observable } from 'mobx';
import { IntlShape } from 'react-intl';
import { ModuleName, Session } from 'src/components/SharedLibrary';
import { buildTokenizedUrl } from 'src/modules/PortSustainability/helpers';
import { RootStore } from './RootStore';

export class PSAppStateStore {
  private authService: AuthService;
  private allStores: RootStore;
  observedSession: Session;

  // *******************
  // TODO: If adding new observables here, add their reset also to resetAppState()
  currentModule: ModuleName;
  currentPage: string;
  editMode: boolean;
  isNewMode: boolean;
  bottomPanelShowing: boolean;
  rightPanelShowing = true;
  scenarioLogShowing: boolean;
  currentView: Record<string, any>;
  siltationMapView: boolean;
  visibleScenarios: any[];
  linkToPdf: any;
  // *******************

  constructor(allStores: RootStore) {
    this.allStores = allStores;
    this.authService = new AuthService(process.env.REACT_APP_API_ENDPOINT_URL);
    this.currentPage = this.getCurrentPageFromPath();

    makeObservable(this, {
      observedSession: observable,

      // *******************
      currentModule: observable,
      currentPage: observable,
      editMode: observable,
      isNewMode: observable,
      bottomPanelShowing: observable,
      rightPanelShowing: observable,
      currentView: observable,
      scenarioLogShowing: observable,
      siltationMapView: observable,
      visibleScenarios: observable,
      linkToPdf: observable,
      // *******************
      setEditMode: action.bound,
      setCurrentPage: action.bound,
      setIsNewMode: action.bound,
      // setSession: action.bound,
      setBottomPanelShowing: action.bound,
      setRightPanelShowing: action.bound,
      resetAppState: action.bound,
      setupSession: action.bound,
      logout: action.bound,
      setVisibleScenarios: action.bound,
      buildLinkToPdf: action.bound,
    });
  }

  getCurrentPageFromPath() {
    // Get the current path from the browser's URL
    const currentPath = window.location.pathname;

    // Extract the last part of the path
    const pathParts = currentPath.split('/');
    const lastPart = pathParts[pathParts.length - 1];

    return lastPart || 'siltation-forecast'; // Set a default page if the path doesn't contain a specific page
  }

  get session(): Session {
    return (
      this.observedSession || {
        user: null,
        customerCode: null,
        token: {
          accessToken: null,
          refreshToken: null,
        },
      }
    );
  }

  setCurrentPage = (page: string) => {
    this.currentPage = page;
  };

  setEditMode = (mode: boolean) => {
    this.editMode = mode;
  };

  setIsNewMode = (mode: boolean) => {
    this.isNewMode = mode;
  };

  // setSession = (session: Session) => {
  //   this.observedSession = session;
  // };

  setBottomPanelShowing = (show: boolean) => {
    this.bottomPanelShowing = show;
  };

  setRightPanelShowing = (show: boolean) => {
    this.rightPanelShowing = show;
  };

  setSiltationMapView = (map: boolean) => {
    this.siltationMapView = map;
  };

  resetAppState = () => {
    // this.currentModule = null;
    this.isNewMode = false;
    this.editMode = false;
    this.bottomPanelShowing = true;
    this.rightPanelShowing = true;
    this.currentView = {};
    // Todo: Should these dates be read in from config.applicationSettings.[start|end]Date ?
    this.currentView.startDate = format(addDays(new Date(), -30), 'yyyy-MM-dd');
    this.currentView.endDate = format(addDays(new Date(), 14), 'yyyy-MM-dd');
    this.siltationMapView = false;
    this.scenarioLogShowing = false;
  };

  setupSession = (user?, accessToken?, refreshToken?, customerCode?, intl?) => {
    this.allStores.appStateStore.setSession({
      user,
      token: {
        accessToken,
        refreshToken,
      },
      customerCode,
    });

    // Set or reset based on value
    this.allStores.appStateStore.resetAppState();
    this.allStores.configStore.fetchConfigData(customerCode, (config) => {
      this.currentView.startDate = format(
        addDays(new Date(), config.applicationSettings.startDateOffset ?? -7),
        'yyyy-MM-dd',
      );
      this.currentView.endDate = format(
        addDays(new Date(), config.applicationSettings.endDateOffset ?? 14),
        'yyyy-MM-dd',
      );
    });
  };

  logout = (intl: IntlShape) => {
    console.log('handleLogout');

    this.setupSession(null);
    this.authService.logout();
    window.history.replaceState('', module.id, '/');
  };

  /**
   * Scenarios filtered by the Start and End Date
   * @param scenarios
   */
  setVisibleScenarios(scenarios: any) {
    this.visibleScenarios = scenarios;
  }

  /**
   * Builds PDF from the Channel Planner scenario View Report
   * @param viewReportUrl
   * @param scenarios
   */
  buildLinkToPdf(viewReportUrl: string, scenarios: any[]) {
    const { customerCode, token } = this.allStores.appStateStore.session;

    this.linkToPdf = buildTokenizedUrl(viewReportUrl, scenarios, customerCode, token.accessToken);
  }
}
