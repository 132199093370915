import { IntlShape } from 'react-intl';
import { DataConfig } from 'src/interfaces/Config';
import { PortData } from 'src/interfaces/PortData';
import { displayToField } from '../helpers/conversions';
import { SCENARIO_DATA_VERSION } from './constants';
import { fieldDefault } from './fieldIntl';
import getDataField from './getDataField';

// TODO: Data input here follows a specific pattern but isnt defined by only one type
const setDefaultValues = (data: any, dataConfig: DataConfig[], portData: PortData, intl: IntlShape = null) => {
  // %% SET LATEST VERSION
  data.version = SCENARIO_DATA_VERSION;

  const isAutoGeneratedEvent = data?.autoGeneratedEvent;

  if (data.port) {
    data.port.motionCriteria = portData.motionCriteria;
    data.port.utmProjection = portData.utmProjection;
  }

  dataConfig.forEach((fieldConfig) => {
    const path = fieldConfig.field;
    const field = fieldConfig.field.substr(fieldConfig.field.lastIndexOf('.') + 1);
    const datafield = getDataField(data, path);
    const pathName = path.substr(0, path.indexOf('.'));

    if (isAutoGeneratedEvent && pathName !== 'environmentalConditions') return;

    if (Object.prototype.hasOwnProperty.call(fieldConfig, 'default')) {
      datafield[field] = displayToField(fieldConfig.default as number, fieldConfig);
    } else if (Object.prototype.hasOwnProperty.call(fieldConfig, 'defaultId') && intl) {
      datafield[field] = fieldDefault(intl, fieldConfig);
    } else if (Object.prototype.hasOwnProperty.call(fieldConfig, 'defaultMin')) {
      // this is for ranges
      // min
      const pathMin = fieldConfig.fieldMin;
      const fieldMin = fieldConfig.fieldMin?.substr(fieldConfig.fieldMin.lastIndexOf('.') + 1);

      if (fieldMin) {
        const datafieldMin = getDataField(data, pathMin);

        datafieldMin[fieldMin] = displayToField(fieldConfig.defaultMin, fieldConfig);
      }

      // max
      const pathMax = fieldConfig.fieldMax;
      const fieldMax = fieldConfig.fieldMax?.substr(fieldConfig.fieldMax.lastIndexOf('.') + 1);

      if (fieldMax) {
        const datafieldMax = getDataField(data, pathMax);

        datafieldMax[fieldMax] = displayToField(fieldConfig.defaultMax, fieldConfig);
      }
    }
  });

  // Set tide range values as per initilisation provided
  if (data.environmentalConditions.tide.min === 0 && data.environmentalConditions.tide.max === 0) {
    data.environmentalConditions.tide.min = portData.mlwPortDatum;
    data.environmentalConditions.tide.max = portData.mhwPortDatum;
  }

  return data;
};

export default setDefaultValues;
