import { COLOR_BLUE_DHI } from '../../theme';
const NUMBER_OF_OBSERVATION_HOURS = 48;
const CRITICAL_EVENT_WINDOW = 12;
const CONSERVATIVE_DATA_WINDOW = 2;
const TIMESERIES_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm";
const PRESENTATION_DATE_FORMAT = 'yyyy-MM-dd HH:mm';
const EVENT_TIME_FORMAT = 'HH:mm';
const EVENT_TIME_WITH_DDMM_FORMAT = 'dd/MM, HH:mm';
const OBSERVED_WIND_SPEED = 'observed-wind-speed';
const FORECAST_WIND_SPEED = 'forecast-wind-speed';
const FORECAST_WIND_GUSTS = 'forecast-wind-gusts';
// const OBSERVED_WIND_DIRECTION = 'obeserved-wind-direction';
const FORECAST_WIND_DIRECTION = 'forecast-wind-direction';
const CONSERVATIVE_DATA = 'conservative-data';
const OUT_OF_BOUNDS_STACKS = 'out-of-bounds-stacks';

export const CONTAINER_STATUS = {
  INVALID: 'Invalid',
  SAFE: 'Safe',
  CRITICAL: 'Critical',
  WARNING: 'Warning',
  CONDITIONAL: 'Conditional',
  ACTIONABLE: 'Actionable',
} as const;

// Fonts
const fontMultiplier = 1.5;

export const FONT_SIZE_XL = 26;
export const FONT_SIZE_L = 22 * fontMultiplier;
export const FONT_SIZE_BODY = 15 * fontMultiplier;
export const FONT_SIZE_S = 12 * fontMultiplier;
export const FONT_SIZE_XS = 10 * fontMultiplier;

// Color shades - https://icolorpalette.com/color/f7e950
export const WIND_SPEED_LEVEL_0_COLOR = '#7CE183';
export const WIND_SPEED_LEVEL_0_COLOR_LIGHT = '#eefbef';
export const WIND_SPEED_LEVEL_1_COLOR = '#F7E950';
export const WIND_SPEED_LEVEL_1_COLOR_LIGHT = '#fcf8cc';
export const WIND_SPEED_LEVEL_2_COLOR = '#E79636';
export const WIND_SPEED_LEVEL_2_COLOR_LIGHT = '#f9e6cf';
export const WIND_SPEED_LEVEL_3_COLOR = '#DE3D4A';
export const WIND_SPEED_LEVEL_3_COLOR_LIGHT = '#f7d1d4';

const VERTICAL_BAR_LABEL_STYLE = {
  color: '#FFF',
  padding: 5,
  fontSize: 14,
  align: 'left',
  position: 'end',
  shadowColor: '#000',
  shadowBlur: 2,
};

const POINTER = {
  icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
  length: '60%',
  width: 10,
  offsetCenter: [0, '-30%'],
  itemStyle: {
    color: 'black',
  },
};

const AXIS_TICK = {
  length: 1,
  lineStyle: {
    color: 'auto',
    width: 0.1,
  },
};

const SPLIT_LINE = {
  length: 14,
  lineStyle: {
    color: 'auto',
    width: 3,
  },
};

const WIND_GAUGE_TITLE = {
  offsetCenter: [0, '-50%'],
  fontSize: FONT_SIZE_L,
  textBorderType: 'solid',
  zlevel: 99,
  color: COLOR_BLUE_DHI,
};

const DETAIL = {
  fontSize: FONT_SIZE_BODY,
  offsetCenter: [0, '-5%'],
  valueAnimation: true,
  fontFamily: "'Roboto', 'Helvetica', sans-serif",

  color: COLOR_BLUE_DHI,
};

interface AxisLabelConfig {
  text: string;
  style: string;
  color?: string;
  fontFamily?: string;
  fontSize?: string;
  padding?: number[];
  additionalStyles?: Record<string, any>;
}

interface AxisLabels {
  [key: number]: AxisLabelConfig;
}

const createdWindAxisPoints = (
  windSpeedLevels: number[],
  windSpeedLabels: string[],
  maxValue: number,
  minVal = 0,
): AxisLabels => {
  const axisLabels: AxisLabels = {};
  windSpeedLevels.forEach((windSpeedLevel, index) => {
    axisLabels[windSpeedLevel] = {
      text: windSpeedLabels[index],
      style: 'warningStyle',
    };
  });
  return axisLabels;
};

const createAxisLabel = (levelLabels: AxisLabels) => {
  const formatter = (value) => {
    const levelLabel = levelLabels[value];
    if (levelLabel) {
      return levelLabel.text;
    } else {
      return null;
    }
  };

  return {
    distance: -70,
    fontFamily: "'Roboto', 'Helvetica', sans-serif",
    fontSize: FONT_SIZE_XS,
    formatter,
  };
};

const getFormattedGaugeLabel = (value: number, config: { [key: string]: string }) => {
  let gaugeLabel = `${value} ${config.unit}`;

  if (config.windSpeedAlertLevels) {
    const alertLevels: { [key: number]: string } = config.windSpeedAlertLevels;

    // Iterate through the keys in alertLevels to find the level that matches the data value.
    Object.keys(alertLevels).forEach((alertLevel) => {
      if (value >= parseFloat(alertLevel)) {
        // Update the gaugeLabel with the corresponding alert level.
        gaugeLabel = alertLevels[alertLevel];
      }
    });
  }

  //if NO wind speed alert levels labels are defined, return the default gaugeLabel(actual wind speed)
  return gaugeLabel;
};

export {
  AXIS_TICK,
  CONSERVATIVE_DATA,
  CONSERVATIVE_DATA_WINDOW,
  CRITICAL_EVENT_WINDOW,
  DETAIL,
  EVENT_TIME_FORMAT,
  EVENT_TIME_WITH_DDMM_FORMAT,
  FORECAST_WIND_DIRECTION,
  FORECAST_WIND_GUSTS,
  FORECAST_WIND_SPEED,
  NUMBER_OF_OBSERVATION_HOURS,
  OBSERVED_WIND_SPEED,
  OUT_OF_BOUNDS_STACKS,
  POINTER,
  PRESENTATION_DATE_FORMAT,
  SPLIT_LINE,
  TIMESERIES_DATE_FORMAT,
  VERTICAL_BAR_LABEL_STYLE,
  WIND_GAUGE_TITLE,
  createAxisLabel,
  createdWindAxisPoints,
  getFormattedGaugeLabel,
};
