import { green, orange, red } from '@material-ui/core/colors';

export const ABOVE_DESIGN_DEPTH = 'Above Design Depth';
export const DESIGN_TO_HIGH_TOLERANCE = 'Design Depth to Insurance Depth';
export const BELOW_MAX_DREDGE_DEPTH = 'Below Insurance Depth';

export const ALL_CHANNELS = 'All Channels';

export const RED = red[500];
export const ORANGE = orange[700];
export const GREEN = green[700];

export const DATE_FORMAT_FOR_ENGINE = 'yyyy-MM-dd';
export const TIMESERIES_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";
