import { AppStateStore } from './AppStateStore';
import { ConfigStore } from './ConfigStore';
import { PortScenarioStore } from './PortScenarioStore';
import { PortStore } from './PortStore';
import { PortSustainabilityStore } from './PortSustainabilityStore';
import { PSAppStateStore } from './PSAppStateStore';
import { PSDataPortalStore } from './PSDataPortalStore';
import { SarCandidateStore } from './SarCandidateStore';
import { SarDriftStore } from './SarDriftStore';
import { SarStateStore } from './SarStateStore';
import { ThemeStore } from './ThemeStore';
import { TransitStore } from './TransitStore';
import { VesselScenarioStore } from './VesselScenarioStore';
import { YardSafeStore } from './YardSafeStore';

export class RootStore {
  // Currently used by MA
  vesselScenarioStore: VesselScenarioStore;
  // Currently used by CC
  portScenarioStore: PortScenarioStore;

  configStore: ConfigStore;

  portStore: PortStore;

  appStateStore: AppStateStore;

  yardSafeStore: YardSafeStore;

  transitStore: TransitStore;

  themeStore: ThemeStore;

  sarCandidateStore: SarCandidateStore;

  sarDriftStore: SarDriftStore;

  sarStateStore: SarStateStore;

  psAppStateStore: PSAppStateStore;

  portSustainabilityStore: PortSustainabilityStore;

  dataPortalStore: PSDataPortalStore;

  constructor() {
    this.vesselScenarioStore = new VesselScenarioStore(this);
    this.portScenarioStore = new PortScenarioStore(this);
    this.configStore = new ConfigStore(this);
    this.portStore = new PortStore(this);
    this.yardSafeStore = new YardSafeStore(this);
    this.transitStore = new TransitStore(this);
    this.appStateStore = new AppStateStore(this);
    this.themeStore = new ThemeStore(this);
    this.sarCandidateStore = new SarCandidateStore(this);
    this.sarDriftStore = new SarDriftStore(this);
    this.sarStateStore = new SarStateStore(this);
    this.psAppStateStore = new PSAppStateStore(this);
    this.portSustainabilityStore = new PortSustainabilityStore(this);
    this.dataPortalStore = new PSDataPortalStore(this);
  }
}
