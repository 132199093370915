/*
 * // TODO: this is a blatent copy from FrontEnd.SharedLibrary due to process.env not being loaded.
 *
 * and will be removed accordingly when these packages are migrated across to the new Nx environment.
 * I have renamed (spelled it the US way) to differentiate from
 * FrontEnd.SharedLibrary/src/__utility/buildTokenisedUrl.ts
 */

/**
 * buildTokenisedUrl will replace existing words dynamically to create a valid link.
 * @param url string with [env], [apiUrl] and so on
 * @param scenario
 * @param customerCode current client `user.metadata.clientId`
 * @param accessToken
 * @returns return a string/link
 */

const buildTokenizedUrl = (url: string, scenarios: any[], customerCode: string, accessToken: string) => {
  let env = '';
  if (process.env.REACT_APP_SYS_ENVIRONMENT === 'Dev') {
    env = '-dev';
  } else if (process.env.REACT_APP_SYS_ENVIRONMENT === 'QA') {
    env = '-qa';
  }
  url = url.replace('[env]', env);
  url = url.replace('[apiUrl]', process.env.REACT_APP_API_ENDPOINT_URL);
  url = url.replace('[apiBlobUrl]', process.env.REACT_APP_API_BLOB_URL);
  url = url.replaceAll('[clientId]', customerCode);
  if (scenarios.length) {
    url = url.replace('[scenarioId]', scenarios[0].fullName);
    if (scenarios[0].data?.vessel) {
      url = url.replace('[vesselName]', scenarios[0].data.vessel.vesselName);
    }
    scenarios.forEach((scenario, index) => (url = url.replace(`[scenarioId${index + 1}]`, scenario.fullName)));
  }
  url = url.replace('[token]', accessToken);
  url = url.replace('[cacheBust]', Math.round(new Date().getTime() / 1000).toString());
  return url;
};

export default buildTokenizedUrl;
