import { Vessel } from '../../interfaces/VesselScenario';

const containerWindageAreas = (vessel: Vessel, draft: number) => {
  var warnings = '';

  // Front Area:
  var superSFrontCovered = 150 + 0.8 * vessel.beam * vessel.summerDraft;
  var superSFrontUncovered = 50 + 0.3 * vessel.beam * vessel.summerDraft;

  var loadingPercent = (vessel.numberOfContainerTiers / vessel.capacityTiersAboveDeck) * 100;

  var superFrontArea = superSFrontUncovered + (superSFrontCovered * (100 - loadingPercent)) / 100;

  // Lateral:
  var superSLatArea = 150 + 0.085 * vessel.loa * vessel.summerDraft;

  var collisionBulkheadFore = 10 + 0.055 * vessel.loa;
  var deckEquipmentHold = 0.05 * vessel.loa;
  var forecastleLateralArea = 0.49 * (collisionBulkheadFore + deckEquipmentHold) * vessel.summerDraft;

  // Total Area:
  var deckLevel = vessel.mouldedDepth - draft;
  var cargoHeight = vessel.numberOfContainerTiers * vessel.containerHeight + vessel.hatchHeightAboveMainDeck;

  var longitudinalWindageArea =
    vessel.lpp * deckLevel + vessel.lpp * cargoHeight + superSLatArea + forecastleLateralArea;
  var transversalWindageArea = vessel.beam * deckLevel + vessel.beam * cargoHeight + superFrontArea;

  return {
    longitudinal: longitudinalWindageArea,
    transversal: transversalWindageArea,
    warnings,
  };
};

const bulkCarrierWindageAreas = (vessel: Vessel, draft: number) => {
  var bulkLateralSuperstructureArea = 125 + 0.063 * vessel.loa * vessel.summerDraft;
  var bulkForecastleLateralArea = 0.08 * vessel.beam * vessel.mouldedDepth;
  var bulkAppendages = ((vessel.loa - vessel.mouldedDepth) / 33) * 24 * 2;

  var superSLatArea = bulkLateralSuperstructureArea + bulkForecastleLateralArea + bulkAppendages;

  var superFrontArea = 125 + 0.41 * vessel.beam * vessel.summerDraft;

  var longitudinalWindageArea = vessel.lpp * (vessel.mouldedDepth - draft) + superSLatArea;
  var transversalWindageArea = vessel.beam * (vessel.mouldedDepth - draft) + superFrontArea;

  return {
    longitudinal: longitudinalWindageArea,
    transversal: transversalWindageArea,
  };
};

const tankerWindageAreas = (vessel: Vessel, draft: number) => {
  var superSLatArea = 225 + 0.06 * vessel.loa * vessel.summerDraft;
  var tankerForecastleLateralArea = 0.02 * vessel.loa * vessel.summerDraft;

  var superFrontArea = 100 + 0.42 * vessel.beam * vessel.summerDraft;
  var longitudinalWindageArea =
    vessel.lpp * (vessel.mouldedDepth - draft) + superSLatArea + tankerForecastleLateralArea;
  var transversalWindageArea = vessel.beam * (vessel.mouldedDepth - draft) + superFrontArea;

  return {
    longitudinal: longitudinalWindageArea,
    transversal: transversalWindageArea,
  };
};

const gasCarrierWindageAreas = (vessel: Vessel, draft: number) => {
  var superSLatArea = 800 + 0.62 * vessel.loa * vessel.summerDraft;


  var longitudinalWindageArea =
    vessel.lpp * (vessel.mouldedDepth - draft) + superSLatArea;

  var KMt = 30 + 0.113 * vessel.loa;
  var transversalWindageArea = (KMt - draft) * (vessel.beam * 0.7);

  return {
    longitudinal: longitudinalWindageArea,
    transversal: transversalWindageArea,
  };
};

const roRoWindageAreas = (vessel: Vessel, draft: number) => {
  if (vessel.loa < 160 || vessel.loa > 270) {
    var frontAreaCoeff = 0.697137;
    var lateralAreaCoeff = 0.497932;
    var refDraft = 0.7959 * vessel.summerDraft;
    var shipHeight = vessel.mouldedDepth + vessel.bridgeHeight;

    var draftDifference = draft - refDraft;
    var frontWindageArea = frontAreaCoeff * vessel.beam * (shipHeight - refDraft);
    var lateralWindageArea = lateralAreaCoeff * vessel.lpp * (shipHeight - refDraft);

    var longitudinalWindageArea = lateralWindageArea - draftDifference * 0.5 * (vessel.loa + vessel.lpp);
    var transversalWindageArea = frontWindageArea - draftDifference * vessel.beam;

    return {
      longitudinal: longitudinalWindageArea,
      transversal: transversalWindageArea,
    };
  } else {
    frontAreaCoeff = 6.6235e-5 * vessel.loa ** 2 - 0.0302 * vessel.loa + 4.0183;
    lateralAreaCoeff = -3.3601e-5 * vessel.loa ** 2 + 0.0143 * vessel.loa - 0.8134;
    refDraft = vessel.summerDraft;
    var shipHeightCoeff = 7.9864e-7 * vessel.loa ** 2 - 0.0013 * vessel.loa + 0.4995;
    shipHeight = shipHeightCoeff * vessel.lpp;
    draftDifference = draft - refDraft;
    frontWindageArea = frontAreaCoeff * vessel.beam * (shipHeight - refDraft);
    lateralWindageArea = lateralAreaCoeff * vessel.lpp * (shipHeight - refDraft);

    longitudinalWindageArea = lateralWindageArea - draftDifference * 0.5 * (vessel.loa + vessel.lpp);
    transversalWindageArea = frontWindageArea - draftDifference * vessel.beam;

    return {
      longitudinal: longitudinalWindageArea,
      transversal: transversalWindageArea,
    };
  }
};

const cruiseLinerWindageAreas = (vessel: Vessel, draft: number) => {
  var frontAreaCoeff = 0.901300236;
  var lateralAreaCoeff = 0.643557522;
  var refDraft = 0.9632 * vessel.summerDraft;
  var shipHeight = 0.245923995 * vessel.lpp;

  var draftDifference = draft - refDraft;
  var frontWindageArea = frontAreaCoeff * vessel.beam * (shipHeight - refDraft);
  var lateralWindageArea = lateralAreaCoeff * vessel.lpp * (shipHeight - refDraft);

  var longitudinalWindageArea = lateralWindageArea - draftDifference * 0.5 * (vessel.loa + vessel.lpp);
  var transversalWindageArea = frontWindageArea - draftDifference * vessel.beam;

  return {
    longitudinal: longitudinalWindageArea,
    transversal: transversalWindageArea,
  };
};

// functions
export {
  bulkCarrierWindageAreas,
  containerWindageAreas,
  cruiseLinerWindageAreas, gasCarrierWindageAreas, roRoWindageAreas,
  tankerWindageAreas
};

