import { BitmapLayer } from '@deck.gl/layers';

const getImageLayer = (portSustainabilityStore, pickable) =>
  new BitmapLayer({
    id: `bitmap-image-layer_${Math.random()}`,
    bounds: portSustainabilityStore.boundingBox,
    pickable,
    image: `data:image/png;base64,${portSustainabilityStore.imageLayer?.frames[0].imageString}`,
  });

export default getImageLayer;
