import jp from 'jsonpath';
import { cloneDeep, extendWith, isUndefined } from 'lodash';
import { action, makeObservable, override } from 'mobx';
import { IntlShape } from 'react-intl';
import { DataConfig } from 'src/interfaces/Config';
import { PortData } from 'src/interfaces/PortData';
import { ListFieldValue } from '../components/common/InputField/types';
import { displayToField } from '../helpers/conversions';
import getConfigSection from '../helpers/getConfigSection';
import getFieldConfig from '../helpers/getFieldConfig';
import setDefaultValues from '../helpers/setDefaultValues';
import { PortScenarioContainer } from '../interfaces/PortScenario';
import PortScenarioBlueprint from '../models/blueprints/PortScenarioBlueprint';
import { RootStore } from './RootStore';
import { ScenarioStore } from './ScenarioStore';
import { PortScenarioValidity } from './types/portTypes';

export class PortScenarioStore extends ScenarioStore {
  initialScenarioDataState = {
    id: '',
    dateTime: '',
    version: '',
    lastJobStatus: '',
    data: PortScenarioBlueprint,
  } as PortScenarioContainer;

  initialValidityState = {
    valid: true,
  } as PortScenarioValidity;

  originalScenarioData: PortScenarioContainer;
  scenarioData = this.initialScenarioDataState;
  validity = this.initialValidityState;

  constructor(allStores: RootStore) {
    super(allStores);
    this.originalScenarioData = null;

    makeObservable(this, {
      scenarioData: override,
      originalScenarioData: override,
      validity: override,
      extendWithBlueprint: action.bound,
      setScenarioData: action.bound,
      revertScenario: action.bound,
      setScenarioDataField: action.bound,
      resetScenarioDataToDefaults: action.bound,
      handleValueChange: action.bound,
      handleValuesChange: action.bound,
    });
  }

  extendWithBlueprint(scenarioContext: PortScenarioContainer) {
    // Merge mapping; As if we add new properties to any of these objects and edit an old scenario,
    // If it is missing the property, the UI will throw an error about controlled components and potentially
    // overlap placeholder labels on textfields when the value is newly created in edit mode.
    const newScenarioData = extendWith(scenarioContext.data, PortScenarioBlueprint, (objValue, srcValue) => {
      let useBlueprintData = false;

      // If property doesn't exist in working scenarioData (take from blueprint)

      if (isUndefined(objValue)) {
        useBlueprintData = true;
      }

      return useBlueprintData ? srcValue : objValue;
    });

    return newScenarioData;
  }

  setScenarioData(scenarioContext: PortScenarioContainer, freshOpen?: boolean) {
    const diagnosticsConfig = getConfigSection(this.allStores.configStore.appConfig, 'diagnostics');
    const enhancedData = this.extendWithBlueprint(scenarioContext);

    scenarioContext.data = enhancedData;
    this.scenarioData = scenarioContext;

    if (freshOpen) {
      this.originalScenarioData = cloneDeep(this.scenarioData);
    }

    this.allStores.appStateStore.validatePortScenario(this.scenarioData, this.validity, diagnosticsConfig);
  }

  revertScenario() {
    const diagnosticsConfig = getConfigSection(this.allStores.configStore.appConfig, 'diagnostics');

    this.scenarioData = this.originalScenarioData;

    this.allStores.appStateStore.validatePortScenario(this.scenarioData, this.validity, diagnosticsConfig);
  }

  setScenarioDataField(dataConfig: any, fieldName: string, fieldValue: any, inServerUnit?: boolean) {
    const payload: any = { fieldName };
    const fieldConfig = getFieldConfig(fieldName, dataConfig);
    const diagnosticsConfig = getConfigSection(this.allStores.configStore.appConfig, 'diagnostics');

    if (fieldConfig) {
      // unit-conversion
      payload.dataValue = inServerUnit === false ? displayToField(fieldValue, fieldConfig) : fieldValue;
    } else {
      // no field-config, so unit-conversion is out of the question
      payload.dataValue = fieldValue;
    }

    // Use jsonpath to apply in a deep path approach
    jp.apply(this.scenarioData.data, `$.${fieldName}`, () => fieldValue);

    this.allStores.appStateStore.validatePortScenario(this.scenarioData, this.validity, diagnosticsConfig);
  }

  resetScenarioDataToDefaults(intl: IntlShape, portData: PortData, dataConfig: DataConfig[]) {
    const newContext = this.initialScenarioDataState;
    // Setup all discharge stations into dto
    const dischargeStationConfig = getFieldConfig('environmentalConditions.tributaries.dischargeStations', dataConfig);
    newContext.data.portDatumOffset = portData.mslPortDatum;
    newContext.data.environmentalConditions.tributaries.dischargeStations = dischargeStationConfig.options as string[];

    setDefaultValues(newContext.data, dataConfig, portData, intl);
    this.validity = cloneDeep(this.initialValidityState);
    this.setScenarioData(newContext, true);
  }

  handleValueChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> | null,
    dataConfig: any,
    newValue?: any,
    fieldName?: string,
  ) {
    const path = fieldName || (e && e.currentTarget.id);

    if (path) {
      this.setScenarioDataField(dataConfig, path, newValue);
    } else {
      throw new Error(
        'Missing `path` for `setScenarioDataField` (path can be obtained through `fieldName` or `e` argument)',
      );
    }
  }

  handleValuesChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> | null,
    dataConfig: any,
    listFieldValue: ListFieldValue[],
  ) {
    listFieldValue.forEach((itemFieldValue: ListFieldValue) => {
      this.handleValueChange(e, dataConfig, itemFieldValue.newValue, itemFieldValue.fieldName);
    });
  }
}
