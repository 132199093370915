/**
 * Removes duplicates out of an array
 *
 * @param {Array}
 * @return {Array}
 */
export const arrayRemoveDuplicates = (array) => [...new Set(array)];

/**
 * Removes duplicate objects out of an array of objects
 *
 * @param {Array} array
 * @param {string} prop
 * @param {boolean} amongAttributes (default=false) Is it a property among the `attributes` property (JSONAPI)
 * @return {Array}
 */
export const arrayRemoveDuplicateObjects = (array, prop, amongAttributes = false) =>
  array.filter((obj, pos, arr) =>
    amongAttributes
      ? arr.map((mapObj) => mapObj.attributes[prop]).indexOf(obj.attributes[prop]) === pos
      : arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos,
  );

/**
 * Retrieves first item of an array
 *
 * @param {Array}
 * @return {*}
 */
export const getFirstArrayItem = (array) => array[0];

/**
 * Retrieves last item of an array
 *
 * @param {Array}
 * @return {*}
 */
export const getLastArrayItem = (array) => array[array.length - 1];

/**
 * Filter an array of objects by a property-value
 *
 * @param {Array} array
 * @param {string} propVal
 * @param {string} propKey
 * @return {*}
 */
export const filterArrayByProp = (array, propVal, propKey) => array.filter((item) => item[propKey] === propVal);

/**
 * Tells whether given variable is an array
 *
 * @param {*}
 * @return {boolean}
 */
export const isArray = (value) => Object.prototype.toString.call(value) === '[object Array]';
