/** useScenarioEditor
 *
 * This custom hook exposes a simple API for handling scenario data and its internal
 * values held in the shared state object
 *
 */
import { useContext } from "react";
import { DataConfig } from "../../__models/DataConfig";
import ScenarioEditorContext from "./ScenarioEditorContext";
import { initialScenarioEditorState, UseScenarioEditor } from "./types";

/**
 * Global data React Hook for getting and setting app-wide data.
 */
const useScenarioEditor = (): UseScenarioEditor => {
  // const intl = useIntl();
  const { scenarioEditor, setScenarioEditor } = useContext(
    ScenarioEditorContext
  );

  scenarioEditor.addError = (errorData) => {
    setScenarioEditor((data) => ({
      ...data,
      error: {
        hasError: true,
        errors: errorData.errors.concat(errorData),
      },
    }));
  };

  scenarioEditor.setError = (errorDataList: any) => {
    setScenarioEditor((data) => ({
      ...data,
      error: {
        hasError: errorDataList.length > 0,
        errors: errorDataList,
      },
    }));
  };

  scenarioEditor.resetErrors = () => {
    scenarioEditor.setSubmitAttempted(false);

    setScenarioEditor((data) => ({
      ...data,
      error: { ...initialScenarioEditorState.error },
    }));
  };

  scenarioEditor.addFieldToTab = (fieldName: string, tabId: string) => {
    const tabBluePrint = {
      id: tabId,
      fields: {},
    };
    const fieldBluePrint = {
      id: fieldName,
      // hasError: false,
    };
    const tabs = scenarioEditor.tabs || {};
    const tab = tabs[tabId] || tabBluePrint;

    tab.fields[fieldName] = fieldBluePrint;
    tabs[tabId] = tab;

    setScenarioEditor((data) => ({
      ...data,
      tabs,
    }));
  };

  scenarioEditor.setActiveTab = (tabName: string) => {
    setScenarioEditor((data) => ({
      ...data,
      activeTab: tabName,
    }));
  };

  scenarioEditor.setSubmitAttempted = (submitAttempted = true) => {
    setScenarioEditor((data) => ({
      ...data,
      submitAttempted,
    }));
  };

  scenarioEditor.setJumpToNextError = (jumpToNextError: boolean) => {
    setScenarioEditor((data) => ({
      ...data,
      jumpToNextError,
    }));
  };

  scenarioEditor.getValidationErrors = (
    dataConfigSet: DataConfig[],
    showErrors = false
  ) => {
    let validationResults = [];

    dataConfigSet.forEach((fieldConfig: DataConfig) => {
      // Activate any relevant helper error messages on each field
      if (showErrors && fieldConfig.showErrors) {
        fieldConfig.showErrors();
      }

      if (fieldConfig.validationStatus != null) {
        validationResults = [
          ...validationResults,
          fieldConfig.validationStatus,
        ];
      }
    });

    const validationErrors = validationResults.filter((v) => !v.success);

    return validationErrors;
  };

  scenarioEditor.displayErrorsIfNeeded = (
    dataConfigSet: DataConfig[],
    attemptSubmit?: boolean,
    showErrors = true
  ): any[] => {
    const validationErrors = scenarioEditor.getValidationErrors(
      dataConfigSet,
      showErrors && (attemptSubmit || scenarioEditor.submitAttempted)
    );

    let jumpToNextError = false;

    if (attemptSubmit) {
      if (validationErrors.length > 0) {
        console.log("Validation errors: ", validationErrors);
      } else {
        console.log("No validation errors.");
      }

      scenarioEditor.setSubmitAttempted();
      jumpToNextError = true;
      scenarioEditor.setJumpToNextError(jumpToNextError);
    } else if (
      scenarioEditor.setSubmitAttempted &&
      scenarioEditor.jumpToNextError
    ) {
      jumpToNextError = false;
      scenarioEditor.setJumpToNextError(jumpToNextError);
    }

    // Must *only* set error if the error length has changed between validations, otherwise we get a render loop.
    // Plus it's more efficient this way.
    if (
      (scenarioEditor.error.errors.length !== validationErrors.length &&
        attemptSubmit !== scenarioEditor.submitAttempted) ||
      (scenarioEditor.jumpToNextError !== undefined &&
        jumpToNextError !== scenarioEditor.jumpToNextError)
    ) {
      scenarioEditor.setError(validationErrors);
    }

    return validationErrors;
  };

  return { scenarioEditor };
};

export default useScenarioEditor;
