import {
  Box,
  IconButton,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import { AddCircle, RemoveCircle } from '@material-ui/icons';
import { endsWith } from 'lodash';
import { runInAction } from 'mobx';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { isNumeric } from '../../__utility/common';
import { fieldLabel } from '../../__utility/fieldIntl';
import { InfiniteTableProps, InfiniteTableRow } from './types';

const styles = (theme: Theme) => ({
  box: {
    'marginTop': theme.spacing(4),
    'marginBottom': theme.spacing(4),
    'display': 'flex',
    'flex-direction': 'column',
  },
  titleLabel: {
    marginBottom: theme.spacing(1),
  },
  tableCell: {
    border: 'none',
    paddingLeft: 0,
  },
});

// const styles = { ...localStyles, ...defaultStyles };
// const useStyles = makeStyles(styles, {
//   withTheme: true,
// } as WithStylesOptions<{}>);

const hasData = (item: InfiniteTableRow) => {
  return Object.values(item).some((item) => item !== '');
};

interface Props extends InfiniteTableProps, WithStyles<typeof styles> {}

const InfiniteTable = observer(({ fieldConfig, fieldName, columns, data, onChange, noLabel, classes }: Props) => {
  const store = useLocalStore(
    () => ({
      items: data,
      get validItems() {
        return store.items.filter((f) => hasData(f));
      },
      addItem() {
        const lastItem = store.items[store.items.length - 1];
        let canAdd = false;

        if (!lastItem || hasData(lastItem)) {
          canAdd = true;
        }

        if (canAdd) {
          const newItem = {};

          columns.forEach((c) => {
            newItem[c.id] = '';
          });

          store.items.push(newItem);
          onChange(store.validItems);
        }
      },
      deleteItem(index: number) {
        store.items.splice(index, 1);
        onChange(store.validItems);
      },
    }),
    data,
  );

  const intl = useIntl();
  const label = fieldLabel(intl, fieldConfig, fieldName);

  useEffect(() => {
    store.addItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderRow = (data: any, rowIndex: number, renderNewButton: boolean, renderDeleteButton: boolean) => (
    <TableRow key={rowIndex}>
      {Object.keys(data).map((key, index) => {
        const column = columns[index];
        const item = data[column.id];

        return (
          <TableCell key={index} className={classes.tableCell}>
            <TextField
              id={column.id}
              value={item}
              onChange={(e) => {
                runInAction(() => {
                  store.items[rowIndex][column.id] =
                    isNumeric(e.target.value) && !endsWith(e.target.value, '.')
                      ? parseFloat(e.target.value)
                      : e.target.value;

                  onChange(store.validItems);
                });
              }}
              onBlur={store.addItem}
              type={column.dataType || 'text'}
            />
          </TableCell>
        );
      })}
      <TableCell className={classes.tableCell}>
        {renderDeleteButton && (
          <IconButton onClick={() => store.deleteItem(rowIndex)} size="small">
            <RemoveCircle color="error" />
          </IconButton>
        )}
        {renderNewButton && (
          <IconButton onClick={store.addItem} size="small">
            <AddCircle color="primary" />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );

  return (
    <Box className={classes.box}>
      <InputLabel className={classes.titleLabel}>
        {!noLabel && (fieldConfig.unit ? `${label} [${fieldConfig.unit}]` : label)}
      </InputLabel>
      <Table size="small">
        <TableHead>
          <TableRow key="header">
            {columns.map((column) => (
              <TableCell key={column.id} className={classes.tableCell}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {store.items.map((item, index) =>
            renderRow(item, index, store.items.length === index + 1, store.items.length !== index + 1),
          )}
        </TableBody>
      </Table>
      {/* {JSON.stringify(store.items)} */}
    </Box>
  );
});

export default withStyles(styles, { withTheme: true })(InfiniteTable);
