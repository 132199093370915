import { makeObservable, observable } from 'mobx';
import { ScenarioContainer } from '../interfaces/ScenarioContainer';
import { RootStore } from './RootStore';
import { ScenarioValidity } from './types';

export abstract class ScenarioStore {
  initialScenarioDataState = {
    id: '',
    dateTime: '',
    version: '',
    lastJobStatus: '',
    data: {},
  } as ScenarioContainer;

  initialValidityState = {
    valid: true,
  } as ScenarioValidity;

  scenarioData = this.initialScenarioDataState;
  originalScenarioData: ScenarioContainer;
  validity = this.initialValidityState;

  constructor(protected allStores: RootStore) {
    makeObservable(this, {
      scenarioData: observable,
      originalScenarioData: observable,
      validity: observable,
    });
  }
}
