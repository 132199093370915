import { FormControl, ListItemIcon, MenuItem, Select, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import React, { useState } from 'react';

interface Items {
  value: number;
  label: string;
  extendedLabel?: string;
  style?: React.CSSProperties;
  editable?: boolean;
}

function MaterialTypeSelect({
  id,
  classes,
  rowValue,
  items,
  onClick = null,
  onChange = (e: React.ChangeEvent<{ value: unknown }>) => {},
  editable = false,
  onEditItem = null,
  disabled = null,
  maxWidth = null,
  style = null,
}: {
  id: string;
  classes: any;
  rowValue: number | string;
  items: Items[];
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onChange?: (e: React.ChangeEvent<{ value: unknown }>) => void; // Upgrade: value: number - Gives type error from old mui
  editable?: boolean;
  onEditItem?: (value: number) => void;
  disabled?: boolean;
  maxWidth?: number;
  style?: React.CSSProperties;
}) {
  style = {
    ...style,
    width: style?.width ?? '100%',
    maxWidth: style?.maxWidth ?? '100%',
  };

  const [showEditable, setShowEditable] = useState(false);

  return (
    <FormControl className={classes.formControl} style={{ ...style }}>
      <Select
        value={rowValue}
        onClick={onClick}
        onChange={onChange}
        inputProps={{
          id,
          name: id,
        }}
        onOpen={() => {
          setShowEditable(true);
        }}
        onClose={() => {
          setShowEditable(false);
        }}
        className={classes.customSelect}
        disabled={disabled}
      >
        {items.map((item, index) => (
          <MenuItem
            value={item.value}
            key={item.value + index + item.label}
            style={{ display: 'flex', height: 54, paddingRight: 10 }}
          >
            <Typography variant="inherit" noWrap style={{ ...item.style, flex: 1 }}>
              {item.extendedLabel ? item.extendedLabel : item.label}
            </Typography>
            {editable && (typeof item.editable === 'undefined' || item.editable) && showEditable && (
              <ListItemIcon style={{ marginLeft: 10, minWidth: 'auto' }}>
                <IconButton onClick={() => onEditItem(item.value)}>
                  <EditIcon />
                </IconButton>
              </ListItemIcon>
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default MaterialTypeSelect;
