export const SCENARIO_DATA_VERSION = 1;

export const HIGH_ZOOM = 12;
export const MED_HIGH_ZOOM = 16;
export const MEDIUM_ZOOM = 18;
export const LOW_ZOOM = 19;

export const BLACK_RGB: [number, number, number] = [0, 0, 0];
export const RED_RGB: [number, number, number] = [255, 0, 0];
export const WHITE_RGB: [number, number, number] = [255, 255, 255];
export const FENDER_BLUE_FADED: [number, number, number, number] = [13, 57, 88, 70];
export const FENDER_BORDER: [number, number, number, number] = [55, 71, 79, 70];
export const BOLLARD_BLUE: [number, number, number] = [55, 71, 79];
export const BOLLARD_BLUE_FADED: [number, number, number, number] = [207, 216, 220, 190];
export const DISABLED_RED: [number, number, number] = [202, 48, 58];
export const DISABLED_RED_FADED: [number, number, number, number] = [202, 48, 58, 190];
export const WINCH_GREEN: [number, number, number] = [165, 214, 167];

export const DISTANCE_RULER_MAX_METER_MARK = 100;
export const SLIDER_CONTAINER_BUFFER = 2;
export const MA_ROUND_DECIMALS = 1;

export const CONTAINER_LENGTH = 12.192;
export const CONTAINER_HEIGHT = 2.591;
export const CONTAINER_WIDTH = 2.438;

export const MOORING_ARRANGEMENT_DROPDOWN_CUSTOMISED = 'Customised';

export const CHARACTER_SET = [
  ' ',
  '!',
  '"',
  '#',
  '$',
  '%',
  '&',
  '(',
  ')',
  '*',
  '+',
  '-',
  '.',
  '/',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  ':',
  ';',
  '<',
  '=',
  '>',
  '?',
  '@',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  'ß',
  'Ä',
  'Ö',
  'Ü',
  '[',
  ']',
  '^',
  '_',
  '`',
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
  'ü',
  'ä',
  'ö',
  '{',
  '|',
  '}',
  '~',
];
