/**
 * buildTokenisedUrl will replace existing words dynamically to create a valid link.
 * @param url string with [env], [apiUrl] and so on
 * @param scenario
 * @param customerCode current client `user.metadata.clientId`
 * @param accessToken
 * @returns return a string/link
 */

const buildTokenisedUrl = (
  url: string,
  scenarios: any[],
  customerCode: string,
  accessToken: string
) => {
  let env = "";
  if (process.env.REACT_APP_SYS_ENVIRONMENT === "Dev") {
    env = "-dev";
  } else if (process.env.REACT_APP_SYS_ENVIRONMENT === "QA") {
    env = "-qa";
  }
  url = url.replace("[env]", env);
  url = url.replace("[apiUrl]", process.env.REACT_APP_API_ENDPOINT_URL);
  url = url.replace("[apiBlobUrl]", process.env.REACT_APP_API_BLOB_URL);
  url = url.replace("[scenarioId]", scenarios[0].fullName);
  url = url.replaceAll("[clientId]", customerCode);
  if (scenarios[0].data.vessel) {
    url = url.replace("[vesselName]", scenarios[0].data.vessel.vesselName);
  }
  if (scenarios.length) {
    scenarios.forEach(
      (scenario, index) =>
        (url = url.replace(`[scenarioId${index + 1}]`, scenario.fullName))
    );
  }
  url = url.replace("[token]", accessToken);
  url = url.replace(
    "[cacheBust]",
    Math.round(new Date().getTime() / 1000).toString()
  );
  return url;
};

export default buildTokenisedUrl;
