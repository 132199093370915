/* eslint-disable no-restricted-properties */ // this is to avoid Math.pow() es warning asking to use ** (potentiator operator) instead
import { PathStyleExtension } from '@deck.gl/extensions';
import { GeoJsonLayer, TextLayer } from '@deck.gl/layers';
import convert from 'convert-units';
import LatLon from 'geodesy/latlon-spherical';
import { Utm, LatLon as UtmLatLon } from 'geodesy/utm';
import { cloneDeep, isArray, isUndefined, round } from 'lodash';
import { toJS, transaction } from 'mobx';
import { Winch } from 'src/interfaces/FleetManagerVessel';
import { Berth, PortData, ShipLoader } from '../interfaces/PortData';
import {
  CraneStaged,
  Fender,
  FenderProfile,
  Mooring,
  Point2D,
  Point3D,
  Port,
  Vessel,
  VesselScenarioContainer,
} from '../interfaces/VesselScenario';
import { AppStateStore, PortStore, VesselScenarioStore } from '../stores';
import { AllBollard } from '../stores/types';
import { BLACK_RGB, CHARACTER_SET, MEDIUM_ZOOM } from './constants';
import { getFullDeckPlane } from './ma/calculateConnectivity';

interface LatLngCoordinate {
  latitude: number;
  longitude: number;
  id: number;
}

/**
 * This function is used to rotate and convert winches, fairleads and ideal bollard lists of coordinates
 * @param {Berth} berth This is the berth the vessel is moored at
 * @param {Mooring} mooring This is the mooring configuration
 * @param {array} list
 */
const relativeToLatLng = (berth: Berth, mooring: Mooring, list: Array<any>): LatLngCoordinate[] => {
  if (mooring.latitude === null || mooring.longitude == null) return [];
  const headingAngle = getHeadingAngle(mooring, berth);
  const points: Point2D[] = list.map((item: Point2D) => ({
    x: item.x,
    y: item.y,
  }));

  return utmToLatLng(points, mooring.latitude, mooring.longitude, headingAngle).map(
    (point, index) =>
      ({
        id: list[index].id,
        longitude: point[0],
        latitude: point[1],
      } as LatLngCoordinate),
  );
};

/**
 * Converts a list of coordinates in the local ship coordinates system to lat lng
 * @param {Point2D[]} points The list of points to convert
 * @param {number} latitude The latitude of the point
 * @param {number} longitude The longitude of the point
 * @param {string} angle The angle to rotate the points
 */
const utmToLatLng = (points: Point2D[], latitude: number, longitude: number, angle: number) => {
  const referencePoint: UtmLatLon = new UtmLatLon(latitude, longitude);
  const utm: Utm = referencePoint.toUtm();
  const radians = (Math.PI / 180) * (-angle + utm.convergence! + 90);
  const cos = Math.cos(radians);
  const sin = Math.sin(radians);

  return points.map((point: Point2D) => {
    const newX = cos * point.x - sin * point.y + utm.easting;
    const newY = cos * point.y + sin * point.x + utm.northing;
    const newUtm = cloneDeep(utm);

    newUtm.easting = newX;
    newUtm.northing = newY;
    const geo = newUtm.toLatLon();

    return [geo.longitude, geo.latitude];
  });
};

/**
 * Converts a list of coordinates in the local ship coordinates system to lat lng
 * @param {Point2D[]} points The list of points to convert
 * @param {number} latitude The latitude of the point
 * @param {number} longitude The longitude of the point
 * @param {string} angle The angle to rotate the points
 */
const drawPolygon = (points: number[][], latitude: number, longitude: number, angle: number): number[][] => {
  return utmToLatLng(
    points.map((p: number[]) => ({
      x: p[0],
      y: p[1],
    })),
    latitude,
    longitude,
    angle,
  );
};

/**
 * Converts a list of coordinates in lat lng to the local ship coordinates system
 * @param {Point2D[]} points The list of points to convert
 * @param {LatLon} centre The centre of the rotation
 * @param {number} angle The angle to rotate the points
 * @param {boolean} portDock Indicates if the ship is docked port side or starboard side
 */
const latLngToUtm = (points: Point2D[], centre: UtmLatLon, angle: number) => {
  const utm = centre.toUtm();
  const radians = (angle - 90 - utm.convergence!) * (Math.PI / 180.0);
  const cos = Math.cos(radians);
  const sin = Math.sin(radians);

  return points.map((point) => {
    const newUtm = new UtmLatLon(point.y, point.x).toUtm();
    const posX = newUtm.easting - utm.easting;
    const posY = newUtm.northing - utm.northing;
    const x = posX * cos - posY * sin;
    const y = posY * cos + posX * sin;

    return [x, y];
  });
};

const latLngHeightToUtm = (points: Point3D[], centre: UtmLatLon, angle: number) => {
  const utm = centre.toUtm();
  const radians = (angle - 90 - utm.convergence!) * (Math.PI / 180.0);
  const cos = Math.cos(radians);
  const sin = Math.sin(radians);

  return points.map((point) => {
    const newUtm = new UtmLatLon(point.y, point.x).toUtm();
    const posX = newUtm.easting - utm.easting;
    const posY = newUtm.northing - utm.northing;
    const x = posX * cos - posY * sin;
    const y = posY * cos + posX * sin;
    const z = point.z;

    return [x, y, z];
  });
};

/**
 * Calculates distance between two given coordinates
 * @param {number[]} a
 * @param {number[]} b
 * @returns {number} // in meter
 */
const getDistanceCoords = (a: number[], b: number[]): number => {
  const pointA = new LatLon(a[1], a[0]);
  const pointB = new LatLon(b[1], b[0]);

  return pointA.distanceTo(pointB);
};

/**
 * This function zooms to the vessel center point
 * @param {VesselScenarioStore} vesselScenarioStore The vesselScenarioStore
 * @param {PortStore} portStore The portStore
 * @param {object} map
 */
const flyToVessel = (berth: Berth, vesselScenarioStore: VesselScenarioStore) => {
  const { mooring, vessel, port } = vesselScenarioStore.scenarioData.data;

  const headingAngle = getHeadingAngle(mooring, berth);
  const vesselCenterPoint = getVesselCentre(mooring, port, berth, vessel);

  let zoom = MEDIUM_ZOOM;

  if (vessel.loa >= 350) {
    zoom = 16;
  } else if (vessel.loa >= 250) {
    zoom = 16.5;
  } else if (vessel.loa >= 150) {
    zoom = 17;
  }

  return {
    zoom,
    latitude: vesselCenterPoint.latitude,
    longitude: vesselCenterPoint.longitude,
    vesselCenterPoint,
    headingAngle,
  };
};

/**
 * This function zooms to berth provides as argument
 * @param {object} map
 * @param {Berth} berth This is the berth the vessel is moored at
 */

const flyToBerth = (berth: Berth) => {
  const [p1, p2] = getBerthSizeLatLon(berth);
  const middle = p1.midpointTo(p2);
  const berthAngle = getBerthAngle(berth);

  return {
    latitude: middle.latitude,
    longitude: middle.longitude,
    zoom: 17,
    berthAngle,
  };
};

/**
 * This function returns the first and last coordinate of a berth provided as argument
 * NOT IN USE
 * @param {Berth} berth This is the berth the vessel is moored at
 */
const getBerthSizeLatLon = (berth: Berth): [LatLon, LatLon] => {
  const p1 = new LatLon(berth.berthLine[0][1], berth.berthLine[0][0]);
  const p2 = new LatLon(berth.berthLine[berth.berthLine.length - 1][1], berth.berthLine[berth.berthLine.length - 1][0]);

  return [p1, p2];
};

/**
 * This function gets the berth angle
 * @param {Berth} berth This is the berth the vessel is moored at
 */
const getBerthAngle = (berth: Berth, diagnostics?: boolean | null): number => {
  if (!berth) {
    return 0;
  }

  const start = new LatLon(berth.berthLine[0][1], berth.berthLine[0][0]);
  const end = new LatLon(berth.berthLine[1][1], berth.berthLine[1][0]);
  const result = start.initialBearingTo(end);

  if (diagnostics) {
    console.log(`Berth Angle: ${result}`);
  }

  return result;
};

/**
 * This function gets the berth angle to north. In the closest UTM projection zone
 * @param {Berth} berth This is the berth the vessel is moored at
 */
const getBerthAngleTN = (berth: Berth, diagnostics?: boolean | null): number => {
  if (!berth) {
    return 0;
  }

  const averageLongitude =
    berth.berthLine.reduce((accumulated, coordinate) => accumulated + coordinate[0], 0) / berth.berthLine.length;
  const averageLatitude =
    berth.berthLine.reduce((accumulated, coordinate) => accumulated + coordinate[1], 0) / berth.berthLine.length;
  const averageZone = new UtmLatLon(averageLatitude, averageLongitude).toUtm().zone;
  const start = new UtmLatLon(berth.berthLine[0][1], berth.berthLine[0][0]).toUtm(averageZone);
  const end = new UtmLatLon(berth.berthLine[1][1], berth.berthLine[1][0]).toUtm(averageZone);
  const dY = end.northing - start.northing;
  const dX = end.easting - start.easting;
  const result = (90 - Math.atan2(dY, dX) * (180 / Math.PI) + 360) % 360;

  if (diagnostics) {
    console.log(`Berth Angle: ${result}`);
  }

  return result;
};

/**
 * This function gets the heading angle of the ship
 * @param {Mooring} mooring This is the mooring configuration
 * @param {Berth} berth This is the berth the vessel is moored at
 */
const getHeadingAngle = (mooring: Mooring, berth: Berth, diagnostics?: boolean | null): number => {
  const berthAngle = getBerthAngle(berth);
  const result = ((berth.berthIsToTheRight ? 0 : 180) + (mooring.portDock ? berthAngle + 180 : berthAngle)) % 360;

  if (diagnostics) {
    console.log(`Heading Angle: ${result}`);
  }

  return result;
};

/**
 * This function gets the heading angle of the ship
 * @param {Mooring} mooring This is the mooring configuration
 * @param {Berth} berth This is the berth the vessel is moored at
 */
const getHeadingAngleTN = (mooring: Mooring, berth: Berth, diagnostics?: boolean | null): number => {
  const berthAngle = getBerthAngleTN(berth);
  const result = ((berth.berthIsToTheRight ? 0 : 180) + (mooring.portDock ? berthAngle + 180 : berthAngle)) % 360;

  if (diagnostics) {
    console.log(`Heading Angle: ${result}`);
  }

  return result;
};

/**
 * This function gets angle perpendicular to the berth towards the ship
 * @param {Berth} berth This is the berth the vessel is moored at
 */
const getBerthToShipAngle = (berth: Berth, diagnostics?: boolean | null) => {
  const berthAngle = getBerthAngle(berth);
  const { berthIsToTheRight } = berth;
  const result = (berthAngle + (berthIsToTheRight ? -90 : 90)) % 360;

  if (diagnostics) {
    console.log(`Berth To Ship Angle: ${result}`);
  }

  return result;
};

/**
 * This function gets angle perpendicular to the berth towards the ship
 * @param {Berth} berth This is the berth the vessel is moored at
 */
const getBerthToShipAngleTN = (berth: Berth, diagnostics?: boolean | null) => {
  const berthAngle = getBerthAngleTN(berth);
  const { berthIsToTheRight } = berth;
  const result = (berthAngle + (berthIsToTheRight ? -90 : 90)) % 360;

  if (diagnostics) {
    console.log(`Berth To Ship Angle: ${result}`);
  }

  return result;
};

/**
 * This function gets angle perpendicular to the ship towards the berth
 * @param {Berth} berth This is the berth the vessel is moored at
 */
const getShipToBerthAngle = (berth: Berth, diagnostics?: boolean | null) => {
  const berthAngle = getBerthAngle(berth);
  const result = (berthAngle + (berth?.berthIsToTheRight ? -90 : 90) + 180) % 360;

  if (diagnostics) {
    console.log(`Ship To Berth Angle: ${result}`);
  }

  return result;
};

export type LabelOrientation = 'top' | 'right' | 'bottom' | 'left';
/**
 * This function gets the orientation for labels from ship angle away from the berth
 * @param berth This is the berth the vessel is moored at
 * @param direction Optional indication of the direction in relation to away from the berth. Options are opposite, left and right
 */
const getLabelOrientationFromBerthAngle = (berth: Berth, direction?: string | null): LabelOrientation => {
  let shipToBerthAngle = getShipToBerthAngle(berth);
  let result = null;

  switch (direction) {
    case 'opposite':
      shipToBerthAngle += 180;
      break;
    case 'left':
      shipToBerthAngle -= 90;
      break;
    case 'right':
      shipToBerthAngle += 90;
      break;
    default:
      break;
  }

  shipToBerthAngle %= 360;

  if (shipToBerthAngle >= 315 || shipToBerthAngle < 45) {
    result = 'top';
  } else if (shipToBerthAngle >= 45 && shipToBerthAngle < 135) {
    result = 'right';
  } else if (shipToBerthAngle >= 135 && shipToBerthAngle < 225) {
    result = 'bottom';
  } else if (shipToBerthAngle >= 225 && shipToBerthAngle < 315) {
    result = 'left';
  }

  return result;
};

interface FairleadMooringLinePlot {
  coordinate: number[][];
  properties: { name: string };
}

interface MooringLinePlot {
  coordinate: number[][];
}

/**
 * This return the fairlead to bollard lines and the vessel's fairleads
 * @param {PortStore} portStore The portStore
 * @param {Berth} berth This is the berth the vessel is moored at
 * @param {Vessel} vessel The vessel
 * @param {Mooring} mooring This is the mooring configuration
 */
const getFairleadToBollardLines = (
  appStateStore: AppStateStore,
  scenarioDataContainer: VesselScenarioContainer,
  portStore: PortStore,
  berth: Berth,
  vessel: Vessel,
  mooring: Mooring,
  bollards: Partial<AllBollard>[],
): FairleadMooringLinePlot[] => {
  let fairleadLines: FairleadMooringLinePlot[] = [];
  let fairleadToBollard: FairleadMooringLinePlot[] = [];
  const fairleadsCoords = relativeToLatLng(berth, mooring, vessel.fairleads);

  /*
   * // TODO: properly validate mooring data at a higher level)
   *
   * the extra conditions in the if() stmt is a quick fix to protect the app from crashing
   * for garbage lat/long values.
   */
  if (mooring && mooring.latitude !== null && mooring.longitude !== null) {
    // Vessel Polygons
    const deckPlaneFull = getFullDeckPlane(vessel.deckPlane, vessel.beam);

    const deckPlane: { x: number; y: number }[] = deckPlaneFull.map((point: { x: number; y: number }) => ({
      x: point.x,
      y: point.y,
    }));
    const { portDock } = scenarioDataContainer.data.mooring;

    mooring.mooringArrangements[mooring.mooringArrangementName].forEach((line: any, index) => {
      if (line.fairleadId) {
        const fairleadLocal = vessel.fairleads.find((f) => f.id === line.fairleadId);
        const fairleadLatLng = fairleadsCoords.find((f) => f.id === line.fairleadId);
        const bollard = portStore.getConnectedBollard(line);

        if (bollard) {
          const currentFairleadLatLng = fairleadLatLng;
          let currentFairleadLocal = [fairleadLocal.x, fairleadLocal.y];

          let clockwise = currentFairleadLocal[0] >= 0;
          clockwise = portDock ? clockwise : !clockwise;

          let coordinateList: number[][] = [[currentFairleadLatLng.longitude, currentFairleadLatLng.latitude]];
          let deckPlaneIndex: number | null = null;

          while (
            appStateStore.vesselSilhoutteIntersects(
              scenarioDataContainer,
              bollards,
              currentFairleadLocal,
              line.bollardIndex,
              deckPlaneFull,
            )
          ) {
            if (deckPlaneIndex > deckPlane.length) break;

            let testDistance = 10000000;

            // Establish initial deckPlaneIndex by finding nearest deckPlane point to current fairlead
            if (deckPlaneIndex == null) {
              for (let index = 0; index < deckPlane.length; index++) {
                const localDistance = Math.sqrt(
                  (currentFairleadLocal[0] - deckPlane[index].x) * (currentFairleadLocal[0] - deckPlane[index].x) +
                    (currentFairleadLocal[1] - deckPlane[index].y) * (currentFairleadLocal[1] - deckPlane[index].y),
                );

                if (localDistance < testDistance) {
                  testDistance = localDistance;
                  // As soon as we inverse our direction, we're closest, so store deckPlaneIndex and break
                  deckPlaneIndex = index;
                }
              }
            }

            // Fetch nearest position to run line from based on this near found position of deck plane
            currentFairleadLocal = [deckPlane[deckPlaneIndex].x, deckPlane[deckPlaneIndex].y];

            // Keep moving around the ship clockwise/counter-clockwise dotting it as we go
            if (clockwise) {
              deckPlaneIndex++;
              deckPlaneIndex = deckPlaneIndex > deckPlane.length - 1 ? 0 : deckPlaneIndex;
            } else {
              deckPlaneIndex--;
              deckPlaneIndex = deckPlaneIndex < 0 ? deckPlane.length - 1 : deckPlaneIndex;
            }

            // eslint-disable-next-line prefer-destructuring
            const currentFairleadLatLng = relativeToLatLng(berth, mooring, [
              {
                x: currentFairleadLocal[0],
                y: currentFairleadLocal[1],
              },
            ])[0];

            coordinateList = [...coordinateList, [currentFairleadLatLng.longitude, currentFairleadLatLng.latitude]];
          } // end while

          const points = bollard.point.map((point) => point);

          fairleadToBollard = [
            {
              coordinate: [...coordinateList, points],
              properties: { name: line.name },
            },
          ];
        }

        fairleadLines = [
          ...fairleadLines,
          {
            ...fairleadToBollard[0],
            coordinate: [...(fairleadToBollard[0]?.coordinate ?? [])],
          },
        ];
      } else {
        fairleadLines = [...fairleadLines, null];
      }
    });
  }

  return fairleadLines;
};

const getWinchToFairleadLines = (berth: Berth, vessel: Vessel, mooring: Mooring): MooringLinePlot[] => {
  let winchToFairlead: MooringLinePlot[] = [];

  /*
   * // TODO: properly validate mooring data at a higher level)
   *
   * the extra conditions in the if() stmt is a quick fix to protect the app from crashing
   * for garbage lat/long values.
   */
  if (mooring && mooring.latitude !== null && mooring.longitude !== null) {
    mooring.mooringArrangements[mooring.mooringArrangementName].forEach((line, index) => {
      if (line.fairleadId) {
        const vesselFairlead = vessel.fairleads.find((f) => f.id === line.fairleadId);
        if (vesselFairlead) {
          const fairleadLatLng = relativeToLatLng(berth, mooring, [vesselFairlead]);

          if (vesselFairlead.pathSelected) {
            const path = vesselFairlead.paths[vesselFairlead.pathSelected];

            let winch: Winch;
            if (line.bittIndex !== undefined && line.bittIndex !== null) {
              const bitt = vessel.bitts.find((bitt) => bitt.id === line.bittIndex);
              winch = {
                id: bitt.id,
                winchProfileId: bitt.bittProfileId,
                connectionPoints: [
                  {
                    id: path.winchConnectionPointId,
                    x: bitt.x,
                    y: bitt.y,
                    z: bitt.z,
                  },
                ],
              };
            } else {
              winch = vessel.winches.find((winch) => winch.id === path.winchId);
            }
            const connectionPoint = winch.connectionPoints.find(
              (connectionPoint) => connectionPoint.id === path.winchConnectionPointId,
            );

            const connectionPointLatLng = relativeToLatLng(berth, mooring, [connectionPoint]);

            const pedestalFairleads: number[][] = path.pedestalIds.map((pedestalId) => {
              const pedestalFairlead = vessel.pedestalFairleads.find(
                (pedestalFarilead) => pedestalFarilead.id === pedestalId,
              );
              const pedistalLatLng = relativeToLatLng(berth, mooring, [pedestalFairlead]);

              return [pedistalLatLng[0].longitude, pedistalLatLng[0].latitude];
            });

            winchToFairlead = [
              ...winchToFairlead,
              {
                coordinate: [
                  [connectionPointLatLng[0].longitude, connectionPointLatLng[0].latitude],
                  ...pedestalFairleads,
                  [fairleadLatLng[0].longitude, fairleadLatLng[0].latitude],
                ],
              },
            ];
          } else {
            winchToFairlead = [...winchToFairlead, null];
          }
        }
      }
    });
  }

  return winchToFairlead;
};

/**
 * This function gets berth marker on the berth
 * @param {Berth} berth This is the berth the vessel is moored at
 * @param {Mooring} mooring This is the mooring configuration
 */
const getBerthMarkerOnBerth = (berth: Berth, mooring: Mooring, diagnostics?: boolean | null): LatLon => {
  let berthMarkerBerth = findVesselPositionOnBerth(berth, mooring);

  if (diagnostics) {
    console.log(`Berth Marker On Berth: ${berthMarkerBerth}`);
  }

  return berthMarkerBerth;
};

/**
 * This function gets the berth marker on the vessel
 * @param {Berth} berth This is the berth the vessel is moored at
 * @param {Mooring} mooring The mooring
 * @param {Vessel} vessel The vessel
 * @param {number} maxFenderThickness The maximum thickness
 */
const getBerthMarkerOnVessel = (
  berth: Berth,
  mooring: Mooring,
  vessel: Vessel,
  maxFenderThickness: number,
  diagnostics?: boolean | null,
): UtmLatLon => {
  const { beam } = vessel;

  let berthMarkerBerth = findVesselPositionOnBerth(berth, mooring);
  const berthToShipAngle = getBerthToShipAngleTN(berth, diagnostics);
  const result = destinationPoint(berthMarkerBerth, beam / 2 + maxFenderThickness, berthToShipAngle);

  if (diagnostics) {
    console.log(`Berth Marker On Vessel: ${result}`);
  }

  return result;
};

/**
 * This function gets the berth marker on the vessel to be used for plotting mooring line
 * @param {Berth} berth This is the berth the vessel is moored at
 * @param {Mooring} mooring The mooring
 * @param {Vessel} vessel The vessel
 * @param {number} maxFenderThickness The maximum thickness
 */
const getBerthMarkerOnVesselForPlotting = (
  berth: Berth,
  mooring: Mooring,
  vessel: Vessel,
  maxFenderThickness: number,
  markerToPlotFor: number,
  diagnostics?: boolean | null,
) => {
  const { beam } = vessel;

  let berthMarkerBerth = findVesselPositionOnBerthForPlot(berth, markerToPlotFor);
  const berthToShipAngle = getBerthToShipAngle(berth, diagnostics);
  const result = berthMarkerBerth.destinationPoint(beam / 2 + maxFenderThickness, berthToShipAngle);

  if (diagnostics) {
    console.log(`Berth Marker On Vessel: ${result}`);
  }

  return result;
};

/**
 * This function gets berth marker on the berth
 * @param {Berth} berth This is the berth the vessel is moored at
 * @param {Mooring} mooring This is the mooring configuration
 * @param {number} MarkerToPlot Marker to plot for in m
 */
const getBerthMarkerOnBerthForPlotting = (
  berth: Berth,
  mooring: Mooring,
  markerToPlotFor: number,
  diagnostics?: boolean | null,
): LatLon => {
  let berthMarkerBerth = findVesselPositionOnBerthForPlot(berth, markerToPlotFor);

  if (diagnostics) {
    console.log(`Berth Marker On Berth: ${berthMarkerBerth}`);
  }

  return berthMarkerBerth;
};

const getManifoldPositionOnVessel = (
  berthAngle: number,
  mooring: Mooring,
  vessel: Vessel,
  maniFoldDistance: number,
): { portSide?: LatLon[][]; starboardSide?: LatLon[][] } => {
  if (!maniFoldDistance) return;

  const ANGLE = 90;
  const vesselPosition: LatLon = new LatLon(mooring.latitude, mooring.longitude);
  const vesselCenterToFore = vessel.loa / 2;
  const vesselCenterToSides = vessel.beam / 2;

  const manifoldPositionCenter: LatLon = vesselPosition.destinationPoint(
    vesselCenterToFore - maniFoldDistance,
    berthAngle,
  );

  const manifoldPositionPortSide: LatLon = manifoldPositionCenter.destinationPoint(
    vesselCenterToSides,
    mooring.portDock ? berthAngle - ANGLE : berthAngle + ANGLE,
  );
  const manifoldPositionStarboardSide: LatLon = manifoldPositionCenter.destinationPoint(
    vesselCenterToSides,
    mooring.portDock ? berthAngle + ANGLE : berthAngle - ANGLE,
  );

  const portSide = vessel.loading.tanker.berthingSides.includes('port');
  const starboardSide = vessel.loading.tanker.berthingSides.includes('starboard');

  let maniFoldPoints: { portSide?: LatLon[]; starboardSide?: LatLon[] } = {};

  if (portSide && starboardSide) {
    maniFoldPoints = {
      portSide: [manifoldPositionPortSide.longitude, manifoldPositionPortSide.latitude],
      starboardSide: [manifoldPositionStarboardSide.longitude, manifoldPositionStarboardSide.latitude],
    };
  } else if (portSide) {
    maniFoldPoints = {
      portSide: [manifoldPositionPortSide.longitude, manifoldPositionPortSide.latitude],
      starboardSide: [manifoldPositionCenter.longitude, manifoldPositionCenter.latitude],
    };
  } else if (starboardSide) {
    maniFoldPoints = {
      portSide: [manifoldPositionCenter.longitude, manifoldPositionCenter.latitude],
      starboardSide: [manifoldPositionStarboardSide.longitude, manifoldPositionStarboardSide.latitude],
    };
  }

  return maniFoldPoints;
};

const findVesselPositionOnBerth = (berth: Berth, mooring: Mooring): UtmLatLon => {
  const { berthMarker } = mooring;

  const berthStart: UtmLatLon = new UtmLatLon(
    (berth.berthRuler ?? berth.berthLine)[0][1],
    (berth.berthRuler ?? berth.berthLine)[0][0],
  );
  const berthAngle = getBerthAngleTN(berth);

  // Get virtual offset by looking at berthStartFromTerminal
  const berthMarkerBerth = destinationPoint(
    berthStart,
    mooring.portDock ? berthMarker - berth.berthStartFromTerminal * 2 : berthMarker,
    berthAngle,
  );
  return berthMarkerBerth;
};

// Calculate the destination point by offsetting in the closest utm projection:
const destinationPoint = (point: UtmLatLon, distance: number, angle: number): UtmLatLon => {
  const utm: Utm = point.toUtm();

  const radians = (Math.PI / 180) * (-angle + 90);
  const cos = Math.cos(radians);
  const sin = Math.sin(radians);

  const distanceX = cos * distance;
  const distanceY = sin * distance;

  const newX = utm.easting + distanceX;
  const newY = utm.northing + distanceY;
  const newUtm = cloneDeep(utm);

  newUtm.easting = newX;
  newUtm.northing = newY;

  const geo = newUtm.toLatLon();

  return geo;
};

const findVesselPositionOnBerthForPlot = (berth: Berth, markerToPlotFor: number) => {
  const berthStart = new LatLon(
    (berth.berthRuler ?? berth.berthLine)[0][1],
    (berth.berthRuler ?? berth.berthLine)[0][0],
  );
  const berthAngle = getBerthAngle(berth);

  // Get virtual offset by looking at berthStartFromTerminal
  const markerPositionToRuler = berthStart.destinationPoint(markerToPlotFor, berthAngle);

  return markerPositionToRuler;
};

/**
 * This function gets the berth marker on the vessel
 * @param {Berth} berth This is the berth the vessel is moored at
 * @param {PortData} portData This is the port data the vessel is moored at
 */
const getBerthRuler = (berth: Berth, portData: PortData, diagnostics?: boolean | null) => {
  return berth.berthLine;
};

const setupBerthRuler = (
  vesselScenarioStore: VesselScenarioStore,
  portData: PortData,
  berth: Berth,
  isSetup: boolean,
  isSiUnits: boolean,
) => {
  // Setup new berth ruler
  if (isUndefined(berth.berthStartFromTerminal)) {
    berth.berthStartFromTerminal = 0;
  }

  if (berth) {
    const newBerthRulerCoords = getBerthRuler(berth, portData, false);
    berth.berthRuler = newBerthRulerCoords;

    if (
      isSetup &&
      vesselScenarioStore.scenarioData.data.vessel.loa &&
      vesselScenarioStore.scenarioData.data.vessel.bridgeToBow
    ) {
      setDefaultBerthMarker(vesselScenarioStore, berth, isSiUnits);
    }
  }
};

const setDefaultBerthMarker = (vesselScenarioStore: VesselScenarioStore, berth: Berth, isSiUnits: boolean) => {
  vesselScenarioStore.scenarioData.data.mooring.berthMarker = getDefaultBerthMarker(
    vesselScenarioStore,
    berth,
    isSiUnits,
  );

  calculateBerthMarkers(
    vesselScenarioStore,
    berth,
    'mooring.berthMarker',
    vesselScenarioStore.scenarioData.data.mooring.berthMarker,
    null,
  );
};

/**
 * Calculated the default berth marker location
 * @param {Berth} berth This is the berth the vessel is moored at
 */
const getDefaultBerthMarker = (vesselScenarioStore: VesselScenarioStore, berth: Berth, isSiUnits: boolean) => {
  const vesselLoa = vesselScenarioStore.scenarioData.data.vessel.loa;
  const berthLength = getDistanceCoords(berth.berthRuler[0], berth.berthRuler[berth.berthRuler.length - 1]);
  let offset = vesselScenarioStore.scenarioData.data.vessel.bridgeToBow - vesselLoa / 2;

  let berthMarker;

  const vesselWithDefaultPosition = berth?.defaultVesselBerthing?.filter(
    (v) => v.loaFrom < vesselLoa && v.loaTo > vesselLoa,
  );

  // Either select default position (if set) or center vessel's bridge in the middle of the berth with berth.berthStartFromTerminal offset.
  if (vesselWithDefaultPosition?.length > 0) {
    berthMarker =
      vesselWithDefaultPosition[0].position +
      (berth.berthIsToTheRight ? -1 : 1) * (vesselScenarioStore.scenarioData.data.mooring.portDock ? -offset : offset);
  } else {
    berthMarker =
      berthLength / 2 +
      berth.berthStartFromTerminal +
      (berth.berthIsToTheRight ? -1 : 1) * (vesselScenarioStore.scenarioData.data.mooring.portDock ? -offset : offset);
  }

  // Ultimately the values are rounded off to 4 decimals before entering the scenario after it has been throguh the FieldInput
  // To ensure that the initial berth marker is the same as after being processed through FieldInput, we ensure the value is correct up front
  if (isSiUnits) {
    berthMarker = round(berthMarker, 4);
  } else {
    berthMarker = round(
      convert(round(convert(berthMarker).from('m').to('ft'), 0))
        .from('ft')
        .to('m'),
      4,
    );
  }

  if (berthMarker < 0) {
    return 0;
  }

  return berthMarker;
};

/**
 * Calculate berth markers based on given field for other two fields.
 * @param fieldName
 * @param value
 */
const calculateBerthMarkers = (
  vesselScenarioStore: VesselScenarioStore,
  berth: Berth,
  fieldName: string,
  value: number,
  completeCallback: () => void,
) => {
  if (berth == null) {
    if (completeCallback) completeCallback();

    return;
  }

  let bowMarker = 0;
  let berthMarker = 0;
  let sternMarker = 0;
  const editedField = fieldName.split('.')[1];
  const { loa, bridgeToBow } = vesselScenarioStore.scenarioData.data.vessel;
  const bridgeToStern = loa - bridgeToBow;

  if (fieldName === 'mooring.bowMarker') {
    bowMarker = value;

    if (berth.berthIsToTheRight) {
      if (vesselScenarioStore.scenarioData.data.mooring.portDock) {
        berthMarker = value + bridgeToBow;
        sternMarker = value + bridgeToBow + bridgeToStern;
      } else {
        berthMarker = value - bridgeToBow;
        sternMarker = value - bridgeToBow - bridgeToStern;
      }
    } else {
      if (vesselScenarioStore.scenarioData.data.mooring.portDock) {
        berthMarker = value - bridgeToBow;
        sternMarker = value - bridgeToBow - bridgeToStern;
      } else {
        berthMarker = value + bridgeToBow;
        sternMarker = value + bridgeToBow + bridgeToStern;
      }
    }
  } else if (fieldName === 'mooring.berthMarker') {
    berthMarker = value;

    if (berth.berthIsToTheRight) {
      if (vesselScenarioStore.scenarioData.data.mooring.portDock) {
        sternMarker = value + bridgeToStern;
        bowMarker = value - bridgeToBow;
      } else {
        sternMarker = value - bridgeToStern;
        bowMarker = value + bridgeToBow;
      }
    } else {
      if (vesselScenarioStore.scenarioData.data.mooring.portDock) {
        sternMarker = value - bridgeToStern;
        bowMarker = value + bridgeToBow;
      } else {
        sternMarker = value + bridgeToStern;
        bowMarker = value - bridgeToBow;
      }
    }
  } else if (fieldName === 'mooring.sternMarker') {
    sternMarker = value;

    if (berth.berthIsToTheRight) {
      if (vesselScenarioStore.scenarioData.data.mooring.portDock) {
        bowMarker = value - bridgeToStern - bridgeToBow;
        berthMarker = value - bridgeToStern;
      } else {
        bowMarker = value + bridgeToStern + bridgeToBow;
        berthMarker = value + bridgeToStern;
      }
    } else {
      if (vesselScenarioStore.scenarioData.data.mooring.portDock) {
        bowMarker = value + bridgeToStern + bridgeToBow;
        berthMarker = value + bridgeToStern;
      } else {
        bowMarker = value - bridgeToStern - bridgeToBow;
        berthMarker = value - bridgeToStern;
      }
    }
  }

  transaction(() => {
    vesselScenarioStore.scenarioData.data.mooring.primaryBerthMarker = editedField;

    vesselScenarioStore.scenarioData.data.mooring.bowMarker = bowMarker;

    vesselScenarioStore.scenarioData.data.mooring.berthMarker = berthMarker;

    vesselScenarioStore.scenarioData.data.mooring.sternMarker = sternMarker;

    if (completeCallback) {
      completeCallback();
    }
  });
};

/**
 * This function gets the vessel centre
 * @param {Berth} berth This is the berth the vessel is moored at
 * @param {Mooring} mooring The mooring
 * @param {Vessel} vessel The mooring
 * @param {number} maxFenderThickness The maximum thickness
 */
const getVesselCentreOnVessel = (
  berth: Berth,
  mooring: Mooring,
  vessel: Vessel,
  maxFenderThickness: number,
  diagnostics?: boolean | null,
): UtmLatLon => {
  const { bridgeToBow, loa } = vessel;
  const headingAngle = getHeadingAngleTN(mooring, berth, diagnostics);
  const berthMarkerOnVessel = getBerthMarkerOnVessel(berth, mooring, vessel, maxFenderThickness, diagnostics);
  const result = destinationPoint(
    berthMarkerOnVessel,
    (berth.berthIsToTheRight ? -1 : 1) * berth.berthStartFromTerminal + (bridgeToBow - loa / 2),
    headingAngle,
  );

  if (diagnostics) {
    console.log(`Vessel Centre On Vessel: ${result}`);
  }

  return result;
};

const getVesselCentre = (mooring: Mooring, port: Port | null, berth: Berth, vessel: Vessel) => {
  let vesselCentreOnVessel: LatLon;
  // If undefined calculate vessel location otherwise take from DTO.
  if (mooring?.longitude === null || mooring?.latitude === null || mooring.longitude === 0 || mooring.latitude === 0) {
    const maxFenderThickness =
      mooring?.maxFenderThickness === null || mooring?.maxFenderThickness === undefined
        ? getMaxFenderThickness(port?.fenders, port?.fenderProfiles, berth.berthIsToTheRight)
        : mooring.maxFenderThickness;
    // VesselCenter on vessel. From the berthmarker on the VESSEL, go in the direction of the berth or the other way depending on orientation
    vesselCentreOnVessel = getVesselCentreOnVessel(berth, mooring, vessel, maxFenderThickness);
  } else {
    vesselCentreOnVessel = new LatLon(mooring.latitude, mooring.longitude);
  }

  return vesselCentreOnVessel;
};

/**
 * This function converts a coordinate from UtmLatLon to LatLon type
 * @param {UtmLatLon} point This is the berth the vessel is moored at
 */
const utmLatLonToLatLon = (point: UtmLatLon): LatLon => {
  return new LatLon(point.lat, point.lon);
};

/**
 * This function gets the vessel centre projected to the berth
 * @param {Berth} berth This is the berth the vessel is moored at
 * @param {Mooring} mooring The mooring
 * @param {Vessel} vessel The mooring
 * @param {number} maxFenderThickness The maximum thickness
 */
const getVesselCentreOnBerth = (
  berth: Berth,
  mooring: Mooring,
  vessel: Vessel,
  returnUtmLatLon: boolean = false,
  diagnostics?: boolean | null,
): LatLon | UtmLatLon => {
  const { bridgeToBow, loa } = vessel;
  const headingAngle = getHeadingAngleTN(mooring, berth, diagnostics);
  const berthMarkerOnBerth = getBerthMarkerOnBerth(berth, mooring, diagnostics);
  const result = destinationPoint(
    berthMarkerOnBerth,
    (berth.berthIsToTheRight ? -1 : 1) * berth.berthStartFromTerminal + (bridgeToBow - loa / 2),
    headingAngle,
  );

  if (diagnostics) {
    console.log(`Vessel Centre On Berth: ${result}`);
  }

  if (returnUtmLatLon) return result;

  return utmLatLonToLatLon(result);
};

/**
 * This function returns the maximum fender thickness
 * @param {Fender[]} fenders A list of all fenders
 * @param {FenderProfile[]} fenderProfiles A list of all fender profiles
 * @param {boolean} berthIsToTheRight If berth is defined to the right or left
 */
const getMaxFenderThickness = (
  fenders: Fender[] | null,
  fenderProfiles: FenderProfile[] | null,
  berthIsToTheRight: boolean,
): number => {
  if (!fenders || !fenderProfiles) return 0;

  return fenders.length === 0
    ? 0
    : fenders
        .map((fender) => {
          const fenderProfile = fenderProfiles.find((profile) => profile.fenderProfileId === fender.profileId);
          if (fenderProfile !== undefined) {
            if (fender?.fenderOffset === null || fender?.fenderOffset === undefined) {
              return fenderProfile.thickness;
            } else if (berthIsToTheRight) {
              return fenderProfile.thickness - fender.fenderOffset;
            } else {
              return fenderProfile.thickness + fender.fenderOffset;
            }
          }

          return null;
        })
        .reduce((max, n) => (n > max ? n : max));
};

const drawRuler = (
  startPoint: LatLon,
  length: number,
  angle: number,
  layerId: string,
  endsWidth?: number,
  lineColour: [number, number, number] | [number, number, number, number] = null,
  label: string = null,
  labelSize = 9,
  labelStandOff: number = null,
  dashDistance: number = null,
  dashAngle: number = null,
  labelAngle: number = 0,
) => {
  const layers = [];

  if (label) {
    const startTextPoint = startPoint.destinationPoint(labelStandOff, (dashAngle ?? angle) + 90);
    const middleRulerPoint = startTextPoint.destinationPoint(length / 2, angle);

    const labelLayer = new TextLayer({
      id: `${layerId}_label`,
      data: [
        {
          coordinates: [middleRulerPoint.lon, middleRulerPoint.lat],
          name: 'distance',
        },
      ],
      // backgroundColor: [255, 0, 0, 200],
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 'bold',
      fontSettings: {
        radius: 1,
      },
      billboard: false,
      getSize: labelSize,
      getColor: lineColour ?? BLACK_RGB,
      getPosition: (d: any) => d.coordinates,
      characterSet: CHARACTER_SET,
      getText: (d) => label,
      getAngle: labelAngle,
      getTextAnchor: 'middle',
      getAlignmentBaseline: 'center',
    });
    layers.push(labelLayer);
  }

  const rulerCoords = [
    endsWidth &&
      drawPolygon(
        [
          [0, endsWidth],
          [0, -endsWidth],
        ],
        startPoint.lat,
        startPoint.lon,
        angle,
      ),
    drawPolygon(
      [
        [0, 0],
        [length, 0],
      ],
      startPoint.lat,
      startPoint.lon,
      angle,
    ),
    endsWidth &&
      drawPolygon(
        [
          [length, endsWidth],
          [length, -endsWidth],
        ],
        startPoint.lat,
        startPoint.lon,
        angle,
      ),
  ];

  const rulerLayer = new GeoJsonLayer({
    id: layerId,
    data: [
      {
        geometry: {
          // https://tools.ietf.org/html/rfc7946#section-3.1.5
          type: 'MultiLineString',
          coordinates: rulerCoords,
        },
      },
    ],
    pickable: true,
    stroked: false,
    filled: true,
    getText: (v: any) => v.properties.name,
    getLineColor: lineColour ?? BLACK_RGB,
    wireframe: true,
    getLineWidth: 1,
    getElevation: 30,
  });
  layers.push(rulerLayer);

  const endPoint = startPoint.destinationPoint(length, angle);

  if (dashDistance) {
    const rulerDashCoords = [
      drawPolygon(
        [
          [0, 0],
          [0, dashDistance],
        ],
        startPoint.lat,
        startPoint.lon,
        dashAngle,
      ),
      drawPolygon(
        [
          [0, 0],
          [0, dashDistance],
        ],
        endPoint.lat,
        endPoint.lon,
        dashAngle,
      ),
    ];
    const dashLayer = new GeoJsonLayer({
      id: `${layerId}_dashes`,
      data: [
        {
          geometry: {
            // https://tools.ietf.org/html/rfc7946#section-3.1.5
            type: 'MultiLineString',
            coordinates: rulerDashCoords,
          },
        },
      ],
      pickable: true,
      stroked: false,
      filled: true,
      getText: (v: any) => v.properties.name,
      getLineColor: lineColour ?? [0, 0, 0, 200],
      wireframe: true,
      getLineWidth: 0.5,
      getElevation: 30,
      extensions: [new PathStyleExtension({ dash: true })],
      getDashArray: [8, 8],
      lineDashJustified: true,
    });
    layers.push(dashLayer);
  }

  return layers;
};

/**
 * Inverse the specified angle.
 * @param angle
 * @returns Inverse
 */
const inverseAngle = (angle: number) => (angle - 180 + 360) % 360;

/**
 * Project a bounding rectangle from a centre lat/lng, given width + angle, height + angle.
 * Eg from return object can be used like,
 * const vesselPolygon = vesselProjectionBox.map((p) => new LatLon(p.lat, p.lon));
   const collision = new LatLon(otherVesselPoint.lat, otherVesselPoint.lon).isEnclosedBy(vesselPolygon);
 * @param centreLatLng
 * @param width
 * @param widthAngle
 * @param length
 * @param lengthAngle
 * @returns
 */
const projectRectangle = (
  centreLatLng: LatLon,
  width: number,
  widthAngle: number,
  length: number,
  lengthAngle: number,
) => {
  return [
    centreLatLng.destinationPoint(width / 2, widthAngle).destinationPoint(length / 2, lengthAngle),
    centreLatLng.destinationPoint(width / 2, inverseAngle(widthAngle)).destinationPoint(length / 2, lengthAngle),
    centreLatLng.destinationPoint(width / 2, widthAngle).destinationPoint(length / 2, inverseAngle(lengthAngle)),
    centreLatLng
      .destinationPoint(width / 2, inverseAngle(widthAngle))
      .destinationPoint(length / 2, inverseAngle(lengthAngle)),
  ];
};

/**
 * Check if point is to the left of a line.
 * Complete with ASCII art.
 *
 * ```
 * .---------| <-- TRUE
 * |---------. <-- FALSE
 * ```
 * @param startOfLine
 * @param endOfLine
 * @param point
 * @returns `boolean`
 */
const isPointToLeft = (startOfLine: LatLon, endOfLine: LatLon, point: LatLon) => {
  return (
    (endOfLine.lon - startOfLine.lon) * (point.lat - startOfLine.lat) -
      (endOfLine.lat - startOfLine.lat) * (point.lon - startOfLine.lon) >
    0
  );
};

/**
 * Resize the vessel proportionally by updating Fairleads, Pedestal Fairleads, Deck Plane and Winches so the automoor can run correctly
 * @param axis 'x' or 'y'
 * @param percentage
 * @param vesselScenarioStore
 */
const scaleVessel = (axis: 'x' | 'y' | 'z', percentage: number, vesselScenarioStore: VesselScenarioStore) => {
  try {
    const { fairleads, pedestalFairleads, deckPlane, winches } = vesselScenarioStore.scenarioData.data.vessel;

    if (axis !== 'z') {
      vesselScenarioStore.scenarioData.data.vessel.deckPlane = deckPlane.map((deck) => ({
        ...deck,
        [axis]: round(deck[axis] * percentage, 3),
      }));
    }

    vesselScenarioStore.scenarioData.data.vessel.fairleads = fairleads.map((fairlead) => ({
      ...fairlead,
      [axis]: round(fairlead[axis] * percentage, 3),
    }));

    vesselScenarioStore.scenarioData.data.vessel.pedestalFairleads = pedestalFairleads.map((pedestal) => ({
      ...pedestal,
      [axis]: round(pedestal[axis] * percentage, 3),
    }));

    vesselScenarioStore.scenarioData.data.vessel.winches = winches.map((winch) => ({
      ...winch,
      connectionPoints: winch.connectionPoints.map((point) => ({
        ...point,
        [axis]: round(point[axis] * percentage, 3),
      })),
    }));
  } catch (reason) {
    console.error('Scale Vessel: ', reason);
  }
};

/**
 * This function produces one or more GeoJSON layers based on input data. If the input data is a list of data and style, then the geojson is returned directly. If the data has raw coordinates, type and style, then the geojson is constructed
 * @param {Array} array
 * @return {*}
 */

const renderingGeoJsonData = (layers) => {
  if (!layers) return null;

  let renderedLayers = {};

  const features = [];

  layers.map((layer) => features.push(...renderingFeatures(layer)));

  renderedLayers = {
    type: 'FeatureCollection',
    features,
  };

  return renderedLayers;
};

const renderingFeatures = (layer) => {
  const { type, coordinates, properties, style, owner } = layer;
  let features = type === 'Point' && isArray(coordinates[0]) ? coordinates : [coordinates];

  features = toJS(features);

  const geoJson = features.map((coordinate) => ({
    type: 'Feature',
    geometry: {
      type,
      coordinates: coordinate,
    },
    style: style || {
      weight: 0,
      opacity: 0,
      fillOpacity: 0,
      radius: 0,
    },
    properties: { ...style, ...properties, owner },
  }));

  return geoJson;
};

const setupCraneStaging = (
  vesselScenarioStore: VesselScenarioStore,
  portStore: PortStore,
  berth: Berth,
  newBerth: boolean,
) => {
  const CRANE_SPACING = 20; // Spacing between cranes in meters
  // Setup new crane staging locations on creating a new scenario:

  // Return if craneStaging has already been set when editing a scenario - But not when changing berth:
  if (
    vesselScenarioStore.scenarioData.data.mooring?.craneStaging &&
    vesselScenarioStore.scenarioData.data.mooring.craneStaging !== null &&
    vesselScenarioStore.scenarioData.data.mooring.craneStaging.length > 0 &&
    !newBerth
  )
    return;

  const berthMarkerCenter =
    (vesselScenarioStore.scenarioData.data.mooring.bowMarker +
      vesselScenarioStore.scenarioData.data.mooring.sternMarker) /
    2;

  const maxLegWidth = berth.shipLoaders.reduce(
    (max, shipLoader) => (shipLoader.widthLegs > max ? shipLoader.widthLegs : max),
    0,
  );

  const numberOfShipLoaders = berth.shipLoaders.length;

  // Get the adjacent berths cranes:
  const allBerths: Berth[] = portStore.getAllBerths(); // All berths with data in a flat list.
  const adjacentBerths: string[] = [
    berth.adjacentBerths.length > 0 ? berth.adjacentBerths[0] : 'none',
    berth.berthName,
    berth.adjacentBerths.length > 1 ? berth.adjacentBerths[1] : 'none',
  ];

  const allShipLoaders: ShipLoader[] = [];

  let cranesBefore = 0;

  adjacentBerths.forEach((adjacentBerthName) => {
    const adjacentBerth = allBerths.find((berth) => berth.berthName === adjacentBerthName);
    if (adjacentBerth && adjacentBerth.berthName === berth.berthName) {
      cranesBefore = allShipLoaders.length;
    }
    if (adjacentBerth && adjacentBerth.shipLoaders) {
      // Push all ship loaders in array:
      allShipLoaders.push(...adjacentBerth.shipLoaders);
    }
  });

  const craneStaging: CraneStaged[] = allShipLoaders.map((shipLoader, index) => {
    let craneEnabled = true;
    if (index < cranesBefore || index > cranesBefore + numberOfShipLoaders - 1) {
      craneEnabled = false;
    }
    const craneStage: CraneStaged = {
      ...shipLoader,
      berthMarkerCenter:
        berthMarkerCenter -
        (cranesBefore + numberOfShipLoaders / 2) * (maxLegWidth + CRANE_SPACING) +
        index * (maxLegWidth + CRANE_SPACING),
      enabled: craneEnabled,
      shipLoaderId: index,
      primaryBerth: craneEnabled, // Initially enabled cranes are also part of the primary berth
    };

    return craneStage;
  });

  transaction(() => {
    vesselScenarioStore.scenarioData.data.mooring.craneStaging = craneStaging;
  });
};

export {
  calculateBerthMarkers,
  destinationPoint,
  drawPolygon,
  drawRuler,
  flyToBerth,
  flyToVessel,
  getBerthAngle,
  getBerthAngleTN,
  getBerthMarkerOnBerth,
  getBerthMarkerOnBerthForPlotting,
  getBerthMarkerOnVessel,
  getBerthMarkerOnVesselForPlotting,
  getBerthRuler,
  getBerthSizeLatLon,
  getBerthToShipAngle,
  getBerthToShipAngleTN,
  getDefaultBerthMarker,
  getDistanceCoords,
  getFairleadToBollardLines,
  getHeadingAngle,
  getHeadingAngleTN,
  getLabelOrientationFromBerthAngle,
  getManifoldPositionOnVessel,
  getMaxFenderThickness,
  getShipToBerthAngle,
  getVesselCentre,
  getVesselCentreOnBerth,
  getVesselCentreOnVessel,
  getWinchToFairleadLines,
  inverseAngle,
  isPointToLeft,
  latLngHeightToUtm,
  latLngToUtm,
  projectRectangle,
  relativeToLatLng,
  renderingGeoJsonData,
  scaleVessel,
  setDefaultBerthMarker,
  setupBerthRuler,
  setupCraneStaging,
  utmLatLonToLatLon,
};
