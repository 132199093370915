import {
  Checkbox,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  MenuProps,
  Select,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { fieldLabel } from '../../helpers/fieldIntl';
import { DataConfig } from '../../interfaces/Config';
import defaultStyles from '../defaultStyles';
import { InputFieldOptionBase, InputFieldPropsBase, InputFieldValue } from './InputField/types';

interface CheckboxDropDownProps extends InputFieldPropsBase {
  fieldConfig: DataConfig;
  fieldName: string;
  value: InputFieldValue[];
  options: InputFieldOptionBase[];
  onChange(e: React.ChangeEvent<{ value: unknown }>, newValue: InputFieldValue | InputFieldOptionBase): void;
}

interface Props extends CheckboxDropDownProps, WithStyles<typeof defaultStyles> {}

const CheckBoxMenuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
} as Partial<MenuProps>;

const CheckboxDropDown: React.FC<Props> = ({
  value,
  fieldConfig,
  fieldName,
  onChange,
  disabled,
  noLabel,
  options,
  classes,
}: Props) => {
  const [values, setValues] = useState<InputFieldValue[]>(value.length > 0 ? value : []);
  const intl = useIntl();
  const label = fieldLabel(intl, fieldConfig, fieldName);

  // when value-prop changes
  useEffect(() => {
    if (value !== values) {
      setValues(value);
    }
  }, [value]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string[];
    const theVal = val.length > 0 ? val : [];
    setValues(theVal);
    // bubble up the event with the right parameters
    onChange(event, theVal);
  };

  return (
    <>
      <InputLabel>{!noLabel && (fieldConfig.unit ? `${label} [${fieldConfig.unit}]` : label)}</InputLabel>
      <Select
        multiple
        value={values}
        onChange={handleChange}
        input={<Input />}
        renderValue={(selected) => (selected as string[]).join(', ')}
        MenuProps={CheckBoxMenuProps}
        style={{ width: '100%' }}
        disabled={disabled}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.label} disabled={option.disabled}>
            <Checkbox checked={values.indexOf(option.value) > -1} color="primary" />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default withStyles(defaultStyles, { withTheme: true })(CheckboxDropDown);
