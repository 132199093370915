/* A collection of generic validators */
import { isArray } from "lodash";
import { getFirstArrayItem, getLastArrayItem } from "../arrays/arrays";
import { isNumeric } from "../common";
import { displayToField } from "../conversions";
import getDataField from "../getDataField";

/* A bunch of common regular expressions */
//  numbers with decimals, positive or negative
//  numbers with decimals, only positive ones
//  numbers with decimals, only negative ones
//  non decimal numbers
//  non decimal numbers, only positive ones
//  non decimal numbers, only negative ones
//  alphanumeric
//  alphanumeric without special chars
//  alpha, not numbers and not special chars
//  alpha with special chars, but not numbers
//  email
//  urls
//  passwords
//  dates
//  times
//  datetimes

const regExpLib = {
  numbers: "",
  positiveNumbers: "^\\d*\\.?\\d*$",
  negativeNumbers: "",
  integers: "^(\\+|-)?\\d+$",
  positiveIntegers: "^\\d+$",
  negativeIntegers: "",
  alphanumeric: "",
  alphanumericNoSpecials: "",
  alphaNoNumbersNoSpecials: "",
  alphaSpecialsNoNumbers: "",
  emails: "",
  urls: "",
  dates: "",
  times: "",
  dateTimes: "",
};

/**
 * value must meet the regular expression given in 'regexp' field in the config
 * @param {*} value
 * @param {String} regexp
 */
const regExp = (value, regexp) => {
  if (!regexp) {
    throw new Error(
      "regexp parameter must be given to validate the field's value"
    );
  }

  try {
    const r = new RegExp(regexp);

    return r.test(value);
  } catch (e) {
    throw new Error(
      "The regular expression given on regexp parameter is not valid"
    );
  }
};

/**
 * value has to be greater or equal to minValue
 * @param {number} value
 * @param {number} minValue
 * @returns {boolean}
 * @throws
 */
const min = (value, minValue) => {
  if (!isNumeric(value) || !isNumeric(minValue)) {
    throw new Error("min Validator error: both values passed must be numeric");
  }

  return value >= minValue;
};

/**
 * value has to be less or equal to maxValue
 * @param {number} value
 * @param {number} maxValue
 * @returns {boolean}
 * @throws
 */
const max = (value, maxValue) => {
  if (!isNumeric(value) || !isNumeric(maxValue)) {
    throw new Error("max Validator error: both values passed must be numeric");
  }

  return value <= maxValue;
};

/**
 * value has to be in collection
 * @param {*} value
 * @param {array} maxValue
 * @returns {boolean}
 * @throws
 */
const inCollection = (value, collection) => {
  if (!collection || !isArray(collection) || collection.length <= 0)
    throw new Error(
      "inCollection Validator error: The collection parameter must be an array of at least 1 item"
    );

  return collection.indexOf(value) > -1;
};

/**
 * value must be between (including) minValue and maxValue
 * @param {number} value
 * @param {number} minValue
 * @param {number} maxValue
 * @returns {boolean}
 */
const inRange = (value, minValue, maxValue) =>
  min(value, minValue) && max(value, maxValue);

/**
 * value must be between (including) minValue and maxValue
 * @param {Array.<number>} array
 * @param {number} minValue
 * @param {number} maxValue
 * @returns {boolean}
 */
const isValidRange = (array, minValue, maxValue) =>
  inRange(getFirstArrayItem(array), minValue, maxValue) &&
  inRange(getLastArrayItem(array), minValue, maxValue);

/**
 * Given fieldConfig, check all the generic validations are met for value
 * @param {*} value of the field
 * @param {object} fieldConfig of the field - NOTE: Pulled from "config_xxx.json" overrides with config.json fallback.
  * Two examples:
 *   listErrors.push({
          field: fieldConfig.field, // id of field
          labelId: 'validate.decimalExceeded', // custom label (if found in en.json or de.json, the message will be used from here)
          message: `Decimal points on value has exceeded maximum length of ${fieldConfig.decimals}.`, // if not found in en.json or de.json this message will be used.
        });

      return {
          success: false,
          labelId: 'validate.valuesOutsideBounds', // As no message is specified, only messageVals, entry must exist in en.json or de.json
          messageVals: {
            minimum: fieldConfig.minimum,
            maximum: fieldConfig.maximum,
          },
        };
 * @returns {({}|{error: boolean, labelId: string})}
 */
const validateField = (
  value,
  fieldConfig,
  dataConfig,
  scenarioData,
  showErrors?: boolean
) => {
  const listErrors = [];
  const valueIsArray = isArray(value);
  let unfinishedValue = false;

  const displayErrorsIfRequested = (result) => {
    if (showErrors && fieldConfig.showErrors) {
      fieldConfig.validationStatus = result;
      fieldConfig.showErrors();
    }
  };

  if (!fieldConfig) {
    console.error(
      "fieldConfig parameter must be given to validate the field's value"
    );
  } else {
    // Booleans, !visible and noValidate are always OK
    if (
      typeof value === "boolean" ||
      (Object.prototype.hasOwnProperty.call(fieldConfig, "visible") &&
        !fieldConfig.visible) ||
      (Object.prototype.hasOwnProperty.call(fieldConfig, "noValidate") &&
        fieldConfig.noValidate)
    ) {
      const result = { success: true, errors: [] };
      displayErrorsIfRequested(result);

      return result;
    }

    //  && fieldConfig.field == 'environmentalConditions.wind.speedValue'
    if (Object.prototype.hasOwnProperty.call(fieldConfig, "validateIf")) {
      const dataEntity = getDataField(
        scenarioData,
        fieldConfig.validateIf.field
      );
      const fieldName = fieldConfig.validateIf.field.substr(
        fieldConfig.validateIf.field.lastIndexOf(".") + 1
      );
      const otherFieldValue = dataEntity[fieldName];

      if (!regExp(otherFieldValue, fieldConfig.validateIf.regExp)) {
        // no requirement to validate, otherwise continue on to validate..
        const result = { success: true, errors: [] };
        displayErrorsIfRequested(result);

        return result;
      }
    }

    // if (fieldConfig.field == 'mooring.mooringArrangementName') {
    //   debugger;
    // }
    // check if it's required and the value is empty
    if (
      Object.prototype.hasOwnProperty.call(fieldConfig, "required") &&
      fieldConfig.required &&
      !value &&
      value !== 0
    ) {
      const result = {
        success: false,
        field: fieldConfig.field,
        labelId: "validate.valueRequired",
      };
      displayErrorsIfRequested(result);

      return result;
    }

    // if validators property exists, check if value meets regExp validation
    if (Object.prototype.hasOwnProperty.call(fieldConfig, "validators")) {
      let validatorErr = null;

      fieldConfig.validators.forEach((validator) => {
        if (!regExp(value, validator.regExp)) {
          validatorErr = {
            success: false,
            field: fieldConfig.field,
            labelId: validator.labelId,
          };
        }
      });

      if (validatorErr) {
        displayErrorsIfRequested(validatorErr);

        return validatorErr;
      }
    }

    if (
      !valueIsArray &&
      Object.prototype.hasOwnProperty.call(fieldConfig, "decimals")
    ) {
      // An unfinished value is considered as such if it ends in a decimal eg. "10."
      if (value.toString().indexOf(".") === value.toString().length - 1) {
        unfinishedValue = true;
      }

      const decimalPlaces =
        value.toString().indexOf(".") >= 0
          ? value.toString().length - 1 - value.toString().indexOf(".")
          : 0;

      if (decimalPlaces > fieldConfig.decimals) {
        const result = {
          success: false,
          field: fieldConfig.field,
          labelId:
            fieldConfig.decimals === 0
              ? "validate.decimalsDisallowed"
              : "validate.decimalsExceeded",
          messageVals: {
            decimals: fieldConfig.decimals,
          },
        };
        displayErrorsIfRequested(result);

        return result;
      }
    }

    if (!unfinishedValue) {
      const thisIsNumeric = !Number.isNaN(Number(value));

      // Do potential conversion (eg. kn -> m/s)
      if (valueIsArray) {
        if (!value.some((v) => !isNumeric(v))) {
          value = value.map((v) => displayToField(v, fieldConfig));
        }
      } else if (thisIsNumeric) {
        value = displayToField(value, fieldConfig);
      }

      const numberValue = parseFloat(value);

      // array-range value is in range?

      if (
        valueIsArray &&
        Object.prototype.hasOwnProperty.call(fieldConfig, "minimum") &&
        Object.prototype.hasOwnProperty.call(fieldConfig, "maximum") &&
        !isValidRange(
          value,
          displayToField(fieldConfig.minimum, fieldConfig),
          displayToField(fieldConfig.maximum, fieldConfig)
        )
      ) {
        const result = {
          success: false,
          field: fieldConfig.field,
          labelId: "validate.valuesOutsideBounds",
          messageVals: {
            minimum: fieldConfig.minimum,
            maximum: fieldConfig.maximum,
          },
        };
        displayErrorsIfRequested(result);

        return result;
      }

      if (!valueIsArray) {
        // check minimum and maximum if they are there
        if (
          Object.prototype.hasOwnProperty.call(fieldConfig, "minimum") &&
          Object.prototype.hasOwnProperty.call(fieldConfig, "maximum")
        ) {
          // single value is in range?
          if (!thisIsNumeric) {
            const result = {
              success: false,
              field: fieldConfig.field,
              labelId: "validate.valueNumeric",
            };
            displayErrorsIfRequested(result);

            return result;
          }

          if (
            (!numberValue && numberValue !== 0) ||
            !inRange(
              numberValue,
              displayToField(fieldConfig.minimum, fieldConfig),
              displayToField(fieldConfig.maximum, fieldConfig)
            )
          ) {
            const result = {
              success: false,
              field: fieldConfig.field,
              labelId: "validate.valuesOutsideBounds",
              messageVals: {
                minimum: fieldConfig.minimum,
                maximum: fieldConfig.maximum,
              },
            };
            displayErrorsIfRequested(result);

            return result;
          }
        }

        if (
          Object.prototype.hasOwnProperty.call(fieldConfig, "minimum") &&
          !Object.prototype.hasOwnProperty.call(fieldConfig, "maximum")
        ) {
          if (
            (!numberValue && numberValue !== 0) ||
            !min(numberValue, displayToField(fieldConfig.minimum, fieldConfig))
          ) {
            const result = {
              success: false,
              field: fieldConfig.field,
              labelId: "validate.valueMinimum",
            };
            displayErrorsIfRequested(result);

            return result;
          }
        }

        if (
          !Object.prototype.hasOwnProperty.call(fieldConfig, "minimum") &&
          Object.prototype.hasOwnProperty.call(fieldConfig, "maximum")
        ) {
          if (
            (!numberValue && numberValue !== 0) ||
            max(numberValue, displayToField(fieldConfig.maximum, fieldConfig))
          ) {
            const result = {
              success: false,
              field: fieldConfig.field,
              labelId: "validate.valueMaximum",
            };
            displayErrorsIfRequested(result);

            return result;
          }
        }
      }
    }
  }

  const validationResult = fieldConfig.helper || {
    success: listErrors.length === 0,
    errors: listErrors,
  }; // return whatever the field had before in the helper or empty
  displayErrorsIfRequested(validationResult);

  return validationResult;
};

export {
  regExpLib,
  min,
  max,
  inCollection,
  inRange,
  isValidRange,
  regExp,
  validateField,
};
