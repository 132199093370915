import fieldIntl, { getSectionProps } from './fieldIntl';

const SECTION = 'placeholder';
const props = getSectionProps(SECTION);

/**
 * Helper function to extract placeholder out of field-config
 * by either having specified one of these
 * two properties in the `fieldConfig` object:
 * - placeholder
 * - placeholderId
 * @param {Object} intl
 * @param {{placeholder?: string, placeholderId?: string}} fieldConfig
 * @returns {string|undefined}
 */
const fieldPlaceholder = (intl, fieldConfig) =>
  Object.prototype.hasOwnProperty.call(fieldConfig, props[0]) ||
  Object.prototype.hasOwnProperty.call(fieldConfig, props[1])
    ? fieldIntl(intl, fieldConfig, SECTION)
    : undefined;

export default fieldPlaceholder;
