import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { tokenFormat } from '../../__utility';

const useStyles = makeStyles({
  shandle: {
    'zIndex': 200,
    'position': 'absolute',
    'cursor': 'pointer',
    'backgroundColor': '#0d3958',
    'boxSizing': 'border-box',
    'boxShadow':
      '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
    '&:hover': {
      boxShadow: '0px 0px 0px 8px rgba(13, 57, 88, 0.16)',
    },
  },

  slabel: {
    position: 'absolute',
    zIndex: -20,
    top: ' 50%',
    left: 0,
    right: 0,
    marginTop: '-0.5em',
    fontSize: '0.85rem',
    textAlign: 'center',
  },

  sbox: {
    position: 'absolute',
    zIndex: -20,
    left: 0,
    top: 0,
  },
});

interface Props {
  type: number;
  minValue: number;
  maxValue: number;
  fromValue: number;
  toValue: number;
  step: number;
  disabled: boolean;
  labelFormatter?: string;
  size?: number;
  onChange: (values: number | number[]) => void;
}

const CircularSlider: React.FC<Props> = ({
  type,
  minValue,
  maxValue = 360,
  fromValue,
  toValue,
  step = 1,
  disabled,
  labelFormatter,
  size = 200,
  onChange,
}) => {
  const [from, setFrom] = useState(fromValue);
  const [to, setTo] = useState(toValue);
  const [handleFromLeft, setHandleFromLeft] = useState<number>();
  const [handleFromTop, setHandleFromTop] = useState<number>();
  const [handleToLeft, setHandleToLeft] = useState<number>();
  const [handleToTop, setHandleToTop] = useState<number>();
  const [isDragging, setIsDragging] = useState(false);
  const [currentHandle, setCurrentHandle] = useState<string>();
  const [lastDeg, setLastDeg] = useState<number>();
  const [unrestrictedMove, setUnrestrictedMove] = useState<boolean>();
  const [newDrag, setNewDrag] = useState<boolean>();
  const canvasRef = React.createRef<any>();
  const containerRef = React.createRef<any>();

  const border = 10;
  const handleSize = 30;
  const classes = useStyles();

  if (labelFormatter == null) {
    if (type === 1) {
      labelFormatter = '{0} °';
    } else if (type === 2) {
      labelFormatter = '{0} – {1} °';
    }
  }

  useEffect(() => {
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Emit onChange
  useEffect(() => {
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to]);

  useEffect(() => {
    setFrom(fromValue);
    setTo(toValue);
  }, [fromValue, toValue]);

  const update = () => {
    if (from != null) {
      updateWidget();
    }
  };

  const move = (e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isDragging) {
      let handleId = currentHandle;
      const target = e.currentTarget as HTMLDivElement;
      const boundingRect = target.getBoundingClientRect();
      // if (e.offsetX != null) {
      const x = (e as any).clientX - boundingRect.x - border;
      const y = (e as any).clientY - boundingRect.y - border;
      const radius = (size - border) / 2;
      const atan = Math.atan2(x - radius, y - radius);
      const deg = -atan / (Math.PI / 180) + 180;
      const clockwise = deg > lastDeg;

      if (type === 2 && newDrag && from >= to - 5 && from < to + 5) {
        // console.log('within similar');
        if (clockwise) {
          handleId = 'handleTo';
        } else {
          handleId = 'handleFrom';
        }

        setCurrentHandle(handleId);
        setUnrestrictedMove(true);
      } else {
        setUnrestrictedMove(false);
        setNewDrag(false);
      }

      let value = Math.round(deg);

      // Basic min maxs
      if (value < minValue) {
        value = minValue;
      } else if (value > maxValue) {
        value = maxValue;
      }

      // Snap value to nearest
      value = Math.round(value / step) * step;

      // Ensure we restrict if we come close to either side give or take 1 deg
      if (type === 2 && !unrestrictedMove && (from === to - 1 || from === to + 1)) {
        setIsDragging(false);
        setCurrentHandle(null);
        setUnrestrictedMove(true);

        return;
      }

      // console.log(`${from} - ${to}`);
      if (handleId === 'handleFrom') {
        setFrom(value);
      } else {
        // Required to ensure to size can reach 360
        if (deg === 0) {
          value = 360;
        }

        setTo(value);
      }

      updateWidget();
      setLastDeg(deg);
    }
  };

  const drawArc = (degreeStart, degreeEnd) => {
    const back = '#E1E4E9';
    const backDisabled = '#F3F4F6';
    const front = '#0D3958';
    const frontDisabled = '#999999';
    const canvas = canvasRef.current;
    const radius = (size - border - handleSize / 2) / 2;

    canvas.width = size;
    canvas.height = size;

    const context = canvas.getContext('2d');

    context.clearRect(0, 0, canvas.width, canvas.height);

    // Back
    context.beginPath();

    context.arc(canvas.width / 2, canvas.width / 2, radius, 0, 2 * Math.PI, false);

    context.lineWidth = border;
    context.strokeStyle = disabled ? backDisabled : back;
    context.stroke();

    // Selection
    if (type === 2) {
      context.beginPath();

      context.arc(
        canvas.width / 2,
        canvas.width / 2,
        radius,
        degreesToRadians(degreeStart) - Math.PI / 2,
        degreesToRadians(degreeEnd) - Math.PI / 2,
        false,
      );

      context.lineWidth = border;
      context.strokeStyle = disabled ? frontDisabled : front;
      context.stroke();
    }

    if (degreeStart === degreeEnd) {
      context.beginPath();
      const x = Math.cos(degreesToRadians(degreeEnd - 90)) * (canvas.width / 2 - border / 2) + canvas.width / 2;
      const y = Math.sin(degreesToRadians(degreeEnd - 90)) * (canvas.width / 2 - border / 2) + canvas.width / 2;

      context.arc(x, y, 10, 0, 2 * Math.PI, false);
      context.fillStyle = front;
      context.fill();
      context.lineWidth = 1;
      context.strokeStyle = disabled ? frontDisabled : front;
      context.stroke();
    }
  };

  const degreesToRadians = (degrees) => degrees * (Math.PI / 180);

  const placeHandle = (handle, degrees) => {
    const x = Math.cos(degreesToRadians(degrees - 90)) * (size / 2 - handleSize / 2) + size / 2 - handleSize / 2;
    const y = Math.sin(degreesToRadians(degrees - 90)) * (size / 2 - handleSize / 2) + size / 2 - handleSize / 2;

    if (handle === 'handleFrom') {
      setHandleFromLeft(x);
      setHandleFromTop(y);
    } else if (handle === 'handleTo') {
      setHandleToLeft(x);
      setHandleToTop(y);
    }
  };

  const updateWidget = () => {
    placeHandle('handleFrom', from);
    placeHandle('handleTo', to);
    drawArc(from, to);
  };

  const onMouseDown = (e) => {
    if (!disabled) {
      setIsDragging(true);
      setNewDrag(true);
      setCurrentHandle(e.target.id);
      e.preventDefault();
    }
  };

  const onMouseUp = (e) => {
    setIsDragging(false);
    setCurrentHandle(null);

    if (type === 1) {
      onChange(from);
    } else if (type === 2) {
      onChange([from, to]);
    }
  };

  return (
    <div
      id="container"
      ref={containerRef}
      style={{ position: 'relative', width: size, height: size }}
      role="tab"
      tabIndex={0}
      onMouseMove={move}
      onTouchMove={move}
      onMouseUp={onMouseUp}
      onTouchEnd={onMouseUp}
    >
      <div>
        <canvas ref={canvasRef}></canvas>
      </div>
      <div style={{ position: 'absolute', zIndex: 200, top: 0, left: 0 }}>
        <div
          id="handleFrom"
          className={classes.shandle}
          role="tab"
          aria-label="handleFrom"
          tabIndex={0}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onTouchStart={onMouseDown}
          onTouchEnd={onMouseUp}
          style={{
            width: handleSize,
            height: handleSize,
            top: handleFromTop,
            left: handleFromLeft,
            borderRadius: '50%',
          }}
        ></div>
        {type === 2 && (
          <div
            id="handleTo"
            className={classes.shandle}
            role="tab"
            aria-label="handleFrom"
            tabIndex={-1}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onTouchStart={onMouseDown}
            onTouchEnd={onMouseUp}
            style={{
              width: handleSize,
              height: handleSize,
              top: handleToTop,
              left: handleToLeft,
              borderRadius: '50%',
            }}
          ></div>
        )}
        <div id="box" style={{ width: size, height: size }} className={classes.sbox}>
          <div className={classes.slabel}>
            <span>{tokenFormat(labelFormatter, from, to)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CircularSlider;
