import { ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import { translationMessages } from './i18n';
import DHITheme from './theme';
import './whyDidYouRender';
import reportWebVitals from './reportWebVitals';

const render = (messages: any) => {
  ReactDOM.render(
    <React.StrictMode>
      <ThemeProvider theme={DHITheme}>
        <App messages={messages} />
      </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
};

if (window.Intl) {
  render(translationMessages);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
