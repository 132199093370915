import { makeObservable, observable } from 'mobx';
import { RootStore } from './RootStore';

export class TransitStore {
  private allStores: RootStore;

  transitData: [Date, number][];

  TransitDataSources = {
    connection: '',
    host: process.env.REACT_APP_API_ENDPOINT_URL,
    ids: [
      // Filled via configs
    ],
  };

  constructor(allStores: RootStore) {
    this.allStores = allStores;

    makeObservable(this, {
      transitData: observable,
    });
  }
}
