import React, { Children } from 'react';
import { IntlProvider } from 'react-intl';
import { LanguageProviderProps } from './types';

const LanguageProvider: React.FC<LanguageProviderProps> = ({ locale, messages, children }: LanguageProviderProps) => {
  console.log(`Locale switching: ${locale}`);

  return (
    <IntlProvider locale={locale} key={locale} messages={messages[locale]}>
      {Children.only(children)}
    </IntlProvider>
  );
};

export default LanguageProvider;
