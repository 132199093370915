import { makeObservable, observable } from 'mobx';
import { SarCandidateContainer } from '../modules/SAR/__models/SarCandidate';
import { RootStore } from './RootStore';

export abstract class CandidateStore {
  initialScenarioDataState = {
    added: '',
    dateTime: '',
    fullName: '',
    data: {},
    permissions: [],
  } as SarCandidateContainer;

  scenarioData = this.initialScenarioDataState;
  originalScenarioData: SarCandidateContainer;

  constructor(protected allStores: RootStore) {
    makeObservable(this, {
      scenarioData: observable,
      originalScenarioData: observable,
    });
  }
}
