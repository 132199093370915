import AuthService from '@dhi/react-components';
import React, { createContext } from 'react';
import { useStores } from '../../hooks/useStores';
import LoginPage from '../../login';
import { UserProviderProps } from './types';

const UserContext = createContext(null);
const { Provider, Consumer } = UserContext;
const authService = new AuthService(process.env.REACT_APP_API_ENDPOINT_URL);

const UserProvider: React.FC<UserProviderProps> = ({ intl, children }: UserProviderProps) => {
  const session = authService.getSession();
  const { appStateStore } = useStores();

  const handleLogin = (user) => {
    const loginSession = authService.getSession();
    const customerCode = user != null ? user.metadata.clientId : null;

    if (customerCode) {
      appStateStore.setupSession(user, loginSession.accessToken, loginSession.refreshToken, customerCode, intl);
      window.history.replaceState('', module.id, '/');
    }
  };

  const handleLogout = () => {
    appStateStore.logout(intl);
  };

  // Setup session
  if (session.user != null && appStateStore.session.token.accessToken !== session.accessToken) {
    appStateStore.setupSession(
      session.user,
      session.accessToken,
      session.refreshToken,
      session.user != null ? session.user.metadata.clientId : null,
    );
  }

  return (
    <Provider
      value={{
        onLogout: handleLogout,
      }}
    >
      {/* TODO: check if the accessToken is valid, not expired, etc... */}
      {authService.isAuthenticated() ? children : <LoginPage onLogin={handleLogin}>{children}</LoginPage>}
    </Provider>
  );
};

export { UserProvider, Consumer as UserConsumer };
export default UserContext;
