import React from 'react';
import { storesContext, useStores } from '../hooks/useStores';

const { Provider, Consumer } = storesContext;

const StoreProvider = ({ children }: { children?: React.ReactNode }) => {
  const {
    appStateStore,
    vesselScenarioStore,
    portScenarioStore,
    portStore,
    yardSafeStore,
    transitStore,
    configStore,
    themeStore,
    sarCandidateStore,
    sarDriftStore,
    sarStateStore,
    psAppStateStore,
    portSustainabilityStore,
    dataPortalStore,
  } = useStores();

  return (
    <Provider
      value={{
        appStateStore,
        vesselScenarioStore,
        portScenarioStore,
        portStore,
        yardSafeStore,
        transitStore,
        configStore,
        themeStore,
        sarCandidateStore,
        sarDriftStore,
        sarStateStore,
        psAppStateStore,
        portSustainabilityStore,
        dataPortalStore,
      }}
    >
      {children}
    </Provider>
  );
};

export { StoreProvider, Consumer as StoreConsumer };
