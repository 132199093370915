import React from 'react';
import PropTypes from 'prop-types';
import { RenderComponents } from '../../helpers/lazyRender';

// TODO: here I may need to load data
// use this config: `componentConfig` etc...
const BottomPanel = ({ className, /*  componentConfig, */ componentStyles, inheritedStyles, components }) => (
  <div className={className || null} style={componentStyles || inheritedStyles || null}>
    {components && <RenderComponents components={components} />}
  </div>
);

BottomPanel.propTypes = {
  className: PropTypes.string,
  // componentConfig: PropTypes.object,
  componentStyles: PropTypes.object,
  inheritedStyles: PropTypes.object,
  components: PropTypes.array,
};

export default BottomPanel;
