// this function returns a section of the json configuration given by a path / id...
const getConfigSection = (config, id) => {
  let objFound = null;

  const findId = (_config, _id) => {
    if (!_config) {
      return {};
    }

    if (!objFound) {
      if (_config.id && _config.id === _id) {
        objFound = _config;
      }

      if (!objFound) {
        if (typeof _config === "object") {
          const keys = Object.keys(_config);

          if (keys.length) {
            return keys.forEach((x) => {
              if (!objFound) {
                findId(_config[x], _id);
              }
            });
          }
        }
      }
    }
  };

  findId(config, id);

  return objFound;
};

export default getConfigSection;
