import PropTypes from 'prop-types';
import React from 'react';
import { RenderComponents } from '../../helpers/lazyRender';

// TODO: here I may need to load data, etc...
// use this config: `componentConfig`
const MainPanel = ({ className, /* componentConfig, */ componentStyles, inheritedStyles, components }) => (
  <div className={className || null} style={componentStyles || inheritedStyles || null}>
    <div>this is main panel</div>
    {components && <RenderComponents components={components} />}
  </div>
);

MainPanel.propTypes = {
  className: PropTypes.string,
  // componentConfig: PropTypes.object,
  componentStyles: PropTypes.object,
  inheritedStyles: PropTypes.object,
  components: PropTypes.array,
};

export default MainPanel;
