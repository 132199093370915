import { FormControl, IconButton, Input, InputAdornment, InputLabel } from '@material-ui/core';
import { AttachFile } from '@material-ui/icons';
import React from 'react';

const InputClip = ({ id, label, value }) => (
  <FormControl style={{ flex: 1 }}>
    <InputLabel htmlFor={id}>{label}</InputLabel>
    <Input
      id={id}
      value={value}
      inputProps={{
        readOnly: true,
      }}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="Copy ScenarioId to Clipboard"
            onClick={() => {
              const eventIdToCopy = document.getElementById(id);

              (eventIdToCopy as any).select();
              document.execCommand('copy');
            }}
          >
            <AttachFile />
          </IconButton>
        </InputAdornment>
      }
      style={{ fontSize: 13 }}
    />
  </FormControl>
);

export default InputClip;
