import { createStyles, makeStyles, Theme } from "@material-ui/core";

const defaultPageStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: "flex",
      flexDirection: "row",
      flex: 1,
      height: "100%",
    },
    main: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: "calc(100vw - 400px)",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    aside: {
      display: "flex",
      position: "relative",
      width: 0,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    sidePanelWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: 52,
    },
    bottomPanel: {
      backgroundColor: "#FFF",
      height: 0,
      transition: theme.transitions.create("height", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    hiddenBottom: {
      gridTemplateRows: "calc(100%) !important", //  - 2px) 2px
    },
    mainShift: {
      // width: 'calc(100% - 400px)',
      width: "100%",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    asideShift: {
      width: 400,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    siltationAsideShift: {
      width: 555,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    bottomShift: {
      height: ({ bottomPanelHeight }: { bottomPanelHeight?: number }) =>
        bottomPanelHeight,
      transition: theme.transitions.create("height", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  })
);

export { defaultPageStyles };
