export enum VesselType {
  Unknown = 0,
  ContainerVessel = 1,
  Tanker = 2,
  BulkCarrier = 3,
  RoRo = 4,
  CruiseLiner = 5,
  GeneralCargo = 6,
  TugBarge = 7,
  GasCarrier = 8,
}

export enum PrescreenIndicator {
  NotSafe = 'NotSafe',
  Warning = 'Warning',
  Safe = 'Safe',
}

export enum PrescreenColour {
  NotSafe = '#FFCDD2',
  Warning = '#FFE0B2',
  Safe = '#DCEDC8',
}

export enum LineValidityType {
  Valid = 0,
  BollardCapacityExceeded = 1,
  FairleadCapacityExceeded = 1,
  NoBollardsAvailable = 2,
  HorizontalAngleViolated = 3,
  VerticalAngleViolated = 4,
  TooShort = 5,
  TooLong = 6,
  CrossingDeck = 7,
  VerticallyTooClose = 8,
  ConstantTensionWinchBlockCapacity = 9,
}

export type ModuleName = 'MooringAnalysis' | 'ClimateChange' | 'YardSafe' | 'SAR';

/**
 * Get ordinal index position of an Enum member in an Enum.
 * @param enumType Enum
 * @param value Enum member phrase to find index on
 */
export const getEnumIndex = (enumType: any, value: any) => Object.keys(enumType).indexOf(value);
