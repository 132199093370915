import { format } from 'date-fns';

const makeInitDate = (): string => {
    const initDate = new Date();

    return format(
        new Date(
            initDate.getUTCFullYear(),
            initDate.getUTCMonth(),
            initDate.getUTCDate(),
            initDate.getUTCHours(),
            initDate.getUTCMinutes(),
            initDate.getUTCSeconds()),
        'yyyyMMddHHmmss');
};

export default makeInitDate;
