const PROP_ID_SUFFIX = 'Id';
const getSectionProps = (section) => [section, `${section}${PROP_ID_SUFFIX}`];

/**
 * Base-helper function to extract
 * (translated) texts out of field-config
 * by either having specified either a property
 * in the `fieldConfig` object with the same name
 * as `section` or `section` suffixed with `Id`. E.g.:
 * - label
 * - labelId
 * Example
 * ```
 * fieldIntl(intl, {label: 'My Label'}, 'label'); // "My Label"
 * fieldIntl(intl, {labelId: 'a'}, 'label'); // translated string by id `a`
 * ```
 * @param {Object} intl
 * @param {Object} fieldConfig
 * @param {string} section
 * @returns {string}
 * @throws {TypeError} when section-props not present
 */
const fieldIntl = (intl, fieldConfig, section) => {
  const [sectionProp, sectionIntlprop] = getSectionProps(section);

  // return section-prop value
  // since it overrides translatable section-intl-prop

  if (Object.prototype.hasOwnProperty.call(fieldConfig, sectionProp)) {
    return fieldConfig[sectionProp];
  }

  // return translated text by section-intl-prop
  if (Object.prototype.hasOwnProperty.call(fieldConfig, sectionIntlprop)) {
    return intl.formatMessage({
      id: fieldConfig[sectionIntlprop],
      defaultMessage: fieldConfig[sectionIntlprop],
    });
  }

  // don't know what prop to work with...
  throw new TypeError();
};

export default fieldIntl;
export { getSectionProps };
