import { getDraftToUse, largeRound } from "..";

/**
 * Calculate the freeboard by using certain vessel details. Freeboard: is a derived parameter that is used for berth prescreening and for calculation of wind area and fairlead z-position
 * @param {scenarioData} scenarioData is the object with all the data (data property has all the details)
 */
const calculateFreeboard = (scenarioData) => {
  const draftToUse = getDraftToUse(scenarioData);
  const freeboard = largeRound(
    (scenarioData.vessel.mouldedDepth || 0) - draftToUse
  ); // there may be no mouldedDepth as per yet...

  return freeboard;
};

export default calculateFreeboard;
