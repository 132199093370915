import AuthService, { Login } from '@dhi/react-components';
import { Card, CardContent, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { LoginLogo, SeaportLogo } from './components/logos';
import { useStores } from './hooks/useStores';

const host = process.env.REACT_APP_API_AUTH_URL;
const authService = new AuthService(host);

/**
 * This component serves to gate any wrapped components in a login form.
 *
 * @example <LoginPage>You are logged in if you see this.</LoginPage>
 */
interface Props {
  children: any;
  onLogin: (user: any) => void;
}

const LoginPage: React.FC<Props> = ({ children, onLogin }) => {
  const { appStateStore } = useStores();
  const isLoggedIn = authService.isAuthenticated();
  let resetPasswordToken = new URLSearchParams(window.location.search).get('token');

  useEffect(() => {
    appStateStore.editMode = false;
    appStateStore.bottomPanelShowing = false;
  }, []);

  const onLoginSuccess = (loggedInUser, token) => {
    console.log('successful login. User: ', loggedInUser);
    onLogin(loggedInUser);
  };

  const onLoginError = () => {
    console.log('login error.');
  };
  // <NCOSLogo />
  // <SeaportLogo />

  return isLoggedIn ? (
    children
  ) : (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '90vh' }}
    >
      <Grid item xs={12} md={4} style={{ width: 600 }}>
        <Card>
          <CardContent style={{ padding: 40 }}>
            <Grid
              container
              spacing={0}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              style={{ paddingBottom: 20 }}
            >
              <LoginLogo />
              <SeaportLogo />
            </Grid>
            <Login
              host={host}
              showRememberMe
              onSuccess={onLoginSuccess}
              onError={onLoginError}
              textFieldVariant="standard"
              translations={{
                loginButton: 'Login',
                userNamePlaceholder: 'Username',
                passwordPlaceholder: 'Password',
                rememberMeLabel: 'Remember me?',
                resetPasswordLabel: 'Forgot Password',
                resetPasswordButton: 'Forgot Password',
                updatePasswordEmailPlaceholder: 'Email Address',
                updatePasswordNewPasswordPlaceholder: 'New Password',
                updatePasswordConfirmPasswordPlaceholder: 'Confirm Password',
              }}
              showResetPassword={true}
              resetPasswordMailTemplate={'NCOS'}
              showUpdatePassword={!!resetPasswordToken}
              resetPasswordToken={resetPasswordToken}
              onBackToLogin={() => {
                document.location.href = '/';
              }}
            />
            <div style={{ clear: 'both' }}></div>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
