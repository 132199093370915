import { IconLayer } from '@deck.gl/layers';
import { MIKE_COLORS } from '@dhi/react-components';
import { colorToRGB } from 'src/components/SharedLibrary';

const getIconLayer = (info) => {
  const color = colorToRGB(MIKE_COLORS.BRANDBLUE_DEFAULT);

  return new IconLayer({
    id: 'IconLayer',
    data: [info],
    getColor: (d) => [color.r, color.g, color.b],
    getIcon: (d) => 'marker',
    getPosition: (d: any) => d.coordinate,
    getSize: (d) => 5,
    iconAtlas: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png',
    iconMapping: {
      marker: {
        x: 0,
        y: 0,
        width: 128,
        height: 128,
        anchorY: 128,
        mask: true,
      },
    },
    sizeScale: 8,
    pickable: true,
  });
};

export default getIconLayer;
