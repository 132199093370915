import React, { createContext, useState } from "react";
import {
  initialScenarioEditorState,
  IScenarioEditor,
  ScenarioEditorProviderProps,
} from "./types";

const ScenarioEditorContext = createContext<IStore>(null);
const { Provider, Consumer } = ScenarioEditorContext;

interface IStore {
  scenarioEditor: IScenarioEditor;
  setScenarioEditor: React.Dispatch<React.SetStateAction<IScenarioEditor>>;
}

const ScenarioEditorProvider: React.FC<ScenarioEditorProviderProps> = ({
  children,
}: ScenarioEditorProviderProps) => {
  const [scenarioEditor, setScenarioEditor] = useState(
    initialScenarioEditorState
  );

  return (
    <Provider value={{ scenarioEditor, setScenarioEditor } as IStore}>
      {children}
    </Provider>
  );
};

export { ScenarioEditorProvider, Consumer as ScenarioEditorConsumer };
export default ScenarioEditorContext;
