import { createTheme } from "@material-ui/core";

// color vars
export const COLOR_BLUE_DHI = "#0d3958";
export const COLOR_BLUE_DHI_2 = "#0eafd5";
export const COLOR_BLUE_DHI_2_FADED = "#0eafd569";
export const COLOR_BLUE_DHI_LIGHT = "#e6f5ff";
export const COLOR_BLUE_DHI_2_ALT = "#77c3d5";
export const COLOR_BLUE_DHI_3 = "#3d667c";
export const COLOR_BLUE_DHI_3_ALT = "#77a7c0";
export const COLOR_YELLOW_600 = "#fedf5c";
export const COLOR_ORANGE_DARK = "#C25615";
export const COLOR_GREY_LIGHT = "#c9c9c9";
export const COLOR_GREY_EXTRA_LIGHT = "#f9f9f9";
export const COLOR_ORANGE_LIGHT = "#FA975C";

export const COLOR_WHITE = "#ffffff";
const COLOR_BLACK = "#000000";
const COLOR_GREY_PLAIN = "#a0a0a0";
export const COLOR_GREY_LIGHT_SNOW = "#e8e8e8";
const COLOR_GREEN_PLAIN = "#00e676";
const COLOR_MAGENTA_PLAIN = "#FF00FF";
const COLOR_CYAN = "#42f5ec";
const LIGHT_THEME_DEFAULT_BG_COLOR = COLOR_WHITE;
const COLOR_WARNING = "#ff7600";
// const COLOR_GREY_DARK_NIGHT = "#000000de";
// const COLOR_MAGENTA_PLAIN = '#fc00fc';

export const COLOR_BLUE_MOORING_LINE = COLOR_BLUE_DHI;

// color aliases
// light theme
// const LIGHT_THEME_DEFAULT_FONT_COLOR = COLOR_BLACK;
// dark theme
// const DARK_THEME_FONT_COLOR = COLOR_WHITE;
// const DARK_THEME_BG_COLOR = COLOR_GREY_DARK_NIGHT;

// DHI-Theme
const DHITheme = createTheme({
  palette: {
    dhiColor: COLOR_BLUE_DHI,
    primary: {
      main: COLOR_BLUE_DHI,
    },
    secondary: {
      main: COLOR_GREY_LIGHT,
      font: COLOR_WHITE,
    },
    shadow: {
      main: COLOR_GREY_LIGHT,
    },
    mapRed: COLOR_ORANGE_DARK,
    mapBlack: COLOR_BLACK,
    mapGreen: COLOR_GREEN_PLAIN,
    mapMagenta: COLOR_MAGENTA_PLAIN,
    mapGrey: COLOR_GREY_PLAIN,
    mapCyan: COLOR_CYAN,
    mapYellow: COLOR_YELLOW_600,
    colorWarning: COLOR_WARNING,
  },
  typography: {
    useNextVariants: true,
  },
  drawerWidth: 320,
  toolbarHeight: 64,
  map: {
    wrapper: {
      height: "calc(100vh - 64px)", // screen height - toolbarHeight
    },
  },
  slideup: {
    height: "500px",
  },
  overrides: {
    MuiIconButton: {
      root: {
        color: COLOR_BLUE_DHI,
      },
    },
    MuiSnackbar: {
      root: {
        minHeight: "auto !important",
        height: "auto !important",
        zIndex: 999,
        marginBottom: "12px",
      },
    },
    MuiAlert: {
      filledWarning: {
        // color: '#dc6300',
        backgroundColor: "#ff7600",
        border: "solid 2px #ff7600",
        // color: '#332600',
        // backgroundColor: '#ef6c00',
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: LIGHT_THEME_DEFAULT_BG_COLOR,
        color: COLOR_BLUE_DHI,
      },
    },
    MuiTabs: {
      root: {
        // marginLeft: 2, // muiBaseTheme.spacing.unit,
        backgroundColor: LIGHT_THEME_DEFAULT_BG_COLOR,
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        backgroundColor: COLOR_BLUE_DHI, // muiBaseTheme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        margin: `10px`,
        minWidth: 0,
        "@media(min-width: 960px)": { minWidth: 0 },
      },
    },
    MuiTableRow: {
      root: {
        backgroundColor: LIGHT_THEME_DEFAULT_BG_COLOR,
        "&$hover": {
          cursor: "pointer",
        },
        "&$selected": {
          backgroundColor: `${COLOR_GREY_LIGHT_SNOW}!important`,
        },
      },
    },
    MuiTableCell: {
      root: {
        "&.MuiTableCell": {
          padding: "0 14px 0 14px",
          height: 70,
        },
        "&.MuiTableCell-sizeSmall": {},
      },
    },
    MuiFormHelperText: {
      root: {
        marginRight: 8,
      },
    },
    MuiMenuItem: {
      root: {
        "&.Mui-disabled": {
          opacity: "0.8 !important",
          cursor: "not-allowed !important",
        },
      },
    },
  },
  MuiMenu: {
    root: {
      "&.paper": {
        transitionDuration: "0s !important",
      },
    },
  },
} as any);

export default DHITheme;
