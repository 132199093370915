import { GraphQLClient } from "graphql-request";
import { FleetManagerVessel } from "../__models";
import { isNumeric, VesselType } from "../__utility";

export default class GraphQLService {
  public client: GraphQLClient;
  private dbPrefix: string;

  constructor(accessToken: string, dbPrefix: string) {
    this.dbPrefix = dbPrefix;
    this.client = new GraphQLClient(
      "https://fleetmanager.seaportopx.com/v1/graphql",
      {
        headers: {
          "X-Hasura-Token": "7f84f112-b33a-4cd1-b178-512d11967e89",
          "X-Hasura-Bearer": accessToken,
        },
      }
    );
  }

  /**
   * Query list of vessels by imo or name.
   * @param imo
   * @returns
   */
  public queryVessels = async (
    searchQuery: string
  ): Promise<FleetManagerVessel> => {
    let whereClause = ``;

    const isImo = isNumeric(searchQuery);
    const availableVesselType = [
      VesselType.ContainerVessel,
      VesselType.Tanker,
      VesselType.BulkCarrier,
      VesselType.Roro,
    ];

    if (isImo) {
      whereClause = `imo: { _ilike: "%${searchQuery.trim()}%" }`;
    } else {
      whereClause = `vesselName: { _ilike: "%${searchQuery.trim()}%" }`;
    }

    const query = `{
            ${this.dbPrefix}vessel(distinct_on: vesselName, where: { ${whereClause}, vesselType: {_in: [${availableVesselType}]}}, order_by: {vesselName: asc}, limit: 25) {
              imo,
              vesselName,
              vesselType
            }
          }`;

    // To view auto-complete query:
    // console.log(query);

    return await this.client.request(query).then((data: any) => {
      return data;
    });
  };

  /**
   * Query vessel by imo.
   * @param imo
   * @returns
   */
  public queryVesselByImo = async (
    imo: string
  ): Promise<FleetManagerVessel> => {
    if (!imo) {
      return;
    }

    const query = `{
              ${this.dbPrefix}vessel(where: {imo: {_eq: "${imo}"} }, order_by: {ownerid: desc}, limit: 1) {
                imo,
                vesselName,
                vesselType,
                particulars,
                mooring
              }
            }`;

    // To view auto-complete query:
    // console.log(query);

    return await this.client.request(query).then((data: any) => {
      const vessel = data[`${this.dbPrefix}vessel`][0];
      console.log(`Querying ${vessel.vesselName}`, data);
      return vessel as FleetManagerVessel;
    });
  };
}
