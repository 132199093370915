import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { useStores } from '../../hooks/useStores';

export const AlertDialog = observer(() => {
  const { appStateStore } = useStores();

  const handleClose = () => {
    appStateStore.setAlertDialogVisibility({
      open: false,
    });
  };

  return appStateStore.alertDialogParams && appStateStore.alertDialogParams.open ? (
    <Dialog
      open={appStateStore.alertDialogParams.open}
      onClose={handleClose}
      maxWidth={appStateStore.alertDialogParams.maxWidth}
    >
      <DialogTitle id="alert-dialog-title">{appStateStore.alertDialogParams.title}</DialogTitle>
      <DialogContent dividers={appStateStore.alertDialogParams.scroll}>
        {typeof appStateStore.alertDialogParams.message === 'string' ? (
          <DialogContentText id="alert-dialog-description">
            <div style={{ marginBottom: 20 }}>{appStateStore.alertDialogParams.message}</div>
            {appStateStore.alertDialogParams.details && (
              <div style={{ color: '#CCC', fontSize: '0.9em' }}>{appStateStore.alertDialogParams.details}</div>
            )}
          </DialogContentText>
        ) : (
          appStateStore.alertDialogParams.message
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  ) : (
    <></>
  );
});
