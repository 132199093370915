const buildMarkLine = (
  date: string,
  formatText: string,
  color: string,
  lineType: "solid" | "dashed" | "dotted",
  fontWeight: "normal" | "bold"
) => {
  return {
    xAxis: date,
    lineStyle: {
      normal: {
        type: lineType,
        color: color,
        width: 2,
      },
    },
    label: {
      fontSize: 12,
      fontWeight: fontWeight,
      formatter: (f) => {
        return formatText;
      },
    },
  };
};
export default buildMarkLine;
