import { action, makeObservable, observable } from 'mobx';
import { RootStore } from './RootStore';

export class ThemeStore {
  theme = 'light';

  constructor(private allStores: RootStore) {
    makeObservable(this, {
      theme: observable,
      setTheme: action.bound,
    });
  }

  setTheme(newTheme: string) {
    this.theme = newTheme;
  }
}
