const getDataFieldValue = (data, field) => {
  const fieldNodes = field.split('.');
  const fieldName = field.substr(field.lastIndexOf('.') + 1);
  let finalObject = data;

  for (let i = 0; i < fieldNodes.length - 1; i++) {
    finalObject = finalObject[fieldNodes[i]];
  }

  return finalObject[fieldName];
};

export default getDataFieldValue;
