import { validateToken } from '@dhi/react-components';
import { withStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import React, { Suspense, useEffect } from 'react';
import { createIntl, createIntlCache } from 'react-intl';
import { BrowserRouter, Switch } from 'react-router-dom';
import { componentStyles } from './components/defaultStyles';
import { AppTopBar } from './components/layout';
import Loading from './components/Loading';
import { redirectHomeIfRequired } from './helpers/common';
import { useStores } from './hooks/useStores';
import { LanguageProvider } from './providers/LanguageProvider';
import { StoreProvider } from './providers/StoreProvider';
import { UserConsumer, UserProvider } from './providers/UserProvider';
import { Routes } from './routes';

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  // toolbar: theme.mixins.toolbar,
  title: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
});

const App = observer((props: any) => {
  const { messages, classes } = props;
  const { appStateStore, configStore } = useStores();
  const cache = createIntlCache();
  let intl;
  // activate styles
  componentStyles({});

  // TODO Need to ensure App reloads even when we aren't printing variables here
  // console.log(`configStore.customerCode: ${configStore.customerCode}`);
  // console.log(`appStateStore.session.customerCode: ${appStateStore.session.customerCode}`);

  // By virtue of monitoring `hasConfig` below this triggers App.tsx to observe via App=observer() hook
  // causing appropriate reloads.

  if (configStore.hasConfig) {
    // Redirect to correct landing page if hitting root
    if (document.location.pathname === '/') {
      redirectHomeIfRequired(appStateStore, configStore.appConfig);
    }

    intl = createIntl(
      {
        locale: configStore.appConfig.applicationSettings.language,
        messages,
      },
      cache,
    );
  }

  const doLogout = () => {
    appStateStore.logout(intl);
    window.location.reload();
  };

  useEffect(() => {
    if (appStateStore.session.token.accessToken) {
      console.log('Verifying token validity...');

      // Check token is still valid
      validateToken(process.env.REACT_APP_API_AUTH_URL, appStateStore.session.token.accessToken)
        .then((res) => {
          if (!res.ok) {
            doLogout();
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          doLogout();
        });
    }
  }, []);

  return (
    <UserProvider intl={intl}>
      <UserConsumer>
        {() => (
          <StoreProvider>
            {!configStore.hasConfig ? (
              <Loading />
            ) : (
              <LanguageProvider locale={configStore.appConfig.applicationSettings.language} messages={messages}>
                <BrowserRouter>
                  <div className={classes.root}>
                    <AppTopBar appConfig={configStore.appConfig} />
                    <main className={classes.content}>
                      <Suspense fallback={<Loading />}>
                        <Switch>
                          <Routes appConfig={configStore.appConfig} />
                        </Switch>
                      </Suspense>
                    </main>
                  </div>
                </BrowserRouter>
              </LanguageProvider>
            )}
          </StoreProvider>
        )}
      </UserConsumer>
    </UserProvider>
  );
});

export default withStyles(styles, { withTheme: true })(App);
