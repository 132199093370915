const imageAssets = {
  APMTTM2: '/assets/img/apm_terminals.jpg',
  APMTPier400: '/assets/img/apm_terminals.jpg',
  PusanNewPort: '/assets/img/PusanNewPort.jpg',
};

const findImageByCustomerCode = (customerCode) => {
  if (imageAssets[customerCode]) {
    return imageAssets[customerCode];
  } else {
    return null; // No matching image found
  }
};

const ClientLogo = ({ customerCode }) => {
  const imageSource = findImageByCustomerCode(customerCode);

  if (!imageSource) {
    return null;
  }

  return (
    <div>
      <img src={imageSource} alt={customerCode} />
    </div>
  );
};

export default ClientLogo;
