import yellow from '@material-ui/core/colors/yellow';
import { format, parseISO } from 'date-fns';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { utcToTz } from '../helpers/common';
import getConfigSection from '../helpers/getConfigSection';
import { CONTAINER_STATUS, TIMESERIES_DATE_FORMAT } from '../modules/YardSafe/yardSafeConstants';
import { RootStore } from './RootStore';

export class YardSafeStore {
  private allStores: RootStore;

  yardData: GeoJSON.Feature[];
  initialYardDataLoaded: Date;
  observedWindSpeedData: [Date, number][];
  observedWindDirectionData: [Date, number][];
  forecastWindSpeedData: [Date, number][];
  forecastWindSpeedDataSelectedPeriod: [Date, number][];
  forecastWindDirectionData: [Date, number, number, string][];
  selectedContainerSlotInfo: { slot: ''; status: '' };
  selectedDate: { date: string; peakWindSpeed: number };
  selectedDateModified: Date;
  criticalDatesUtc: string[];
  warningDatesUtc: string[];
  criticalEventDate: Date;
  dataOutOfDate: boolean;
  demoMode: boolean = false;
  outOfBoundsStacks: number;
  toggleWindUnit: boolean = false;

  constructor(allStores: RootStore) {
    this.allStores = allStores;

    // Initialize toggleWindUnit from localStorage or default to false
    this.toggleWindUnit = localStorage.getItem('toggleWindUnit') === 'true' ? true : false;

    makeObservable(this, {
      yardData: observable,
      initialYardDataLoaded: observable,
      observedWindSpeedData: observable,
      observedWindDirectionData: observable,
      forecastWindSpeedData: observable,
      forecastWindSpeedDataSelectedPeriod: observable,
      forecastWindDirectionData: observable,
      selectedContainerSlotInfo: observable,
      selectedDate: observable,
      selectedDateModified: observable,
      criticalDatesUtc: observable,
      warningDatesUtc: observable,
      criticalEventDate: observable,
      dataOutOfDate: observable,
      outOfBoundsStacks: observable,
      demoMode: observable,
      toggleWindUnit: observable,
      loadYard: action.bound,
      setDemoMode: action.bound,
      setSelectedDate: action.bound,
      setSelectedDateModified: action.bound,
      setDataOutOfDate: action.bound,
      setOutOfBoundsStacks: action.bound,
      setToggleWindUnit: action.bound,
    });
  }

  get sidePanelConfig() {
    const originalConfig = getConfigSection(this.allStores.configStore.appConfig, 'YardSafeSidePanel');

    // Create a copy of the original config needed for the toggleWindUnit feature
    const config = JSON.parse(JSON.stringify(originalConfig));
  
    // Special case for APMTPier400
    // If toggleWindUnit then modify the config to use m/s
    if (this.toggleWindUnit) {
      config.config.unit = "m/s";
      config.config.multiplier = 1;
      config.config.windSpeedLevels = [0, 5, 10, 15, 20, 25]; 
      config.config.windSpeedLabels = ["0m/s", "5m/s", "10m/s", "15m/s", "20m/s", "25m/s"]; 
    }
  
    return config;
  }

  get windTimeSeriesConfig() {
    const originalConfig = getConfigSection(this.allStores.configStore.appConfig, 'yardSafeBottomPanel');

    // Create a copy of the original config needed for the toggleWindUnit feature
    const config = JSON.parse(JSON.stringify(originalConfig));
  
    // Special case for APMTPier400
    // If toggleWindUnit then modify the config to use m/s
    if (this.toggleWindUnit) {
       config.config.windSpeedLevels = {
        level1: 13.41, // 30 mph to m/s
        level2: 17.88, // 40 mph to m/s
        level3: 20.12  // 45 mph to m/s
      };
    }
  
    return config;
  }

  setSelectedDate = (selectedDate: { date: string; peakWindSpeed: number }) => {
    this.selectedDate = selectedDate;
  };

  setSelectedDateModified = (selectedDateModified: Date) => {
    this.selectedDateModified = selectedDateModified;
  };

  setDataOutOfDate = (dataOutOfDate: boolean) => {
    this.dataOutOfDate = dataOutOfDate;
  };

  setDemoMode = (demoMode: boolean) => {
    this.demoMode = demoMode;
  };

  setToggleWindUnit = (toggleWindUnit: boolean) => {
    this.toggleWindUnit = toggleWindUnit;

     // Save the new state to localStorage
     localStorage.setItem('toggleWindUnit', this.toggleWindUnit.toString());
  };

  setOutOfBoundsStacks = (outOfBoundsStacks: number) => {
    this.outOfBoundsStacks = outOfBoundsStacks;
  };

  loadYard = (successCallback: (result: GeoJSON.Feature[]) => void = null, debugHours = 0) => {
    const {
      config: { mapConfig },
    } = getConfigSection(this.allStores.configStore.appConfig, 'yardSafeMap');

    const safeStackPlanUrl = `${process.env.REACT_APP_API_ENDPOINT_URL}/api/yardsafe/safestackplan${
      this.demoMode ? '?mock=true' : ''
    }`;
    fetch(safeStackPlanUrl, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.allStores.appStateStore.session.token.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((result: GeoJSON.FeatureCollection) => {
        runInAction(() => {
          console.log(
            `%cRebuilding yard${debugHours !== 0 ? `, ${debugHours}hrs` : ''} ...`,
            `font-weight:bold;background:${yellow[800]};`,
          );

          const { features } = result;

          this.warningDatesUtc = [
            ...new Set(features.flatMap((m) => m.properties.warnings).filter((item) => item !== null)),
          ].sort((a, b) => new Date(a as string).getTime() - new Date(b as string).getTime());

          this.criticalDatesUtc = [
            ...new Set(
              features.flatMap((feature, featureIndex) => {
                const indexFeature = featureIndex;
                return feature.properties.criticals.filter((critical, index) => {
                  if (
                    critical !== null &&
                    features[indexFeature].properties.overallStatus[index] !== CONTAINER_STATUS.CONDITIONAL
                  )
                    return true;

                  return false;
                });
              }),
            ),
          ].sort((a, b) => new Date(a as string).getTime() - new Date(b as string).getTime());

          const firstCriticalDate = this.criticalDatesUtc[0];

          if (firstCriticalDate) {
            const closestCriticalEventAsLocal = parseISO(`${firstCriticalDate}Z`);

            console.log(
              `closestCriticalEvent (zoned time) ${format(
                utcToTz(closestCriticalEventAsLocal, this.allStores.portStore.portData.timeZone),
                TIMESERIES_DATE_FORMAT,
              )}`,
            );

            this.criticalEventDate = closestCriticalEventAsLocal;
          } else {
            this.criticalEventDate = null;
          }

          this.yardData = features;

          if (!this.initialYardDataLoaded) this.initialYardDataLoaded = new Date();
        });
      })
      .finally(() => {
        if (successCallback) successCallback(this.yardData);
        console.log('fetching safestackplan data complete');
      });
  };
}
