import { GraphQLClient } from 'graphql-request';
import { isNumeric } from '../helpers/common';
import { VesselType } from '../helpers/enums';
import { FleetManagerVessel, HistoricalVesselDataDTO } from '../interfaces/FleetManagerVessel';

export default class GraphQLService {
  public client: GraphQLClient;

  constructor(accessToken: string) {
    this.client = new GraphQLClient(`${process.env.REACT_APP_FLEETMANAGER_URL}/v1/graphql`, {
      headers: {
        'X-Hasura-Token': '7f84f112-b33a-4cd1-b178-512d11967e89',
        'X-Hasura-Bearer': accessToken,
      },
    });
  }

  /**
   * Query list of vessels by imo or name.
   * @param imo
   * @returns
   */
  public queryVessels = async (searchQuery: string): Promise<FleetManagerVessel[]> => {
    let whereClause = ``;

    const isImo = isNumeric(searchQuery);

    // TODO: Should these allowed vessel types come from a user specific config?
    let availableVesselTypes = [
      VesselType.ContainerVessel,
      VesselType.Tanker,
      VesselType.BulkCarrier,
      VesselType.RoRo,
      VesselType.CruiseLiner,
      VesselType.GasCarrier,
    ];

    if (isImo) {
      whereClause = `imo: { _ilike: "%${searchQuery.trim()}%" }`;
    } else {
      whereClause = `vesselName: { _ilike: "%${searchQuery.trim()}%" }`;
    }

    const query = `{
            vesseldata_vessel(distinct_on: imo, where: { ${whereClause}, vesselType: {_in: [${availableVesselTypes}]}}, order_by: [{imo: asc}, {vesselName: asc} {ownerid: desc}], limit: 25) {
              imo,
              vesselName,
              vesselType,
              ownerid
            }
          }`;

    // To view auto-complete query:
    // console.log(query);

    return await this.client.request(query).then((data: any) => {
      return data[`vesseldata_vessel`];
    });
  };

  /**
   * Query vessel by imo.
   * @param imo
   * @returns
   */
  public queryVesselByImo = async (imo: string): Promise<FleetManagerVessel> => {
    if (!imo) {
      return;
    }

    const query = `{
            vesseldata_vessel(where: {imo: {_eq: "${imo}"} }, order_by: {ownerid: desc}, limit: 1) {
                imo
                vesselName
                vesselType
                particulars
                mooring
                loading
                ownerid
                lastupdated
              }
            }`;

    // To view auto-complete query:
    //console.log(query);

    return await this.client.request(query).then((data: any) => {
      const vessel = data[`vesseldata_vessel`][0];
      console.log(`Querying ${vessel.vesselName}`, data);
      return vessel as FleetManagerVessel;
    });
  };

  public queryNearestVessel = async (params: {
    lpp: number | string;
    loa: number | string;
    beam: number | string;
    vesselType: number | string;
    summerDraft: number | string;
  }): Promise<FleetManagerVessel[]> => {
    if (!params) return;

    const query = `
      {
        vesseldata_similar_vessels(args: {
          ${params.loa ? `search_loa: ${params.loa},` : ''}
          ${params.beam ? `search_beam: ${params.beam},` : ''}
          ${params.lpp ? `search_lpp: ${params.lpp},` : ''}
          ${params.summerDraft ? `search_summer_draft: ${params.summerDraft},` : ''}
          ${params.vesselType ? `search_type: ${params.vesselType},` : null}
        }, limit: 10) {
            id
            imo
            vesselName
            vesselType
            particulars
            mooring
        }
      }
    `;

    // TODO: GQL typing on this query response:
    return await this.client.request(query).then((data: any) => {
      const vessels = data[`vesseldata_similar_vessels`] as FleetManagerVessel[];
      console.debug({ vesseldata_similar_vessels: vessels });
      return vessels;
    });
  };

  /**
   * Query list of vessels by imo or name.
   * @param imo
   * @returns
   */
  public queryVesselsHistoricalData = async (imo: string): Promise<HistoricalVesselDataDTO> => {
    if (!imo) return;

    const query = `{
      vesseldata_vessel_history(where: {debug: {_eq: true}, dataschematype: {_eq: "MA"}, imo: {_eq: "${imo}"}}, order_by: {id: desc}) {
        scenarioid
        draftMin: data(path: "draftmin")
        draftMax: data(path: "draftmax")
        draftFore: data(path: "draftfore")
        draftMid: data(path: "draftmid")
        draftAft: data(path: "draftaft")
        bowMarker: data(path: "bowmarker")
        sternMarker: data(path: "sternmarker")
        mooringArrangementName: data(path: "mooringarrangementname")
        berthName: data(path: "berthname")
        terminalName: data(path: "terminalname")
        numberOfContainerTiers: data(path: "numberofcontainertiers")
        startTime: data(path: "starttime")
        endTime: data(path: "endtime")
      }
    }`;

    return await this.client.request(query).then((data) => {
      return data;
    });
  };
}
