import { fetchUrl } from '@dhi/react-components';
import jp from 'jsonpath';
import { filter, find, findIndex, isEmpty, mergeWith } from 'lodash';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { getConfigSection } from '../helpers';
import { isArray } from '../helpers/arrays';
import { Config } from '../interfaces/Config';
import { RootStore } from './RootStore';

export class ConfigStore {
  private allStores: RootStore;

  appConfig: Config = null;

  customerCode: string;

  hasConfig = false;

  dataSources = {
    host: process.env.REACT_APP_API_ENDPOINT_URL,
    ids: [
      // Filled via configs
    ],
    from: null,
    to: null,
  };

  jsonDocumentsDataSources = {
    channelPlanner: {
      ...this.dataSources,
      connection: 'MarineAid-Data',
    },
    sustainability: {
      ...this.dataSources,
      connection: 'MarineAid-JsonDocuments-Sustainability-[customerCode]',
    },
    survey: {
      ...this.dataSources,
      connection: 'MarineAid-JsonDocuments-BlobNado-Survey-[customerCode]',
    },
    dredge: {
      ...this.dataSources,
      connection: 'MarineAid-JsonDocuments-BlobNado-Dredge-[customerCode]',
    },
    jobs: {
      ...this.dataSources,
      connection: 'MarineAid-Jobs',
    },
  };

  timeseriesDataSources = [
    {
      connection: 'MarineAid-Data',
      host: process.env.REACT_APP_API_ENDPOINT_URL,
      ids: [
        // Filled via configs
      ],
      from: null,
      to: null,
    },
  ];

  shapeFileDataSources = [
    {
      connection: 'MarineAid-Climate-ShapeFiles',
      host: process.env.REACT_APP_API_ENDPOINT_URL,
      ids: [
        // Filled via configs
      ],
      from: null,
      to: null,
    },
  ];

  yardSafeDataSources = [
    {
      connection: 'MarineAid-SafeStackPlan',
      host: process.env.REACT_APP_API_ENDPOINT_URL,
      ids: [
        // Filled via configs
      ],
    },
  ];

  constructor(allStores: RootStore) {
    makeObservable(this, {
      customerCode: observable,
      hasConfig: observable,
      fetchConfigData: action.bound,
    });

    this.allStores = allStores;
  }

  fetchConfigData = (customerCode, onConfigFetched: (config: any) => void) => {
    this.customerCode = customerCode;

    if (!customerCode) {
      console.log(`Resetting config.`);
      this.appConfig = null;
      this.hasConfig = false;

      return;
    }

    console.log(`Fetching config for ${customerCode}`);
    const cbString = Math.round(new Date().getTime() / 1000);

    fetchUrl(`${process.env.REACT_APP_CONFIG_FILE_URL}?cacheBust=${cbString}`, null).subscribe((baseConfig) => {
      // eslint-disable-next-line no-prototype-builtins
      if (process.env.REACT_APP_CUSTOMER_CONFIG_URL && customerCode != null) {
        fetchUrl(
          `${process.env.REACT_APP_CUSTOMER_CONFIG_URL.replace('{customer}', customerCode)}?cacheBust=${cbString}`,
          null,
        ).subscribe((configChanges) => {
          // Recursive iteration for applying overrides
          const applyOverride = (changes: any, scope: string = null) => {
            // merge configs
            changes.forEach((change) => {
              let baseSection = baseConfig;

              // there are three ways of getting to a particular branch:
              // by the id, by the jsonpath or by both,
              // first id and then the path apply against the subsection got by the id
              // scope can be applied in order to traverse nested nodes.

              // SCOPE GOVERNS ALL
              if (scope || change.scope) {
                try {
                  const node = jp.nodes(baseSection, scope ?? change.scope);
                  baseSection = node[0].value;
                } catch (err) {
                  console.error(err);
                  console.warn('Check scope: ', scope || change.scope);
                }
              }
              if (change.id) {
                try {
                  baseSection = getConfigSection(baseSection, change.id);
                } catch (err) {
                  console.log(err);
                  console.warn('Check config id: ', change.id);
                }
              }
              if (change.path) {
                try {
                  const node = jp.nodes(baseSection, change.path);
                  baseSection = node[0].value;
                } catch (err) {
                  console.error(err);
                  console.warn('Check config path: ', change.path);
                }
              }

              if (baseSection && !isEmpty(baseSection)) {
                if (change.overrides) {
                  try {
                    change.overrides.forEach((overrideChange) => {
                      Object.assign(baseSection, overrideChange);
                    });
                  } catch (err) {
                    console.error(err);
                    console.warn('Check change overrides: ', change.overrides);
                  }
                }

                if (change.removes) {
                  try {
                    change.removes.forEach((removeChange) => {
                      delete baseSection[removeChange];
                    });
                  } catch (err) {
                    console.error(err);
                    console.warn('Check change removes: ', change.removes);
                  }
                }

                if (change.extends) {
                  change.extends.forEach((extendChange) => {
                    // We use mergewith instead of merge because of the posibility of some properties to be arrays
                    // eslint-disable-next-line consistent-return
                    mergeWith(baseSection, extendChange, (baseValue, extendingValue) => {
                      // On each occurance, if this item is an array,
                      // Merge the arrays if similar can be found by an ID match,
                      // Otherwise simply return the new replaecValue array as normal.
                      if (isArray(extendingValue)) {
                        let mergeArray = extendingValue.map((item) => {
                          if (item.id !== undefined) {
                            const origValue = find(baseValue, { id: item.id });
                            // Merge the matching item on the overriding with the base config

                            return { ...origValue, ...item };
                          }

                          return item;
                        });
                        // Find all base config arrays where they dont' have a matching pair in override
                        // And ensure they also are now added to new merge array.
                        const orphanOriginals = filter(
                          baseValue,
                          (item) => findIndex(mergeArray, { id: item.id }) === -1,
                        );

                        mergeArray = orphanOriginals.concat(mergeArray);

                        return mergeArray;
                      }

                      // No merging array and just a base array? return it.
                      if (isArray(baseValue)) {
                        return baseValue;
                      }
                    });
                  });
                }
              }
              // Iterate child changes
              if (change.changes && !isEmpty(change.changes)) {
                applyOverride(change.changes, change.scope);
              }
            });
          };

          applyOverride(configChanges.changes);

          runInAction(() => {
            this.appConfig = baseConfig;
            this.hasConfig = true;
            if (onConfigFetched) onConfigFetched(baseConfig);
          });
        });
      } else {
        runInAction(() => {
          this.appConfig = baseConfig;
          this.hasConfig = true;
          if (onConfigFetched) onConfigFetched(baseConfig);
        });
      }
    });
  };
}
