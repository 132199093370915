import React from 'react';

// https://html-online.com/editor/
export const ExistingOperatingInstructionsText = () => (
  <div>
    <h2>
      <span style={{ color: '#ff6600' }}>Work Instructions</span>
    </h2>
    <h3>
      <span style={{ color: '#ff9900' }}>
        <strong>Alert Levels and Action Plans</strong>
      </span>
    </h3>
    <h4>
      <strong>Alert level 0 (Calm to 14 m/s)</strong>
    </h4>
    <p>No action is needed, situation is normal</p>
    <h4>
      <strong>Alert level 1 (Winds 14 to 17 m/s)</strong>
    </h4>
    <p>
      No need to take urgent action. Operations are running in a normal way unless weather forecast is predicting
      increase in the wind speed. In this case the EM will take appropriate actions well in advance &amp; inform
      relevant parties (Shift Engineer, HSSE supervisor, Vessel, Line, Port Authority, etc&hellip;). Among these,
      preventive measures will be to reduce the height of the empty containers blocks &amp; towers.
    </p>
    <h4>
      <strong>Alert level 2 (17 to 25 m/s)</strong>
    </h4>
    <ol>
      <li>
        The DSTS cranes which are not working on vessels should be put in storm pins position (See attached document:
        DSTS Anchorage SOP). The cranes which are not operational because they are available for preventive or
        corrective maintenance must be moved and positioned in their positions of storm pins with the assistance of
        M&amp;R personnel. all Portal trolley for the non working DSTS are to be manually moved in between crane legs.
      </li>
    </ol>
    <ol start={2}>
      <li>
        The working cranes will continue operating unless automatically stopped by a sudden gusting wind (Normally DSTS
        should stop at 25 m/s to prevent damages to the spreaders, containers &amp; vessel). In this case, once the wind
        decreases the cranes can resume working (These stoppages due to the wind no matter how shorter should be
        recorded in the DSTS activity sheet &amp; the EM duly informed).
      </li>
    </ol>
    <ol start={3}>
      <li>
        Inside the Yard as per EM decision, the traffic can be partially or fully blocked around empty containers. The
        ARMGs which are not operating should be properly parked (at the end of blocks). The OS should supervise this
        operation to ensure that it is done in a safe way.
      </li>
    </ol>
    <ol start={4}>
      <li>
        Work at height for unlashing/Unlocking twistlocks should be done using the Safety Cage or Gondola. The OS should
        alert Shift Engineer to cancel /postpone working at height (on DSTS &amp; ARMGs) due to the risks of falling
      </li>
    </ol>
    <h4>
      <strong>Alert level 3 (from 25 m/s and up)</strong>
    </h4>
    <ol>
      <li>This is the highest alert level.</li>
    </ol>
    <ol start={2}>
      <li>
        In this case the winds are either steady in speed and direction which do not allow to restart operations or are
        gusty with an average of 25 m/s or more for two consecutive periods of ten minutes been recorded in earlier
        periods of gusts.
      </li>
    </ol>
    <ol start={3}>
      <li>
        All operations at the terminal should be stopped at 25 m/s . The quay cranes which were working on vessels
        should stop working, boom up to 45 degree , Portal trolley in between legs and park in the middle of the ship at
        least 02 bollards away from the bow and the stern of the vessel.
      </li>
    </ol>
    <ol start={4}>
      <li>
        Once the EM activates this alert level he should alert all involved parties within operations including the
        Shift Engineer, HSSE Supervisor, the Vessels, the Port Authorities, Line, etc&hellip;
      </li>
    </ol>
    <ol start={5}>
      <li>
        The AMRGs will be parked outside the blocks ( in the TZ) to avoid the risk of falling containers on ARMGs.
      </li>
    </ol>
    <ol start={6}>
      <li>
        At this level of alert, it is prohibited to refuel equipment as well as driving inside the Yard except near the
        terminal fences.
      </li>
    </ol>
    <ol start={7}>
      <li>
        Lashing equipment should be prepared for eventual quay cranes lashing in case they cannot gantry to their
        anchorage positions.
      </li>
    </ol>
    <ol start={8}>
      <li>
        When Operations are stopped for bad weather, gate clerk to drive the external trucks and instruct them to drive
        out; Gate to be open out.
      </li>
    </ol>
    <ol start={9}>
      <li>Entry to the Terminal for external trucks should be restricted or suspended.</li>
    </ol>
    <ol start={10}>
      <li>
        The Terminal personnel will remain stand by at their posts following instructions received from their superiors
        as per EM instructions.
      </li>
    </ol>
    <ol start={11}>
      <li>
        The personnel working on board vessel(s) should leave the vessel when advise by OS and move to building or
        dedicated safe area
      </li>
    </ol>
    <ol start={12}>
      <li>
        Only OS, LF and mooring men should stay close to the ship inside the dedicated shelters and ready in case a
        vessel requires immediate sailing or mooring reinforcement .
      </li>
    </ol>
    <ol start={13}>
      <li>
        Manoeuvring the cranes booms (Up or down) should be avoided when this level is reached unless supervised by
        M&amp;R team.
      </li>
    </ol>
    <h3>
      <span style={{ color: '#ff9900' }}>
        <strong>Communication Plan for each Alert Level</strong>
      </span>
    </h3>
    <h4>
      <strong>Alert level 0:</strong>
    </h4>
    <p>No need to implement specific communication plan</p>
    <h4>
      <strong>Alert Level 1:</strong>
    </h4>
    <p>
      No need to implement any specific communication. Operations are running in a normal way unless weather forecast is
      predicting increase in the wind speed, in this case OS and Shift Engineer should be informed by EM
    </p>
    <p>
      <strong>Alert Level 2:</strong>
    </p>
    <ol>
      <li>
        For anchoring quay cranes: EM communicates with Shift Engineer. OS &amp; LF will be available to safely execute
        and supervise the job with M&amp;R assistance.
      </li>
    </ol>
    <ol start={2}>
      <li>
        To approach operational cranes close to their anchorage positions: OS (or personnel delegated by him) should
        instruct the LF and RCC to perform the job.
      </li>
    </ol>
    <ol start={3}>
      <li>For working at height on ships: OS should contact the LF to use appropriate tools for their work.</li>
    </ol>
    <ol start={4}>
      <li>
        For working at height for M&amp;R on cranes or ARMGs: EM should contact the Shift Engineer to alert him about
        the wind speed &amp; risks of working at height. This information will be cascaded by the Supervisor to his
        staff.
      </li>
    </ol>
    <ol start={5}>
      <li>
        For stopping traffic inside the Yard: EM will instruct OS and LF to cascade the information to their relevant
        staff
      </li>
    </ol>
    <h4>
      <strong>Alert Level 3:</strong>
    </h4>
    <ol>
      <li>
        For alerting relevant parties about activating level 3: EM should communicate with Shift Engineer (by phone or
        radio), HSSE Supervisor if available (by phone), the Vessels, the Line (via email) &amp; the Port Authority (by
        phone).
      </li>
    </ol>
    <ol start={2}>
      <li>For parking AMRGs outside the blocks: EM should instruct the PCO to execute and OS to supervise this job</li>
    </ol>
    <ol start={3}>
      <li>
        For prohibiting refueling: EM should contact Shift Engineer who will then cascade the information to the fueling
        Man. EM will also instruct the OS about this measure taken.
      </li>
    </ol>
    <ol start={4}>
      <li>
        Restricting or prohibiting the entry into the Terminal for external trucks: EM should contact the GM who will
        cascade the information to GCs. The EM will also inform the OS accordingly.
      </li>
    </ol>
  </div>
);
