import fieldIntl, { getSectionProps } from './fieldIntl';

const SECTION = 'menuCaption';
const props = getSectionProps(SECTION);

/**
 * Helper function to extract menuCaption out of page-config
 * by either having specified one of these
 * two properties in the `fieldConfig` object:
 * - menuCaption
 * - menuCaptionId
 * @param {Object} intl
 * @param {{menuCaption?: string, menuCaptionId?: string}} pageConfig
 * @returns {string}
 */
const pageMenuCaption = (intl, pageConfig) =>
  Object.prototype.hasOwnProperty.call(pageConfig, props[0]) ||
  Object.prototype.hasOwnProperty.call(pageConfig, props[1])
    ? fieldIntl(intl, pageConfig, SECTION)
    : undefined;

export default pageMenuCaption;
