import { useEffect, useState } from 'react';
import NCOSLogo from './NCOSLogo';
import SARLogo from './SARLogo';

const LoginLogo = () => {
  const [isSarLogin, setIsSarLogin] = useState(false);

  useEffect(() => {
    const hostname = document.location.hostname.toLowerCase();
    setIsSarLogin(process.env.REACT_APP_SAR_URL === hostname);
  }, []);

  const logo = isSarLogin ? (
    <div style={{ alignSelf: 'center' }}>
      <SARLogo />
    </div>
  ) : (
    <div style={{ alignSelf: 'center' }}>
      <NCOSLogo />
    </div>
  );

  return logo;
};

export default LoginLogo;
