import { format } from 'date-fns';

const linearInterpolation = (dateTime, list) => {
  return list.map((zone: any) => {
    const formatDate = format(new Date(dateTime), "yyyy-MM-dd'T'00:00:00");
    return {
      ...zone,
      elevations: { [formatDate]: interpolate(dateTime, zone.elevations || {}) },
      designVolumes: { [formatDate]: interpolate(dateTime, zone.designVolumes || {}) },
      insuranceVolumes: { [formatDate]: interpolate(dateTime, zone.insuranceVolumes || {}) },
    };
  });
};

const interpolate = (dateTime, objectList): number | null => {
  const date = new Date(dateTime).getTime();
  const list = Object.keys(objectList).map((key) => [key.split('T')[0], objectList[key]]);

  if (list.length <= 0) return null;
  if (date < new Date(list[0][0]).getTime()) {
    return list[0][1];
  }

  if (date > new Date(list[list.length - 1][0]).getTime()) {
    return list[list.length - 1][1];
  }

  for (let index = 1; index < list.length; index++) {
    const before = new Date(list[index - 1][0]).getTime();
    const after = new Date(list[index][0]).getTime();

    if (before <= date && date <= after) {
      const value = list[index - 1][1] + ((date - before) / (after - before)) * (list[index][1] - list[index - 1][1]);

      return value;
    }
  }
};

export default linearInterpolation;
