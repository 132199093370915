import {
  PortScenario,
  PortScenarioEnvironmentalConditions,
  PortScenarioResults,
  PortScenarioTide,
  PortScenarioTributaries,
  PortScenarioWind,
} from '../../interfaces/PortScenario';

const EnviroBlueprint: PortScenarioEnvironmentalConditions = {
  wind: {
    name: null,
    speedValue: null,
    calculatedSpeedValue: null,
    directionList: [],
    averageRecurrenceInterval: null,
    windSpeedIncrease: null,
    windSpeedIncreaseUnit: null,
  } as PortScenarioWind,
  tide: {
    name: null,
    slrYears: [],
    slrWeighting: 0,
    slr: 0,
  } as PortScenarioTide,
  tributaries: {
    name: null,
    averageRecurrenceInterval: null,
    rainfallAmplifier: null,
    dischargeStations: [],
    dischargeValues: [],
    calculatedDischargeValues: [],
  } as PortScenarioTributaries,
};

const PortScenarioBlueprint: PortScenario = {
  name: '',
  debug: false,
  version: null,
  projectionYear: null,
  portDatumOffset: 0,
  environmentalConditions: EnviroBlueprint,
  results: {} as PortScenarioResults,
};

export default PortScenarioBlueprint;
