import { addHours, format, parseISO } from 'date-fns';
import { format as formatDateFns, toDate, utcToZonedTime } from 'date-fns-tz';
import { round } from 'lodash';
import { Config } from '../interfaces/Config';
import { AppStateStore } from '../stores';
import { MA_ROUND_DECIMALS } from './constants';

// ///////////////////////////
// Common methods
// ///////////////////////////

const redirectHomeIfRequired = (appStateStore: AppStateStore, appConfig: Config) => {
  // Redirect to default route
  if (appStateStore.session.user && appStateStore.session.user.metadata && appStateStore.authorisedModules) {
    appStateStore.authorisedModules.forEach((module) => {
      module.pages.forEach((page) => {
        if (page.default) {
          window.history.replaceState('', module.id, `${module.route}${page.route}`);
        }
      });
    });
  }
};

/**
 * Determines whether value is broadly speaking, a number.
 * @param value The value.
 */
const isNumeric = (value: any): boolean => {
  if (typeof value === 'boolean') {
    return false;
  }

  return value != null && value.toString().length > 0 && !Number.isNaN(Number(value));
};

/**
 * Round a number off to remove noise
 * @param value The value.
 */
const largeRound = (value: number): number => {
  return round(value, 8);
};

/**
 * Round a number suitable for "Mooring Analysis" related values (line lengths, line angles)
 * @param value
 * @returns
 */
const maRound = (value: number): number => {
  return round(value, MA_ROUND_DECIMALS);
};

/**
 * Determines whether an object is Object type (eg. JSON object vs standard primitive)
 * @param v A property name.
 */
const isObjectType = (v: any): boolean => typeof v === 'object';

/**
 * Returns a replaced string via token replacements denoted by _{n}_.
 *
 * Eg. `value="test {0} - {1} string"` `args=hello,world`
 * Replaces to `"test hello - world string"`.
 * @param value String containing one more tokens eg. {0}, {1}..
 * @param args Argument list of tokens to replace
 */
const tokenFormat = (value: string, ...args) => {
  return value.replace(/{(\d+)}/g, function (match, number) {
    return typeof args[number] !== 'undefined' ? args[number] : match;
  });
};

/**
 * Returns a parsed and formatted date string given requested format from ISO date-formatted string.
 * @param isoDate ISO date in string format.
 * @param formatString format string compatible with date-fns eg. `yyyy-MM-dd HH:mm:ss`.
 * @see https://date-fns.org/docs/format
 */
export const formatFromISO = (isoDate: string, formatString = 'yyyy-MM-dd HH:mm:ss') => {
  return format(parseISO(isoDate), formatString);
};

/**
 * This is the current UTC time possibly offset by a number of hours. The returned string is without time zone
 * @param offsetHours An optional number of hours to offset the time
 */
const utcNowString = (offsetHours?: number | null) =>
  formatDateFns(
    addHours(utcToZonedTime(new Date(), 'UTC'), offsetHours == null ? 0 : offsetHours),
    'yyyy-MM-dd HH:mm:ss',
  );

/**
 * This is the current UTC time rounded to nearest minute possibly offset by a number of hours. The returned string is without time zone
 * @param offsetHours An optional number of hours to offset the time
 */
const utcNowRoundedString = (minutesToRoundTo: number, offsetHours?: number | null) => {
  let currentTime = utcToZonedTime(new Date(), 'UTC');
  currentTime.setMinutes(Math.round(currentTime.getMinutes() / minutesToRoundTo) * minutesToRoundTo);
  currentTime.setSeconds(0);
  return formatDateFns(addHours(currentTime, offsetHours == null ? 0 : offsetHours), 'yyyy-MM-dd HH:mm:ss');
};
/**
 * This is the current UTC timee.
 */
const utcNow = () => {
  return utcToZonedTime(new Date(), 'UTC');
};

/**
 * This converts the date provided to a specific IANA time zone into `string` friendly format.
 * It does this by assuming date is UTC via "wiping" any timezone data from it via "Z", and then converting to the desired timezone.
 * @param date The UTC date to convert. No time zone provided
 * @param timeZone The time zone to convert it to
 */
const utcToTzString = (date: string | Date, timeZone: string) => {
  if (typeof date === 'string') {
    return date
      ? formatDateFns(utcToZonedTime(`${date}Z`, timeZone), 'yyyy-MM-dd HH:mm:ss')
      : formatDateFns(utcToZonedTime(`${utcNowString()}Z`, timeZone), 'yyyy-MM-dd HH:mm:ss');
  } else {
    return date
      ? formatDateFns(utcToZonedTime(date, timeZone), 'yyyy-MM-dd HH:mm:ss')
      : formatDateFns(utcToZonedTime(utcNowString(), timeZone), 'yyyy-MM-dd HH:mm:ss');
  }
};

/**
 * This converts the date provided to a specific IANA time zone into `Date` format.
 * It does this by assuming date is UTC via "wiping" any timezone data from it via "Z", and then converting to the desired timezone.
 * @param date The UTC date to convert. No time zone provided
 * @param timeZone The time zone to convert it to
 */
const utcToTz = (date: string | Date, timeZone: string) => {
  if (typeof date === 'string') return utcToZonedTime(`${date}Z`, timeZone);

  return utcToZonedTime(date, timeZone);
};

/**
 * This converts the date provided in a specific IANA time zone to UTC
 * @param date The date in a time zone to convert. No time zone provided
 * @param timeZone The time zone its in
 */
const tzToUtc = (date: string | Date, timeZone: string) =>
  date
    ? formatDateFns(utcToZonedTime(toDate(date, { timeZone }), 'UTC'), 'yyyy-MM-dd HH:mm:ss')
    : formatDateFns(utcToZonedTime(toDate(utcNowString(), { timeZone }), 'UTC'), 'yyyy-MM-dd HH:mm:ss');

/**
 * Format currency using the Internationalization API.
 * https://stackoverflow.com/a/16233919
 */
const formatCurrency = (amount: number, currency = 'AUD') => {
  try {
    // Create our number formatter.
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
    });

    return formatter.format(amount).replace(/\D00(?=\D*$)/, ''); /* eg. $2,500.00 */
  } catch (e) {
    console.log(e);

    return '$ Error';
  }
};

export const lerp = (start, end, pos) => {
  return (end - start) * pos + start;
};

/**
 * Add spaces to a string
 * @param string
 * @returns string with space before a Capital letter
 * e.g.:  ROROCargo = RORO Cargo and BulkCarrier = Bulk Carrier
 */
const insertSpaces = (string) => {
  string = string.replace(/([a-z])([A-Z])/g, '$1 $2');
  string = string.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');

  return string;
};

/**
 * Convert date into timestamp
 * @param date string
 * @returns the timestamp in milliseconds, so we need to divide it by 1000 in order to get the timestamp
 */
const dateToTimestamp = (date) => {
  const datum = Date.parse(date);

  return datum / 1000;
};

const getRoundedDate = (minutes, d = new Date()): Date => {
  const ms = 1000 * 60 * minutes; // convert minutes to ms
  const roundedDate = new Date(Math.round(d.getTime() / ms) * ms);

  return roundedDate;
};

export {
  redirectHomeIfRequired,
  isNumeric,
  isObjectType,
  tokenFormat as format,
  utcNowString,
  utcNow,
  utcToTzString,
  utcToTz,
  tzToUtc,
  largeRound,
  maRound,
  formatCurrency,
  insertSpaces,
  dateToTimestamp,
  getRoundedDate,
  utcNowRoundedString,
};
