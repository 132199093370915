/**
 * This function gets data configuration that defines how many fields are displayed,
 * their default value, some validation, labels, etc...
 * and a field id and returns the config for the given id
 * @param {string} fieldId
 * @param {Array} dataConfig
 * @param {string} fieldName
 * @returns {Object}
 */
const getFieldConfig = (fieldId, dataConfig, fieldName = 'field') => {
  const fieldConfig = dataConfig.find((element) => {
    if (element[fieldName] === fieldId) {
      return true;
    }

    return null;
  });

  if (fieldConfig) {
    return fieldConfig;
  }

  console.warn(`field config not found: ${fieldId} in config.json.`, dataConfig);
};

export default getFieldConfig;
