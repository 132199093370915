import { AisFeatureCollection, Scenario } from '@dhi/react-components';
import { action, makeObservable, observable } from 'mobx';
import buildTokenizedUrl from '../modules/SAR/helpers/buildTokenizedUrl';
import { RootStore } from './RootStore';

export enum SidePanelView {
  Lists,
  CandidateEditor,
  DriftEditor,
  InterceptEditor,
  SearchPatternEditor,
}

export enum EnvironmentalLayer {
  Wind,
  Current,
  Waves,
}

export class SarStateStore {
  private allStores: RootStore;

  // *******************
  // TODO: If adding new observables here, add their reset also to resetAppState()
  sidePanelView: SidePanelView;
  environmentalLayer: EnvironmentalLayer;
  selectedMMSI: number | null;
  activeListTab: string;
  linkToOccurrencePdf: string;
  linkToOccurrenceZip: string;
  interceptPlanVisible: boolean;
  searchPatternName: string | null;
  aisData: AisFeatureCollection = {
    type: 'FeatureCollection',
    features: [],
  };
  candidateData: Scenario[];
  driftScenarioData: Scenario[];
  // *******************

  constructor(allStores: RootStore) {
    this.allStores = allStores;

    makeObservable(this, {
      // *******************
      sidePanelView: observable,
      environmentalLayer: observable,
      selectedMMSI: observable,
      activeListTab: observable,
      interceptPlanVisible: observable,
      searchPatternName: observable,
      aisData: observable,
      candidateData: observable,
      driftScenarioData: observable,
      // *******************

      setSidePanelView: action.bound,
      setEnvironmentalLayer: action.bound,
      setSelectedMMSI: action.bound,
      setActiveListTab: action.bound,
      setInterceptPlanVisible: action.bound,
      setSearchPatternName: action.bound,
      setAisData: action.bound,
      setCandidateData: action.bound,
      setDriftScenarioData: action.bound,
    });

    this.resetSarState();
  }

  setActiveListTab(tabName: string) {
    this.activeListTab = tabName;
  }

  setSidePanelView = (view: SidePanelView) => {
    this.sidePanelView = view;
  };

  setEnvironmentalLayer = (layer: EnvironmentalLayer) => {
    this.environmentalLayer = layer;
  };

  setSelectedMMSI = (mmsi: number | null) => {
    this.selectedMMSI = mmsi;
  };

  setInterceptPlanVisible = (show: boolean) => {
    this.interceptPlanVisible = show;
  };

  setSearchPatternName = (name: string) => {
    this.searchPatternName = name;
  };

  resetSarState = () => {
    this.sidePanelView = SidePanelView.Lists;
    this.selectedMMSI = null;
    this.interceptPlanVisible = false;
    this.searchPatternName = null;
  };

  /**
   * Builds View Occurrence PDF URL
   * @param viewOccurrencePdfUrl
   * @param scenarios
   */
  buildLinkToViewOccurrencePdf(viewOccurrencePdfUrl: string, scenarios: any[]) {
    const { customerCode, token } = this.allStores.appStateStore.session;
    this.linkToOccurrencePdf = buildTokenizedUrl(viewOccurrencePdfUrl, scenarios, customerCode, token.accessToken);
  }

  /**
   * Builds Download Occurrences Zip file
   * @param occurrencesZipUrl
   * @param driftConnection
   * @param scenarios
   */
  buildLinkToDownloadOccurrencesZip(occurrencesZipUrl: string, driftConnection: string, scenarios: any[]) {
    const { customerCode, token } = this.allStores.appStateStore.session;
    this.linkToOccurrenceZip = buildTokenizedUrl(
      occurrencesZipUrl,
      scenarios,
      customerCode,
      token.accessToken,
      driftConnection,
    );
  }

  setAisData(newData: AisFeatureCollection) {
    this.aisData = newData;
  }

  setCandidateData(newData: Scenario[]) {
    this.candidateData = newData;
  }

  setDriftScenarioData(newData: Scenario[]) {
    this.driftScenarioData = newData;
  }
}
