import { makeStyles } from '@material-ui/core/styles';
// these are default styles if nothing is defined otherwise... TODO: override these styles if there are others in config.json
const defaultPageStyles = makeStyles({
  genericMainContainer: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: 'calc(100% - 400px) 400px', // '75% 25%',
    gridTemplateRows: ({ tableHeight }: { tableHeight?: string }) => `calc(100% - ${tableHeight}) ${tableHeight}`, // 'calc(100% - 200px) 200px',
    flex: 1,
    transition: '0.1s all', // NOTE: css grids still don't support transitions...
  },
  hiddenSide: {
    gridTemplateColumns: '100% 400px',
  },
  hiddenBottom: {
    gridTemplateRows: 'calc(100%) !important', //  - 2px) 2px
  },
  mainPanel: {
    backgroundColor: '#88CC88',
  },
  sidePanel: {
    backgroundColor: '#ffbb88',
    gridColumn: '2 / span 1',
    gridRow: '1 / span 2',
  },
  bottomPanel: {
    backgroundColor: '#CC8888',
    gridColumn: '1 / span 1',
  },
});

export { defaultPageStyles };
