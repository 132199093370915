import { Typography } from '@material-ui/core';
import * as React from 'react';
import { PublicLayout } from '../../pageLayouts';

export const NotFoundPage = (() => {
  const _NotFoundPage: React.FC = () => {
    return (
      <PublicLayout>
        <Typography variant="h1" style={{ color: '#456275', marginBottom: 10 }}>
          :(
        </Typography>
        <Typography variant="h4" style={{ color: 'white' }}>
          Module or page not accessible.
        </Typography>
      </PublicLayout>
    );
  };

  return _NotFoundPage;
})();
