import { deleteJsonDocument, postJsonDocuments, updateJsonDocument } from '@dhi/react-components';
import { format } from 'date-fns';
import jp from 'jsonpath';
import { action, makeObservable, override } from 'mobx';
import { IntlShape } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

import {
  ListFieldValue,
  Validator,
  displayToField,
  getConfigSection,
  getFieldConfig,
} from 'src/components/SharedLibrary';
import { SarDriftContainer } from '../modules/SAR/__models/SarDrift';
import setDefaultValues from '../modules/SAR/__utility/setDefaultValues';
import { makeInitDate } from '../modules/SAR/helpers';
import { DriftStore } from './DriftStore';
import { RootStore } from './RootStore';

export class SarDriftStore extends DriftStore {
  initialScenarioDataState = {
    added: '',
    dateTime: '',
    fullName: '',
    data: {
      driftingObject: {},
      initialConditions: {},
      environmentalConditions: {},
      assimilation: {},
      tug: {
        location: {},
        speedFunction: { significantWaveHeight: [], speedMPS: [] },
      },
      aircraft: {
        location: {},
      },
      tugInterceptResults: {
        interceptPath: [],
        vesselDriftIterations: [],
        simulationStatus: {},
      },
      searchPatternResults: {
        searchPattern: [],
        particlesSearched: [],
        simulationStatus: {},
      },
      frontEndData: { interceptVesselBase: '', searchAircraftBase: '' },
      debug: false,
    },
    permissions: [],
  } as SarDriftContainer;

  jsonDocumentsDataSources = [
    {
      connection: 'MarineAid-JsonDocuments-SAR-[customerCode]',
      host: process.env.REACT_APP_API_ENDPOINT_URL,
      ids: [
        // Filled via configs
      ],
      from: null,
      to: null,
    },
  ];

  constructor(allStores: RootStore) {
    super(allStores);
    this.scenarioData = this.initialScenarioDataState;

    makeObservable(this, {
      scenarioData: override,
      resetScenarioDataToDefaults: action.bound,
      createScenarioData: action.bound,
      addScenarioData: action.bound,
      updateScenarioData: action.bound,
      deleteScenarioData: action.bound,
      setScenarioDataField: action.bound,
      setScenarioData: action.bound,
      clearInterceptResults: action.bound,
      clearSearchPatternResults: action.bound,
      handleRemovedFields: action.bound,
    });
  }

  createScenarioData(scenarioContext: SarDriftContainer, freshOpen?: boolean) {
    this.scenarioData = {
      ...scenarioContext,
      fullName: `${makeInitDate()}-${uuidv4()}`,
      permissions: [
        {
          principals: ['Administrators', 'Editors', 'Users'],
          operation: 'read',
        },
        {
          principals: ['Administrators', 'Editors'],
          operation: 'update',
        },
        {
          principals: ['Administrators', 'Editors'],
          operation: 'delete',
        },
      ],
    } as SarDriftContainer;
  }

  addScenarioData(scenarioContext: SarDriftContainer) {
    // limited fields are needed when posting a new json document
    const postScenario = {
      fullName: scenarioContext.fullName,
      data: JSON.stringify(scenarioContext.data),
      permissions: scenarioContext.permissions,
      dateTime: format(new Date(scenarioContext.data.initialConditions.driftStartTime), "yyyy-MM-dd'T'HH:mm:ss"),
    };

    let dataSource = this.jsonDocumentsDataSources[0];
    dataSource.connection = dataSource.connection.replace('[customerCode]', this.allStores.configStore.customerCode);
    postJsonDocuments(dataSource, this.allStores.appStateStore.session.token.accessToken, postScenario);
  }

  updateScenarioData() {
    const scenarioData = this.scenarioData;

    const updateScenario = {
      ...scenarioData,
      data: JSON.stringify(scenarioData.data),
      dateTime: format(new Date(scenarioData.data.initialConditions.driftStartTime), "yyyy-MM-dd'T'HH:mm:ss"),
    };

    let dataSource = this.jsonDocumentsDataSources[0];
    dataSource.connection = dataSource.connection.replace('[customerCode]', this.allStores.configStore.customerCode);

    updateJsonDocument(dataSource, this.allStores.appStateStore.session.token.accessToken, updateScenario);
  }

  deleteScenarioData(scenarioContext: SarDriftContainer) {
    let dataSource = this.jsonDocumentsDataSources[0];
    dataSource.connection = dataSource.connection.replace('[customerCode]', this.allStores.configStore.customerCode);
    deleteJsonDocument(dataSource, this.allStores.appStateStore.session.token.accessToken, scenarioContext.fullName);
  }

  setScenarioData(scenarioContext: SarDriftContainer, freshOpen?: boolean) {
    this.scenarioData = scenarioContext;
    this.handleRemovedFields();
  }

  setScenarioDataField(
    dataConfig: any,
    fieldName: string,
    fieldValue: any,
    intl: IntlShape,
    inServerUnit?: boolean,
    noValidation = false,
  ) {
    const payload: any = { fieldName };
    const fieldConfig = getFieldConfig(fieldName, dataConfig);

    if (fieldConfig) {
      // unit-conversion
      payload.dataValue = inServerUnit === false ? displayToField(fieldValue, fieldConfig) : fieldValue;
    } else {
      // no field-config, so unit-conversion is out of the question
      payload.dataValue = fieldValue;
    }

    // Use jsonpath to apply in a deep path approach
    jp.apply(this.scenarioData.data, `$.${fieldName}`, () => payload.dataValue);
  }

  handleValueChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> | null,
    dataConfig: any,
    newValue?: any,
    fieldName?: string,
    intl?: IntlShape,
    validators?: Validator,
    noValidation = false,
  ) {
    const path = fieldName || (e && e.currentTarget.id);

    if (path) {
      this.setScenarioDataField(dataConfig, path, newValue, intl, false, noValidation);
    } else {
      throw new Error(
        'Missing `path` for `setScenarioDataField` (path can be obtained through `fieldName` or `e` argument)',
      );
    }
  }

  handleValuesChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> | null,
    dataConfig: any,
    listFieldValue: ListFieldValue[],
    intl: IntlShape,
  ) {
    listFieldValue.forEach((itemFieldValue: ListFieldValue) => {
      this.handleValueChange(e, dataConfig, itemFieldValue.newValue, itemFieldValue.fieldName, intl);
    });
  }

  resetScenarioDataToDefaults(intl: any, dataConfig: any) {
    const newContext = this.initialScenarioDataState;
    setDefaultValues(newContext.data, dataConfig, intl);

    // example of specifically setting a default
    const objectTypes = getConfigSection(this.allStores.appStateStore.authorisedModules, 'SARV2').sarConfig.ObjectTypes;
    const defaultObjectType = objectTypes.list[0].name;
    newContext.data.driftingObject.objectTypeName = defaultObjectType;

    // other defaults will be looked up when form loads

    this.createScenarioData(newContext, true);
  }

  clearInterceptResults() {
    const scenarioData = this.scenarioData.data;

    this.scenarioData.data = {
      ...scenarioData,
      tugInterceptResults: {
        interceptPath: [],
        vesselDriftIterations: [],
        bollardPullCapacityExceeded: null,
        simulationStatus: {},
      },
    };
  }

  clearSearchPatternResults() {
    const scenarioData = this.scenarioData.data;

    this.scenarioData.data = {
      ...scenarioData,
      searchPatternResults: {
        searchPattern: [],
        particlesSearched: [],
        simulationStatus: {},
      },
    };
  }

  handleRemovedFields() {
    const aircraftFields = this.scenarioData.data.aircraft;

    if (this.scenarioData.data.aircraft?.overridesEnabled === undefined) {
      this.scenarioData.data.aircraft = {
        ...aircraftFields,
        overridesEnabled: false,
      };
    }

    if (this.scenarioData.data.frontEndData === undefined) {
      this.scenarioData.data.frontEndData = {
        interceptVesselBase: '',
        searchAircraftBase: '',
      };
    }

    const tugFields = this.scenarioData.data.tug;

    if (this.scenarioData.data.tug?.planName === undefined) {
      this.scenarioData.data.tug = {
        ...tugFields,
        planName: '',
      };
    }
  }
}
