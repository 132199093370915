import { makeStyles } from '@material-ui/core/styles';
import { COLOR_BLUE_DHI } from '../theme';

const defaultStyles: any = (theme: any) => ({
  groupContainerColumns: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
  },
  groupContainerRows: {
    'flex': '1',
    'display': 'flex',
    'flexDirection': 'row',
    '& > .MuiFormControl-root:not(only-child)': {
      'marginRight': theme.spacing(),
      '&:last-child': {
        marginRight: 'inherit',
      },
    },
  },
  framedGroup: {
    marginBottom: '10px',
    borderRadius: '6px',
    border: `1px solid ${theme.palette.secondary.main}`,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  formSeparator: {
    marginTop: '20px',
  },
  groupTitle: {
    margin: '5px 0',
    fontWeight: 600,
    flex: '1',
  },
  formField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    flex: '1',
  },
  customSlider: {
    flex: '1',
    width: 'calc(100% - 10px)',
  },
  identGroup: {
    padding: '10px',
  },
  hidden: {
    display: 'none',
  },
  shipIcon: {
    height: '24px',
    width: '24px',
    marginTop: '0px',
    marginRight: '8px',
    float: 'left',
  },
  warningBorder: {
    border: `2px solid ${theme.palette.colorWarning}`,
  },
  alert: {
    'color': theme.palette.colorWarning,
    'padding': '0px 14px',
    'fontWeight': 'bold',
    '& .MuiAlert-icon': {
      color: theme.palette.colorWarning,
    },
  },
  tableCondensed: {
    '& th': {
      padding: '6px 12px 6px 16px',
    },
  },
  disabledNoFrame: {
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'black !important',
    },
    '& .MuiInput-underline.Mui-disabled:before': {
      border: 'none !important',
    },
  },
});

const componentStyles = makeStyles(
  (theme) => ({
    'a, a:hover, a:visited': {
      color: COLOR_BLUE_DHI,
    },
    'root': {
      // use !important so eg .MuiSlider-root.InputField-formField-* won't overrule the margin-top
      'marginTop': `${theme.spacing(5)}px !important`,
      '&.Mui-disabled .MuiSlider-valueLabel > * > *': {
        backgroundColor: 'transparent !important',
      },
    },
    'MuiInput': {
      root: {
        '&.Mui-disabled': {
          border: 'none !important',
        },
      },
    },
    'valueLabel': {
      '& > * > *': {
        // only hacky way to get to: .MuiSlider-valueLabel .PrivateValueLabel-label-17663
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(0.5),
        borderRadius: theme.shape.borderRadius,
      },
      '&:disabled > * > *': {
        backgroundColor: 'transparent',
      },
    },
  }),
  { name: 'MuiSlider' },
);

export default defaultStyles;
export { componentStyles };
