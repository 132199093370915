import { makeObservable, observable } from 'mobx';
import { PortSustainabilityScenarioContainer } from 'src/modules/PortSustainability/__models/PortSustainabilityScenario';
import { RootStore } from './RootStore';

export abstract class PSScenarioStore {
  initialScenarioDataState = {
    added: new Date(),
    dateTime: '',
    fullName: '',
    data: {},
    permissions: [],
  } as PortSustainabilityScenarioContainer;

  scenarioData = this.initialScenarioDataState;
  originalScenarioData: PortSustainabilityScenarioContainer;

  constructor(protected allStores: RootStore) {
    makeObservable(this, {
      scenarioData: observable,
      originalScenarioData: observable,
    });
  }
}
