import { Dictionary } from "./dictionary";

const de: Dictionary = {
  "auth.menu.login": "Anmelden",
  "auth.menu.logout": "Abmelden",
  "auth.menu.profile": "Profil",
  "components.views.startDate": "Startdatum",
  "components.views.endDate": "Enddatum",
  "auth.status.loggingin": "Einloggen",
  "components.scenarioEditor.fields.name": "Szenario-Name",
  "components.scenarioEditor.fields.name.default": "Neues MA Szenario",
  "components.scenarioEditor.fields.name.placeholder": "Szenario-Name",
  "components.cc.scenarioEditor.tabs.wind": "Wind",
  "components.cc.scenarioEditor.tabs.tributaries": "Niederschlag",
  "components.cc.scenarioEditor.tabs.tide": "Meeresspiegelanstieg",
  "components.cc.scenarioEditor.tabs.portAssets": "Hafenanlagen",
  "components.cc.scenarioEditor.fields.name": "Klimaszenario-Name",
  "components.cc.scenarioEditor.fields.name.default": "Neues Szenario",
  "components.cc.scenarioEditor.fields.name.placeholder": "Klimaszenario-Name",
  "components.cc.scenarioEditor.fields.projectionYear": "Projektionsjahr",
  "components.cc.scenarioEditor.fields.environmentalConditions.wind.name":
    "Name Input",
  "components.cc.scenarioEditor.fields.environmentalConditions.wind.directionList":
    "Select Wind Direction Array",
  "components.cc.scenarioEditor.fields.environmentalConditions.wind.averageRecurrenceInterval":
    "Durchschnittl. Wiederkehrintervall [Jahre]",
  "components.cc.scenarioEditor.fields.environmentalConditions.wind.windSpeedIncrease":
    "Windgeschwindigkeitszunahme",
  "components.cc.scenarioEditor.fields.environmentalConditions.wind.windSpeedIncreaseUnit":
    "Einheit",
  "components.cc.scenarioEditor.fields.environmentalConditions.tributaries.name":
    "Name Input",
  "components.cc.scenarioEditor.fields.environmentalConditions.tributaries.averageRecurrenceInterval":
    "Durchschnittl. Wiederkehrintervall [Jahre]",
  "components.cc.scenarioEditor.fields.environmentalConditions.tributaries.dischargeStations":
    "Abflussquellen",
  "components.cc.scenarioEditor.fields.environmentalConditions.tributaries.rainfallAmplifier":
    "NIederschlagsverstärkung [%]",
  "components.cc.scenarioEditor.fields.environmentalConditions.tide.name":
    "Name Input",
  "components.ma.dialogs.customiseLineProperties":
    "Passen Sie die Eigenschaften der Leinen an",
  "components.ma.dialogs.customiseTailProperties":
    "Passen Sie die Eigenschaften der Vorläufer an",
  "components.ma.dialogs.customiseWinchProperties":
    "Passen Sie die Eigenschaften der Winschen an",
  "components.ma.forms.berth.linePretension": "Trossenvorspannung",
  "components.ma.forms.berth.lineRange": "Wertebereich",
  "components.ma.forms.berth.lineValue": "Wert",
  "components.ma.berth.prescrenning.boomTravelBeyondBeam":
    "Maximale Reichweite überschritten.",
  "components.ma.berth.prescrenning.minSpreaderClearance":
    "Maximale Schiffshöhe überschritten.",
  "components.ma.berth.prescrenning.minUKC":
    "Mindestanforderung an Kielfreiheit überschritten.",
  "components.ma.berth.prescrenning.maxBeamClearance":
    "Schiffsbreite überschreitet die zulässige Schiffsbreite am Anleger.",
  "components.ma.berth.prescrenning.maxLOAClearance":
    "Schiffslänge überschreitet die zulässige Schiffslänge am Anleger.",
  "components.ma.forms.environment.current": "Strömung",
  "components.ma.forms.environment.directionFrom": '(Richtung "kommt aus")',
  "components.ma.forms.environment.directionTo": '(Richtung "geht nach")',
  "components.ma.forms.environment.forecast": "Vorhersage",
  "components.ma.forms.environment.range": "Wertebereich",
  "components.ma.forms.environment.tide": "Tidewasserstand",
  "components.ma.forms.environment.value": "Wert",
  "components.ma.forms.environment.wave": "Wellen",
  "components.ma.forms.environment.wind": "Wind",
  "components.ma.forms.lineLengthInput": "Initiale Leinenlänge",
  "components.ma.forms.lineLengthConverged": "Konvergierte Leinenlänge: ",
  "components.ma.forms.mooringLinesBollardCapacityExceeded":
    "Festmacherkapazität von {1} überschritten.",
  "components.ma.forms.mooringLinesFairleadCapacityExceeded":
    "Klüsenkapazität von {1} überschritten.",
  "components.ma.forms.mooringLinesNoBollardsAvailable":
    "Keine Festmacher für Leine {1} geeignet.",
  "components.ma.forms.mooringLinesHorizontalAngleViolated":
    "Der empfohlene horizontale Winkel von {1}° liegt außerhalb des {2}-{3}° Bereichs.",
  "components.ma.forms.mooringLinesVerticalAngleViolated":
    "Der empfohlene vertikale Winkel von {1}° liegt außerhalb des {2}-{3}° Bereichs.",
  "components.ma.forms.mooringLinesTooShort":
    "Die Leinenlänge von {1} ist kleiner als die Mindestlänge von {2}.",
  "components.ma.forms.mooringLinesTooLong":
    "Die Leinenlänge von {1} ist größer als die Maximallänge von {2}.",
  "components.ma.forms.mooringLinesCrossingDeck": "Die Leine kreuzt das Deck.",
  "components.ma.forms.mooringLinesVerticallyTooClose":
    "Die Leine liegt zu nah an einer anderen Leine {1}.",
  "components.ma.forms.mooringProximityError": "Zu nah an anderen Schiffen.",
  "components.ma.forms.scenarioFormBerthReset":
    "Die Liegeplatzauswahl wurde zurückgesetzt, da die Eingabeparameter die Liegeplatzgrenzen überschreiten.",
  "components.ma.forms.scenarioFormEndTimeReset":
    "Die Auswahl der Endzeitdauer wurde aufgrund einer Änderung des Liegeplatzes zurückgesetzt.",
  "components.ma.forms.scenarioFormInvalid":
    "Bitte korrigieren Sie die folgenden Werte, bevor Sie das Szenario speichern:",
  "components.ma.forms.scenarioMissingVessel": "Wählen Sie ein Schiff",
  "components.ma.forms.stats.title": "Statistiken",
  "components.ma.forms.vesselSpecs.draft": "Tiefgang",
  "components.ma.forms.vesselSpecs.draftRange": "Bereich",
  "components.ma.forms.vesselSpecs.draftValue": "Wert",
  "components.ma.forms.vesselspecs.inputClipScenarioId":
    "Szenario-ID (Kopiere ID via Büroklammer)",
  "components.ma.mooringmap.goto": "Gehe zu",
  "components.page.menu.climateChange": "Klimawandel",
  "components.page.menu.mooringAnalysis": "Vertäuberechnungen",
  "components.page.menu.mooringScheduling": "Aktivitätsprotokoll",
  "components.ma.scenarioEditor.fields.environmentalConditions.current.directionBinSize":
    "Sektorgröße Richtung ",
  "components.ma.scenarioEditor.fields.environmentalConditions.current.directionRange":
    "Richtung",
  "components.ma.scenarioEditor.fields.environmentalConditions.current.directionValue":
    "Richtung",
  "components.ma.scenarioEditor.fields.environmentalConditions.current.speedBinSize":
    "Schrittweite Geschwindigkeit",
  "components.ma.scenarioEditor.fields.environmentalConditions.current.speedRange":
    "Geschwindigkeit",
  "components.ma.scenarioEditor.fields.environmentalConditions.current.speedValue":
    "Geschwindigkeit",
  "components.ma.scenarioEditor.fields.environmentalConditions.current.type":
    "Typ",
  "components.ma.scenarioEditor.fields.environmentalConditions.tide.binSize":
    "Schrittweite Tide",
  "components.ma.scenarioEditor.fields.environmentalConditions.tide.range":
    "Tidewasserstand",
  "components.ma.scenarioEditor.fields.environmentalConditions.tide.type":
    "Tidetyp",
  "components.ma.scenarioEditor.fields.environmentalConditions.tide.value":
    "Tide",
  "components.ma.scenarioEditor.fields.environmentalConditions.wave.directionBinSize":
    "Sektorgröße Richtung",
  "components.ma.scenarioEditor.fields.environmentalConditions.wave.directionRange":
    "Wertebereich",
  "components.ma.scenarioEditor.fields.environmentalConditions.wave.directionValue":
    "Wert",
  "components.ma.scenarioEditor.fields.environmentalConditions.wave.directionalStandardDeviationBinSize":
    "Schrittweite Richtung Standardabweichung",
  "components.ma.scenarioEditor.fields.environmentalConditions.wave.heightBinSize":
    "Schrittweite Wellenhöhe",
  "components.ma.scenarioEditor.fields.environmentalConditions.wave.heightRange":
    "Wertebereich",
  "components.ma.scenarioEditor.fields.environmentalConditions.wave.heightValue":
    "Wert",
  "components.ma.scenarioEditor.fields.environmentalConditions.wave.periodBinSize":
    "Schrittweite Periode",
  "components.ma.scenarioEditor.fields.environmentalConditions.wave.periodRange":
    "Wertebereich",
  "components.ma.scenarioEditor.fields.environmentalConditions.wave.periodValue":
    "Wert",
  "components.ma.scenarioEditor.fields.environmentalConditions.wave.type":
    "Wellentyp",
  "components.ma.scenarioEditor.fields.environmentalConditions.wind.directionBinSize":
    "Sektorgröße Windrichtung",
  "components.ma.scenarioEditor.fields.environmentalConditions.wind.directionRange":
    "Richtung",
  "components.ma.scenarioEditor.fields.environmentalConditions.wind.directionValue":
    "Richtung",
  "components.ma.scenarioEditor.fields.environmentalConditions.wind.speedBinSize":
    "Schrittweite Geschwindigkeit",
  "components.ma.scenarioEditor.fields.environmentalConditions.wind.speedRange":
    "Geschwindigkeit",
  "components.ma.scenarioEditor.fields.environmentalConditions.wind.speedValue":
    "Geschwindigkeit",
  "components.ma.scenarioEditor.fields.environmentalConditions.wind.type":
    "Windart",
  "components.ma.scenarioEditor.fields.usesCrossScenarioAwareness":
    "Benachbarte Schiffe einschließen",
  "components.ma.scenarioEditor.fields.mooring.bowMarker": "Bug",
  "components.ma.scenarioEditor.fields.mooring.berthMarkerLabel":
    "Liegeplatzmarkierungen",
  "components.ma.scenarioEditor.fields.mooring.berthMarker": "Brücke",
  "components.ma.scenarioEditor.fields.mooring.sternMarker": "Heck",
  "components.ma.scenarioEditor.fields.mooring.berthName": "Liegeplatz",
  "components.ma.scenarioEditor.fields.mooring.deckAboveWharf":
    "Deck über Kaje",
  "components.ma.scenarioEditor.fields.mooring.dredgeDepth": "Solltiefe",
  "components.ma.scenarioEditor.fields.mooring.dynamic":
    "Live-Vorhersage Verwenden",
  "components.ma.scenarioEditor.fields.mooring.startTime":
    "geschätzte Ankunftszeit",
  "components.ma.scenarioEditor.fields.mooring.endTime":
    "geschätzte Ablegezeit",
  "components.ma.scenarioEditor.fields.mooring.lockMooringArrangement":
    "Vertäuanordnung",
  "components.ma.scenarioEditor.fields.mooring.unlockMooringArrangement":
    "Vertäuanordnung freischalten",
  "components.ma.scenarioEditor.fields.mooring.linePretensionBinSize":
    "Schrittweite Trossenvorspannung",
  "components.ma.scenarioEditor.fields.mooring.linePretensionRange":
    "Trossenvorspannung Wertebereich",
  "components.ma.scenarioEditor.fields.mooring.linePretensionType":
    "Art der Trossenvorspannung",
  "components.ma.scenarioEditor.fields.mooring.linePretensionValue":
    "Trossenvorspannung",
  "components.ma.scenarioEditor.fields.mooring.lines.breast": "Brust",
  "components.ma.scenarioEditor.fields.mooring.lines.spring": "Spring",
  "components.ma.scenarioEditor.fields.mooring.lines.short.bow": "Bug",
  "components.ma.scenarioEditor.fields.mooring.lines.short.foreBreast":
    "Vordere Brust",
  "components.ma.scenarioEditor.fields.mooring.lines.short.foreSpring":
    "Vorspring",
  "components.ma.scenarioEditor.fields.mooring.lines.short.aftBreast":
    "achtere Brust",
  "components.ma.scenarioEditor.fields.mooring.lines.short.aftSpring":
    "Achterspring",
  "components.ma.scenarioEditor.fields.mooring.lines.short.stern": "Heck",
  "components.ma.scenarioEditor.fields.mooring.lines.long.bowLine": "Vorleine",
  "components.ma.scenarioEditor.fields.mooring.lines.long.foreBreastLine":
    "Vordere Brustleine",
  "components.ma.scenarioEditor.fields.mooring.lines.long.foreSpringLine":
    "Vorspring",
  "components.ma.scenarioEditor.fields.mooring.lines.long.aftSpringLine":
    "Achterspring",
  "components.ma.scenarioEditor.fields.mooring.lines.long.aftBreastLine":
    "achtere Brustleine",
  "components.ma.scenarioEditor.fields.mooring.lines.long.sternLine":
    "Achterleine",
  "components.ma.scenarioEditor.fields.mooring.lines.headerBollard":
    "Festmacher",
  "components.ma.scenarioEditor.fields.mooring.lines.headerFairlead": "Klüse",
  "components.ma.scenarioEditor.fields.mooring.lines.headerLine": "Leine",
  "components.ma.scenarioEditor.fields.mooring.lines.headerLines": "Leinen",
  "components.ma.scenarioEditor.fields.mooring.lines.headerLineType":
    "Leinentyp",
  "components.ma.scenarioEditor.fields.mooring.lines.headerLineMaterial":
    "Leinenmaterial",
  "components.ma.scenarioEditor.fields.mooring.lines.headerTail": "Vorläufer",
  "components.ma.scenarioEditor.fields.mooring.lines.headerTailType":
    "Vorläufertyp",
  "components.ma.scenarioEditor.fields.mooring.lines.headerTailMaterial":
    "Vorläufermaterial",
  "components.ma.scenarioEditor.fields.mooring.lines.headerWinch":
    "Winschenart",
  "components.ma.scenarioEditor.fields.mooring.lines.none": "Keiner",
  "components.ma.scenarioEditor.fields.mooring.lines.noTail": "Kein Vorläufer",
  "components.ma.scenarioEditor.fields.mooring.lines.setAllLines":
    "Alle Leinen setzen",
  "components.ma.scenarioEditor.fields.mooring.lines.setAllTails":
    "Alle Vorläufer setzen",
  "components.ma.scenarioEditor.fields.mooring.lines.setAllWinches":
    "Alle Winschen setzen",
  "components.ma.scenarioEditor.fields.mooring.lines.addShoreTension":
    "Add Shore Tension",
  "components.ma.scenarioEditor.fields.mooring.lineMinimumBreakingLoad":
    "Mindestbruchlast [Tonnen]",
  "components.ma.scenarioEditor.fields.mooring.tailMinimumBreakingLoad":
    "Mindestbruchlast [Tonnen]",
  "components.ma.scenarioEditor.fields.mooring.tailLength":
    "Vorläuferlänge [m]",
  "components.ma.scenarioEditor.fields.mooring.winchBrakeHoldingCapacity":
    "Winsch Bremshaltekapazität [Tonnen]",
  "components.ma.scenarioEditor.fields.mooring.maxBeamExceeded":
    "Maximale Breite überschritten",
  "components.ma.scenarioEditor.fields.mooring.maxLoaExceeded":
    "Maximale LOA überschritten",
  "components.ma.scenarioEditor.fields.mooring.mooringArrangement":
    "Vertäuanordnung",
  "components.ma.scenarioEditor.fields.mooring.mooringArrangementName":
    "Vertäuanordnung",
  "components.ma.scenarioEditor.fields.mooring.outreachBeyondBeam":
    "Reichweite überschreitet Schiffsbreite",
  "components.ma.scenarioEditor.fields.mooring.customisedMooringArrangement":
    "Benutzerdefiniert",
  "components.ma.scenarioEditor.fields.mooring.portDock": "Anleger",
  "components.ma.scenarioEditor.fields.mooring.portDockPort": "Backbord",
  "components.ma.scenarioEditor.fields.mooring.portDockStarboard": "Steuerbord",
  "components.ma.scenarioEditor.fields.mooring.spreaderClearance":
    "Lichte Höhe unter dem Greifer",
  "components.ma.scenarioEditor.fields.mooring.underKeelClearance":
    "Kielfreiheit",
  "components.ma.scenarioEditor.fields.name": "Szenario-Name",
  "components.ma.scenarioEditor.fields.name.default": "Neues MA Szenario",
  "components.ma.scenarioEditor.fields.name.placeholder": "Szenario-Name",
  "components.ma.scenarioEditor.fields.port.airTemperature": "Lufttemperatur",
  "components.ma.scenarioEditor.fields.port.salinity": "Salzgehalt",
  "components.ma.scenarioEditor.fields.port.waterTemperature":
    "Wassertemperatur",
  "components.scenarioEditor.fields.vessel.beam": "Schiffsbreite",
  "components.ma.scenarioEditor.fields.vessel.bridgeToBow": "Brücke bis Bug",
  "components.ma.scenarioEditor.fields.vessel.displacement": "Verdrängung",
  "components.ma.scenarioEditor.fields.vessel.draftBinSize":
    "Schrittweite Tiefgang",
  "components.scenarioEditor.fields.vessel.draftForeValue": "Bug",
  "components.scenarioEditor.fields.vessel.draftMidValue": "Mittschiffs",
  "components.scenarioEditor.fields.vessel.draftAftValue": "Achtern",
  "components.ma.scenarioEditor.fields.vessel.draftRange": "Tiefgangbandbreite",
  "components.ma.scenarioEditor.fields.vessel.draftType": "Art des Tiefgangs",
  "components.ma.scenarioEditor.fields.vessel.geometricCentreHeight": "GMf",
  "components.ma.scenarioEditor.fields.vessel.hatchHeightAboveMainDeck":
    "Lukenhöhe über Hauptdeck",
  "components.scenarioEditor.fields.vessel.imo": "IMO-Nummer",
  "components.scenarioEditor.fields.vessel.loa": "LOA",
  "components.scenarioEditor.fields.vessel.lbp": "LBP",
  "components.scenarioEditor.fields.vessel.speedProfile": "Speed Profile",
  "components.scenarioEditor.fields.vessel.dwt": " DWT",
  "components.scenarioEditor.fields.vessel.eventId": "Event ID",
  "components.scenarioEditor.fields.vessel.vesselType": "Vessel Type",
  "components.scenarioEditor.fields.vessel.loading": "Beladung",
  "components.scenarioEditor.fields.vessel.loadingContainers":
    "Containerreihen über Deck",
  "components.ma.scenarioEditor.fields.vessel.tiers": "Lagen",
  "components.ma.scenarioEditor.fields.vessel.tonnes": "Tonnen",
  "components.ma.scenarioEditor.fields.vessel.longitudinalWindageArea":
    "Windangriffsfläche längs",
  "components.ma.scenarioEditor.fields.vessel.lpp": "LPP",
  "components.ma.scenarioEditor.fields.vessel.mouldedDepth": "Seitenhöhe",
  "components.ma.scenarioEditor.fields.vessel.rxx": "xx-Trägheitsradius",
  "components.ma.scenarioEditor.fields.vessel.ryy": "yy-Trägheitsradius",
  "components.ma.scenarioEditor.fields.vessel.rzz": "zz-Trägheitsradius",
  "components.ma.scenarioEditor.fields.vessel.totalHeightAboveWl":
    "Gesamthöhe über WSL",
  "components.ma.scenarioEditor.fields.vessel.transversalWindageArea":
    "Windangriffsfläche quer",
  "components.ma.scenarioEditor.fields.vessel.lineTypeConfirmed":
    "Leinentyp bestätigt?",
  "components.ma.scenarioEditor.fields.vessel.winchTypeConfirmed":
    "Winschentyp bestätigt?",
  "components.ma.scenarioEditor.fields.vessel.vesselName": "Schiffsname / IMO",
  "components.ma.scenarioEditor.fields.vessel.vesselName.placeholder":
    "Suche Schiff nach Namen / IMO",
  "components.ma.scenarioEditor.tabs.berthConfig": "Liegeplatz",
  "components.ma.scenarioEditor.tabs.enviroForcings": "Umweltbedingungen",
  "components.ma.scenarioEditor.tabs.vesselSpec": "Schiff",
  "components.ma.scenarioList.menuItems.clone": "Duplizieren",
  "components.ma.scenarioList.menuItems.customClone": "Duplizieren",
  "components.ma.scenarioList.menuItems.remove": "Löschen",
  "components.ma.scenarioList.menuItems.delete": "Löschen",
  "components.ma.scenarioList.menuItems.edit": "Bearbeiten",
  "components.ma.scenarioList.menuItems.execute": "Ausführen",
  "components.ma.scenarioList.menuItems.tryExecute": "Ausführen",
  "components.ma.scenarioList.menuItems.terminate": "Ausführung abbrechen",
  "components.ma.scenarioList.menuItems.openPdf": "PDF öffnen",
  "components.ma.scenarioList.menuItems.openXlsx": "Excel öffnen",
  "components.ma.scenarioList.menuItems.viewResults": "Bericht anzeigen",
  "components.ma.scenarioList.menuItems.viewLog": "Protokoll anzeigen",
  "components.ps.scenarioEditor.fields.siltationForecastDate":
    "Evaluation Date",
  "components.ps.scenarioEditor.fields.dredgingCost": "Dredging Cost",
  "components.ps.scenarioEditor.fields.dredgingZones": "Dredging Zone",
  "components.ps.scenarioEditor.fields.designDepth": "Design Depth",
  "components.ps.scenarioEditor.fields.dredgeDepth": "Dredge Depth",
  "components.ps.scenarioEditor.fields.dredgeVolume": "Dredge Volume",
  "components.ps.scenarioEditor.fields.channelCategory": "Channel Category",
  "components.ps.scenarioEditor.fields.timeToRedredgeMonths":
    "Months to Re-Dredge",
  "components.ps.scenarioEditor.fields.date": "Date",
  "components.ps.scenarioEditor.fields.name": "Name",
  "components.ps.scenarioEditor.fields.description": "Description",
  "components.ps.scenarioEditor.fields.inputDataResolution":
    "Data Resolution [m]",
  "components.ps.scenarioEditor.fields.surveyDate": "Survey Date",
  "components.ps.scenarioEditor.fields.surveyDepth": "Survey Depth",
  "components.ps.scenarioEditor.fields.colorPalette": "Color Palette",
  "components.ps.scenarioEditor.fields.mode": "Mode",
  "components.ps.siltation.button.mapView": "Map View",
  "components.ps.siltation.button.bathymetricModel": "Bathymetric Model",
  "components.ps.siltation.button.dredgeRecords": "Dredge Records",
  "components.ps.siltation.button.analyticsView": "Analytics View",
  "components.scenarioList.translations.executeConfirmation":
    "Neuen Job im Hintergrund starten. Der Status ändert sich nach Beendigung des Jobs. Sind Sie sicher, dass Sie %job% ausführen möchten?",
  "components.scenarioList.translations.terminateConfirmation":
    "Aktuellen Job abbrechen. Der Status ändert sich nach Beendigung des Jobs. Möchten Sie %job% wirklich beenden?",
  "components.scenarioList.translations.cloneConfirmation":
    "Sind Sie sicher, dass Sie %job% duplizieren möchten?",
  "components.scenarioList.translations.deleteConfirmation":
    "Ausgewähltes Szenario aus der Liste löschen. Nach dem Löschen können Sie die Daten nicht mehr abrufen. Möchten Sie %job% wirklich löschen?",
  "components.ma.scenarioList.translations.cancelLabel": "Abbrechen",
  "components.scenarioList.translations.confirmLabel": "Bestätigen",
  "components.scenarioList.translations.viewResults.compare":
    "Optionally, select other scenarios to compare against",
  "components.ma.scenarioList.translations.cloneLabel": "Ja, Duplizieren",
  "components.ma.scenarioList.translations.deleteLabel": "Ja, Löschen Szenario",
  "components.ma.scenarioList.translations.cloneTitle": "Duplizieren",
  "components.ma.scenarioList.translations.deleteTitle": "Löschen",
  "components.ma.scenarioList.title": "Szenarien",
  "components.page.menu.yardSafe": "Yard Safe",
  "components.page.menu.liveWeather": "Live-Wetter",
  "engine.errorMessages.beamScaling":
    "Die Zielbreite ist {1}% größer/kleiner als die vorgegebene Schiffsbreite.",
  "engine.errorMessages.convergenceError":
    "Das Vertäusystem konnte nicht konvergieren. Bitte generieren Sie ein symmetrischeres Vertäusystem.",
  "engine.errorMessages.depthScaling":
    "Die Zieltiefe ist {1} größer/kleiner als der vorgegebene Tiefgang.",
  "engine.errorMessages.draftMouldedDepthError":
    "Der maximale Tiefgang ({1}) ist größer als die Seitenhöhe ({2}).",
  "engine.errorMessages.draftScaling":
    "Der Zieltiefgang ist {1} größer/kleiner als der vorgegebene Tiefgang.",
  "engine.errorMessages.draftWaterDepthError":
    "Der maximale Tiefgang ({1}) ist größer als die minimale Wassertiefe ({2}).",
  "engine.errorMessages.forcingProcessingTime":
    "Rechenzeit Umweltbedingungen: {1}",
  "engine.errorMessages.frequencyResponseProcessingTime":
    "Rechenzeit Frequenzresonanz: {1}",
  "engine.errorMessages.loaScaling":
    "Die Ziel LOA ist {1}% größer/kleiner als die gegebene LOA.",
  "engine.errorMessages.mooringAnalysisProcessingTime":
    "Rechenzeit Vertäuberechnung: {1}",
  "engine.errorMessages.mooringSystemConvergenceProcessingTime":
    "Rechenzeit Vertäusystem-Konvergenz: {1}",
  "engine.errorMessages.resultsProcessingTime":
    "Rechenzeit Postprocessing: {1}",
  "engine.errorMessages.shipSurgeViolation":
    "Das Schiff wogte {1} während der Konvergenz. Bitte generieren Sie ein symmetrischeres Vertäusystem.",
  "engine.errorMessages.shipSwayViolation":
    "Das Schiff schwojte {1} während Konvergenz. Bitte generieren Sie ein symmetrischeres Vertäusystem.",
  "engine.errorMessages.shipYawViolation":
    "Das Schiff gierte {1} während der Konvergenz. Bitte generieren Sie ein symmetrischeres Vertäusystem.",
  "engine.errorMessages.technicalError":
    "Bei der Ausführung dieses Szenarios ist ein technischer Fehler aufgetreten. Bitte rufen Sie den NCOS-Support an.",
  "engine.errorMessages.totalProcessingTime": "Gesamtrechenzeit: {1}",
  "engine.errorMessages.vcgScaling":
    "Der vertikale Zielschwerpunkt ist {1} größer/kleiner als der angegebene Tiefgang.",
  "engine.automoor.errorMessages.generalError":
    "Automoor konnte das Schiff an dieser Stelle nicht festmachen. Bitte passen Sie die Liegeplatzkonfiguration an.",
  "engine.automoor.errorMessages.succeedWithWarnings":
    "Während der automatischen Vertäuung traten Warnungen auf:",
  "engine.automoor.errorMessages.possibleLinesError":
    "Es konnte kein zulässiges Vertäusystem gefunden warden. ",
  "engine.automoor.errorMessages.possibleLinesWarning":
    "Es konnte ein Vertäusystem gefunden werden, jedoch mit weniger Leinen als gewünscht.",
  "engine.automoor.errorMessages.bollardOverloadWarning":
    "Es konnte kein zulässiges Vertäusystem gefunden werden, weil Festmacher überladen würden.",
  "engine.automoor.errorMessages.subOptimalMooringSystem":
    "Die Vertäuvorschriften mussten in diesem Szenario gelockert werden. Bewegen Sie das Schiff an der Kaje hin und her, um herauszufinden, ob ein Vertäusystem gefunden werden kann, das den strengeren Vorschriften genügt.",
  "global.button.cancel": "Abbrechen",
  "global.button.save": "Speichern",
  "global.button.saveDraft": "als Entwurf speichern",
  "global.button.saveAndExecute": "Speichern & ausführen",
  "global.button.saveAndRegister": "Speichern & Registrieren",
  "global.button.elearning": "E-LERNEN",
  "global.button.next": "Nächster",
  "global.help": "Hilfe",
  "global.evaluationPurposes": "Nur zu Bewertungszwecken.",
  "global.page.notFound": "Seite nicht gefunden",
  "global.scenario.status.editing": "in Bearbeitung",
  "global.scenario.status.failed": "Fehlgeschlagen",
  "global.scenario.status.running": "wird berechnet",
  "global.scenario.status.success": "Beendet",
  "global.scenarioLogViewer.columns.description": "Beschreibung",
  "global.scenarioLogViewer.noResults": "Keine Protokolleinträge vorhanden",
  "global.scenarioLogViewer.title": "Szenario-Protokoll",
  "global.settings": "Einstellungen",
  "global.unit.direction": "Richtung",
  "global.unit.length": "Länge",
  "global.yes": "Ja",
  "global.no": "Nein",
  "validate.decimalsDisallowed": "Darf keine Dezimalstellen enthalten",
  "validate.decimalsExceeded": "Maximal {decimals, number} Dezimalstellen",
  "validate.valueMaximum": "{maximum, number} Maximum",
  "validate.valueMinimum": "{minimum, number} Minimum",
  "validate.valueNumeric": "Muss eine Zahl sein",
  "validate.valuePositive": "Muss eine positive Zahl sein",
  "validate.valueRequired": "Wert erforderlich",
  "validate.valuesOutsideBounds":
    "Nur Werte von {minimum, number} bis {maximum, number}",
  "vesselType.bulkCarrier": "Massengutfrachter",
  "vesselType.containerVessel": "Containerschiff",
  "vesselType.cruiseLiner": "Kreuzfahrtschiff",
  "vesselType.generalCargo": "Stückgutfrachter",
  "vesselType.tanker": "Tanker",
  "vesselType.unknown": "Unbekannter Schiffstyp",
};

export default de;
