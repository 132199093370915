import React, { FC } from 'react';

export const PublicLayout: FC = ({ children }) => (
  <div
    style={{
      minHeight: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      padding: 0,
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    {children}
  </div>
);
