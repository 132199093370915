export const SCENARIO_DATA_VERSION = 1;

export const HIGH_ZOOM = 12;
export const DETAIL_ZOOM_LEVEL = 15;
export const MED_HIGH_ZOOM = 17;
export const MEDIUM_ZOOM = 18;
export const LOW_ZOOM = 19;

export const CHARACTER_SET = [
  ' ',
  '!',
  '"',
  '#',
  '$',
  '%',
  '&',
  '(',
  ')',
  '*',
  '+',
  '-',
  '.',
  '/',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  ':',
  ';',
  '<',
  '=',
  '>',
  '?',
  '@',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  'ß',
  'Ä',
  'Ö',
  'Ü',
  '[',
  ']',
  '^',
  '_',
  '`',
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
  'ü',
  'ä',
  'ö',
  '{',
  '|',
  '}',
  '~',
];
