import { DataConfig } from "../../__models";

export interface UseScenarioEditor {
  scenarioEditor: IScenarioEditor;
}

const initialState: Partial<IScenarioEditor> = {
  error: {
    hasError: false,
    errors: [],
  },
  tabs: {},
};

export interface IScenarioEditor {
  error: {
    hasError: boolean;
    errors: [];
  };
  tabs: {};
  activeTab?: string;
  submitAttempted: boolean;
  jumpToNextError: boolean;
  /**
   * Add an error-object
   * @param {object} errorData
   */
  addError: (errorData) => void;

  /**
   * Set list of error-objects
   * @param [{object}] errorDataList
   */
  setError: (errorDataList) => void;

  /**
   * Resets errors: empties errors-list
   * and flags hasError to false
   */
  resetErrors: () => void;

  /**
   * Add a field-name to a scenario-tab
   * existing or non-existing tab
   * @param {string} fieldName
   * @param {string} tabId
   */
  addFieldToTab: (fieldName, tabId) => void;

  setActiveTab: (tabName: string) => void;

  setSubmitAttempted: (submitAttempted?: boolean) => void;

  setJumpToNextError: (jumpToNextError: boolean) => void;

  getValidationErrors: (
    dataConfigSet: DataConfig[],
    showErrors?: boolean
  ) => any[];
  displayErrorsIfNeeded: (
    dataConfigSet: DataConfig[],
    attemptSubmit?: boolean
  ) => any[];
}

export interface ScenarioEditorProviderProps {
  children?: React.ReactNode;
}

export { initialState as initialScenarioEditorState };
