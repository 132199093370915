import { COLOR_BLUE_MOORING_LINE, COLOR_ORANGE_DARK, COLOR_YELLOW_600 } from '../theme';

/**
 *
 * @param color : HEX, rgb or 'black';
 * @returns return an object with `r`, `g` and `b` color values
 */
const colorToRGB = (
  color: String,
): {
  r: number;
  g: number;
  b: number;
} => {
  if (!color) {
    return {
      r: 255,
      g: 255,
      b: 255,
    };
  }

  if (color === 'black') {
    return {
      r: 0,
      g: 0,
      b: 0,
    };
  }
  if (color.includes('rgb')) {
    const parts = color.split('(')[1].split(')')[0].split(',');

    return {
      r: parseInt(parts[0]),
      g: parseInt(parts[1]),
      b: parseInt(parts[2]),
    };
  } else {
    return {
      r: parseInt(color.substring(1, 3), 16),
      g: parseInt(color.substring(3, 5), 16),
      b: parseInt(color.substring(5, 7), 16),
    };
  }
};

/**
 * Get correct line colors based on the Vessel Status
 * @param status active | invalid | '' | null
 * @returns
 */
const getLineColor = (status?: string) => {
  switch (status) {
    case 'active':
      return paint(COLOR_YELLOW_600);

    case 'invalid':
      return paint(COLOR_ORANGE_DARK);

    default:
      return paint(COLOR_BLUE_MOORING_LINE);
  }
};

/**
 * return RGB array
 * @param color : HEX, rgb or 'black';
 * @returns RGB array
 */
const paint = (color: string): [number, number, number] | [number, number, number, number] => {
  const rgb = colorToRGB(color);

  if (color != null) {
    return [rgb.r, rgb.g, rgb.b];
  }

  return [rgb.r, rgb.g, rgb.b, 0];
};

export default colorToRGB;
export { getLineColor, paint };
