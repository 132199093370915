import fieldIntl, { getSectionProps } from './fieldIntl';

const SECTION = 'default';
const props = getSectionProps(SECTION);

/**
 * Helper function to extract default out of field-config
 * by either having specified one of these
 * two properties in the `fieldConfig` object:
 * - default
 * - defaultId
 * @param {Object} intl
 * @param {{default?: string|number, defaultId?: string}} fieldConfig
 * @returns {string|number|undefined}
 */
const fieldDefault = (intl, fieldConfig) =>
  Object.prototype.hasOwnProperty.call(fieldConfig, props[0]) ||
  Object.prototype.hasOwnProperty.call(fieldConfig, props[1])
    ? fieldIntl(intl, fieldConfig, SECTION)
    : undefined;

export default fieldDefault;
