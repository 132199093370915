import { deleteJsonDocument, postJsonDocuments, updateJsonDocument } from '@dhi/react-components';
import jp from 'jsonpath';
import { action, makeObservable, override } from 'mobx';
import { IntlShape } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import { ListFieldValue, Validator, displayToField, getFieldConfig } from '../components/SharedLibrary';
import { SarCandidateContainer } from '../modules/SAR/__models/SarCandidate';
import setDefaultValues from '../modules/SAR/__utility/setDefaultValues';
import { CandidateStore } from './CandidateStore';
import { RootStore } from './RootStore';

export class SarCandidateStore extends CandidateStore {
  initialScenarioDataState = {
    added: '',
    dateTime: '',
    fullName: '',
    data: {},
    permissions: [],
  } as SarCandidateContainer;

  jsonDocumentsDataSources = [
    {
      connection: 'MarineAid-JsonDocuments-SARDriftCandidates-[customerCode]',
      host: process.env.REACT_APP_API_ENDPOINT_URL,
      ids: [
        // Filled via configs
      ],
      from: null,
      to: null,
    },
  ];

  constructor(allStores: RootStore) {
    super(allStores);
    this.scenarioData = this.initialScenarioDataState;

    makeObservable(this, {
      scenarioData: override,
      resetScenarioDataToDefaults: action.bound,
      createScenarioData: action.bound,
      addScenarioData: action.bound,
      updateScenarioData: action.bound,
      deleteScenarioData: action.bound,
      setScenarioDataField: action.bound,
      setScenarioData: action.bound,
    });
  }

  createScenarioData(scenarioContext: SarCandidateContainer, freshOpen?: boolean) {
    this.scenarioData = {
      ...scenarioContext,
      fullName: uuidv4(),
      permissions: [
        {
          principals: ['Administrators', 'Editors', 'Users'],
          operation: 'read',
        },
        {
          principals: ['Administrators', 'Editors'],
          operation: 'update',
        },
        {
          principals: ['Administrators', 'Editors'],
          operation: 'delete',
        },
      ],
    } as SarCandidateContainer;
  }

  addScenarioData(scenarioContext: SarCandidateContainer) {
    // limited fields are needed when posting a new json document
    const postScenario = {
      fullName: scenarioContext.fullName,
      data: JSON.stringify(scenarioContext.data),
      permissions: scenarioContext.permissions,
    };

    let dataSource = this.jsonDocumentsDataSources[0];
    dataSource.connection = dataSource.connection.replace('[customerCode]', this.allStores.configStore.customerCode);
    postJsonDocuments(dataSource, this.allStores.appStateStore.session.token.accessToken, postScenario);
  }

  updateScenarioData() {
    const scenarioData = this.scenarioData;

    const updateScenario = {
      ...scenarioData,
      data: JSON.stringify(scenarioData.data),
    };

    let dataSource = this.jsonDocumentsDataSources[0];
    dataSource.connection = dataSource.connection.replace('[customerCode]', this.allStores.configStore.customerCode);

    updateJsonDocument(dataSource, this.allStores.appStateStore.session.token.accessToken, updateScenario);
  }

  deleteScenarioData(scenarioContext: SarCandidateContainer) {
    let dataSource = this.jsonDocumentsDataSources[0];
    dataSource.connection = dataSource.connection.replace('[customerCode]', this.allStores.configStore.customerCode);
    deleteJsonDocument(dataSource, this.allStores.appStateStore.session.token.accessToken, scenarioContext.fullName);
  }

  setScenarioData(scenarioContext: SarCandidateContainer, freshOpen?: boolean) {
    this.scenarioData = scenarioContext;
  }

  setScenarioDataField(
    dataConfig: any,
    fieldName: string,
    fieldValue: any,
    intl: IntlShape,
    inServerUnit?: boolean,
    noValidation = false,
  ) {
    const payload: any = { fieldName };
    const fieldConfig = getFieldConfig(fieldName, dataConfig);

    if (fieldConfig) {
      // unit-conversion
      payload.dataValue = inServerUnit === false ? displayToField(fieldValue, fieldConfig) : fieldValue;
    } else {
      // no field-config, so unit-conversion is out of the question
      payload.dataValue = fieldValue;
    }

    // Use jsonpath to apply in a deep path approach
    jp.apply(this.scenarioData.data, `$.${fieldName}`, () => fieldValue);
  }

  handleValueChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> | null,
    dataConfig: any,
    newValue?: any,
    fieldName?: string,
    intl?: IntlShape,
    validators?: Validator,
    noValidation = false,
  ) {
    const path = fieldName || (e && e.currentTarget.id);

    if (path) {
      this.setScenarioDataField(dataConfig, path, newValue, intl, false, noValidation);
    } else {
      throw new Error(
        'Missing `path` for `setScenarioDataField` (path can be obtained through `fieldName` or `e` argument)',
      );
    }
  }

  handleValuesChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> | null,
    dataConfig: any,
    listFieldValue: ListFieldValue[],
    intl: IntlShape,
  ) {
    listFieldValue.forEach((itemFieldValue: ListFieldValue) => {
      this.handleValueChange(e, dataConfig, itemFieldValue.newValue, itemFieldValue.fieldName, intl);
    });
  }

  resetScenarioDataToDefaults(intl: any, dataConfig: any) {
    const newContext = this.initialScenarioDataState;
    setDefaultValues(newContext.data, dataConfig, intl);
    this.createScenarioData(newContext, true);
  }
}
